import axios from "axios"
import baseUrl from "./apiUrl";

export async function login_request(loginData) {
    try {
        let resp;
        // console.log(loginData);
        const response = await axios({
            url: baseUrl + "/login",
            method: "POST",
            data: loginData,
        })
        // console.log();
        if (response.status === 200) {
            // console.log(response.data.Respuesta);
            // console.log("##############################");
            // console.log(response.data.Respuesta.Usuario.Paquete);
            // console.log(response.data.Respuesta.Usuario.PaqueteStatus);
            // console.log("##############################");
            localStorage.setItem("token", response.data.Respuesta.Token.Token);
            localStorage.setItem("paquete", response.data.Respuesta.Usuario.Paquete);
            localStorage.setItem("paqueteStatus", response.data.Respuesta.Usuario.PaqueteStatus);
            localStorage.setItem("refresh", 0);
            // storage.set ("token", response.data.Respuesta.Token.Token);
            resp = {
                "ok": true,
                "status": 200,
                "data": response.data.Respuesta
            };
        } else {
            resp = {
                "ok": false,
                "status": 500
            };
        }
        return resp;
    } catch (error) {
        // console.log(error);
        // console.log(error.response.data);
        return {
            "ok": false,
            "status": 500,
            "mensaje": error.response !== undefined ? error.response.data.exception === undefined ? error.response.data.message !== undefined ? error.response.data.message : error.response.data.Error.Motivo : "Error desconocido" : error.message,
        };

    }
}

export async function logout_request() {
    try {
        let resp;
        // console.log(loginData);
        const response = await axios({
            url: baseUrl + "/logout",
            method: "GET",
            headers:
            {

                'Accept': 'application/json',
                'Content-Type': 'application/json',

                'Authorization': 'Bearer ' + localStorage.getItem("token")
            }
        })
        // console.log();
        // console.log(localStorage.setItem("token",response.data.Respuesta.Token.Token));
        if (response.status === 200) {
            // storage.set ("token", response.data.Respuesta.Token.Token);
            localStorage.setItem("logged", false);
            localStorage.removeItem("token");
            localStorage.removeItem("tipoUsuario");
            localStorage.removeItem("datosMedico");
            localStorage.removeItem("refresh");
            localStorage.removeItem("paquete");
            localStorage.removeItem("paqueteStatus");
            sessionStorage.removeItem("infoUser");
            localStorage.removeItem("permisos");
            localStorage.removeItem("firebaseToken");
            localStorage.setItem("loginAsistente", 0);
            resp = {
                "ok": true,
                "status": 200
            };
        } else {
            resp = {
                "ok": false,
                "status": 500
            };
        }
        return resp;
    } catch (error) {
        // console.log(error);
        // console.log(error.response.data);
        return {
            "ok": false,
            "status": 500,
            "mensaje": error.response !== undefined ? error.response.data.exception === undefined ? error.response.data.Error.Motivo : "Error desconocido" : error.message,
        };

    }
}

export async function sendRecoveryCodeToEmail_request(mail) {
    try {
        let resp;
        // console.log(loginData);
        const response = await axios({
            url: baseUrl + "/create",
            method: "POST",
            data: mail,
        })
        // console.log();
        if (response.status === 200) {
            resp = {
                "ok": true,
                "status": 200
            };
        } else {
            resp = {
                "ok": false,
                "status": 500
            };
        }
        return resp;
    } catch (error) {
        // console.log(error);
        return {
            "ok": false,
            "status": 500,
            "mensaje": error.response !== undefined ? error.response.data.exception === undefined ? error.response.data.message !== undefined ? error.response.data.message : error.response.data.Error.Motivo : "Error desconocido" : error.message,
        };

    }
}

export async function resendCode_request(mail) {
    try {
        let resp;
        // console.log(loginData);
        const response = await axios({
            url: baseUrl + "/reenviarCodigo",
            method: "POST",
            data: mail,
        })
        // console.log();
        if (response.status === 200) {
            resp = {
                "ok": true,
                "status": 200
            };
        } else {
            resp = {
                "ok": false,
                "status": 500
            };
        }
        return resp;
    } catch (error) {
        // console.log(error.response.data);
        return {
            "ok": false,
            "status": 500,
            "mensaje": error.response !== undefined ? error.response.data.exception === undefined ? error.response.data.Error.Motivo : "Error desconocido" : error.message,
        };

    }
}

export async function recoveryCodeVerification_request(code) {
    try {
        let resp;
        // console.log(loginData);
        const response = await axios({
            url: baseUrl + "/find/" + code,
            method: "GET",
        })
        // console.log();
        if (response.status === 200) {
            resp = {
                "ok": true,
                "status": 200
            };
        } else {
            resp = {
                "ok": false,
                "status": 500
            };
        }
        return resp;
    } catch (error) {
        // console.log(error.response.data);
        return {
            "ok": false,
            "status": 500,
            "mensaje": error.response !== undefined ? error.response.data.exception === undefined ? error.response.data.Error.Motivo : "Error desconocido" : error.message,
        };

    }
}

export async function changePassword_request(recoveryData) {
    try {
        let resp;
        // console.log(loginData);
        const response = await axios({
            url: baseUrl + "/reset",
            method: "POST",
            data: recoveryData,
        })
        // console.log();
        if (response.status === 200) {
            resp = {
                "ok": true,
                "status": 200
            };
        } else {
            resp = {
                "ok": false,
                "status": 500
            };
        }
        return resp;
    } catch (error) {
        // console.log(error.response.data);
        return {
            "ok": false,
            "status": 500,
            "mensaje": error.response !== undefined ? error.response.data.exception === undefined ? error.response.data.Error.Motivo : "Error desconocido" : error.message,
        };

    }
}

export async function updateDeviceToken_request(deviceTokenData) {
    try {
        let resp;
        // console.log(loginData);
        const response = await axios({
            url: baseUrl + "/deviceToken",
            method: "POST",
            data: deviceTokenData,
            // params: deviceTokenData,
            headers:
            {

                'Accept': 'application/json',
                'Content-Type': 'application/json',

                'Authorization': 'Bearer ' + localStorage.getItem("token")
            }
        })
        // console.log();
        if (response.status === 200) {
            // console.log(response);
            resp = {
                "ok": true,
                "status": 200
            };
        } else {
            resp = {
                "ok": false,
                "status": 500
            };
        }
        return resp;
    } catch (error) {
        // console.log(error);
        // console.log(error.response.data);
        return {
            "ok": false,
            "status": 500,
            "mensaje": error.response !== undefined ? error.response.data.exception === undefined ? error.response.data.message !== undefined ? error.response.data.message : error.response.data.Error.Motivo : "Error desconocido" : error.message,
        };

    }
}