import React, { useEffect, useState, useRef } from "react";
import "./styles/Citas.scss";

// Libreria de React router
import { useNavigate } from "react-router-dom";
// Libreria de formularios
import {
  Label,
  ContenedorBotonCentrado,
  LeyendaError,
} from "../componentes/GeneralComponents/Formularios2Grids";

import InputCustome from "../componentes/GeneralComponents/Input";

import {
  Input,
  Select,
  TimePicker,
  Row,
  Button,
  Layout,
  DatePicker,
  Modal,
  Col,
  Tabs,
  Radio,
  Calendar,
} from "antd";
import InputAntd from "../componentes/GeneralComponents/InputAntd";
import SelectAntd from "../componentes/GeneralComponents/SelectAntd";
import TextAreaAntd from "../componentes/GeneralComponents/TextAreaAntd";
import Accordionn from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Campos from "../componentes/GeneralComponents/campos";
import NavbarNuevo from "../componentes/GeneralComponents/Navbar_Nuevo";
import DatePickerAntd from "../componentes/GeneralComponents/DatePickerAntd";
import { Loader } from "../componentes/GeneralComponents/Loader";
import LoaderModals from "../componentes/GeneralComponents/Loader_modals";
import TextArea from "antd/lib/input/TextArea";
import Cards from "../componentes/Citas/CitasCard";
// import ModalContactos from "../componentes/Citas/ModalContactos";

// Importando modales
import ModalCitas1 from "../componentes/GeneralComponents/ModalDetalle";
import ModalCitasCalendar from "../componentes/GeneralComponents/ModalDetalle";
import ModalCitasCalendarPropuesta from "../componentes/GeneralComponents/ModalDetalle";
import Addcitas from "../componentes/Citas/ModalCitasAdd";
import ModalDetalle from "../componentes/GeneralComponents/ModalDetalle";
import ModalFiltro from "../componentes/GeneralComponents/ModalFiltro";
import ModalTipoUsuario from "../componentes/GeneralComponents/ModalDetalle";
import ModalAddContacto from "../componentes/GeneralComponents/ModalDetalle";
import ModalAddNota from "../componentes/GeneralComponents/ModalDetalle";
import ModalEditContacto from "../componentes/GeneralComponents/ModalDetalle";
import ModalFicha from "../componentes/GeneralComponents/ModalDetalle";
import ModalAddCitas from "../componentes/GeneralComponents/ModalDetalle";
import ModalAlertConfirm from "../componentes/GeneralComponents/ModalDetalle";

// hooks de funcionamiento del modal
import { UseModal } from "../hooks/UseModal";

// importando iconos
import Confi from "../Img/Iconos/Configuracion.svg";
import Confi_white from "../Img/Iconos/Configuracion_white.svg";
import Agregarcitas from "../Img/Iconos/AgregarBlanco.svg";
import Filtrocitas from "../Img/Iconos/Filtro.svg";
import Iconos from "../componentes/IconosDeSitio";
import {
  ExclamationCircleOutlined,
  EyeFilled,
  EyeOutlined,
} from "@ant-design/icons";
import AddIcon from "@mui/icons-material/AddCircle";
import {
  BorderColor,
  Cancel,
  CancelOutlined,
  CancelRounded,
  CheckCircleOutline,
} from "@mui/icons-material";

// importando imagenes
import Imag from "../Img/no-image.png";
import CancelarCita from "../Img/Iconos/cancelarcita.svg";
import Editarcita from "../Img/Iconos/Editarcita.svg";
import CheckGuardar from "../Img/Iconos/checkGuardar.svg";
import EliminarCita from "../Img/Iconos/EliminarCita.svg";
import {
  getCitas_request,
  detailCitas_request,
  addCita_request,
  cancelCitas_request,
  deleteCitas_request,
  getHorarios_request,
  reprogramarCita_request,
  getConfigCitas_request,
  getCitasFilter_request,
  atenderCitas_request,
  confirmCitas_request,
  getCitasDisponibles_request,
} from "../services/citas_services";
import {
  getContacts_request,
  addContact_request,
  contactDetail_request,
  editContact_request,
} from "../services/contactos_services";
import { addExpediente_request } from "../services/expediente_service";
import { getHistoriasClinicas_request } from "../services/expediente_service";
import {
  getEstado_request,
  getLocalidad_request,
} from "../services/expediente_service";

import dayjs from "dayjs";
import "dayjs/locale/es";
import locale from "antd/es/calendar/locale/es_ES";

import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import CitasAgendadasCard from "../componentes/Citas/CitasAgendadasCard";
import CalendarPage from "../componentes/GeneralComponents/googleCalendar";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const { Header, Content, Footer } = Layout;
const { RangePicker } = DatePicker;

function CitasNuevo() {
  // ******** VARIABLES ********

  let fecha_format = new Date(Date.now());
  let mes = fecha_format.getMonth() + 1;
  let mes_correcto = mes.toString().length === 1 ? `0${mes}` : mes;
  let fecha_hoy =
    fecha_format.getFullYear() +
    "-" +
    mes_correcto +
    "-" +
    fecha_format.getDate();

  const dateFormatList = ["DD/MM/YYYY", "DD/MM/YY", "DD-MM-YYYY", "DD-MM-YY"];

  const styleDet = {
    minHeight: "30vh",
    maxHeight: "90vh",
    minWidth: "56vh",
    maxWidth: "90vh",
  };

  const styleFichaContacto = {
    height: "clac(100vh - 52px)",
    width: "clac(100vw - 52px)",
    margin: "0 2%",
  };

  const styleNota = {
    height: "clac(100vh - 52px)",
    width: "clac(100vw - 52px)",
    margin: "0 2%",
  };

  const styleConfirm = {
    height: "clac(100vh - 52px)",
    width: "clac(100vw - 52px)",
    margin: "0 2%",
  };

  const styleTUser = {
    minHeight: "30vh",
    maxHeight: "90vh",
    minWidth: "56vh",
    maxWidth: "90vh",
  };

  const styleAddContacto = {
    minHeight: "30vh",
    maxHeight: "90vh",
    minWidth: "56vh",
    maxWidth: "90vh",
  };

  // Expresiones
  const expresiones = {
    Numeros: /^\d{1,}$/, // numeros.
    // telefono: /^\d{1,10}$/, // 7 a 14 numeros.
    // telefono: /^[0-9]{1,10}$/, // numeros.
    Letras: /^[ñíóáéú a-zA-Z-ZÀ-ÿ\s]{1,}$/, // Letras y espacios, pueden llevar acentos.
    Correo: /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i, //Letras y numeros sin espacios y caracteres especiales (-,_,.)
  };

  // ******* USE STATES *******

  // Modales de citas
  const [isOpenModalcita1, OpenModalcita1, CloseModalcita1] = UseModal(false);
  const [isOpenModalFiltro, openModalFiltro, CloseModalFiltro] =
    UseModal(false);
  const [
    isOpenModalcitaCalendar,
    openModalcitaCalendar,
    CloseModalcitaCalendar,
  ] = UseModal(false);
  const [isOpenModalDetalle, openModalDetalle, CloseModalDetalle] =
    UseModal(false);
  const [IsOpenTipoUsuario, OpenTipoUsuario, ClosedTipoUsuario] =
    UseModal(false);
  const [IsOpenAddContacto, OpenAddContacto, ClosedAddContacto] =
    UseModal(false);
  const [isOpenModalAddNota, OpenModalAddNota, CloseModalAddNota] =
    UseModal(false);
  const [isOpenEditContacto, openEditContacto, CloseEditContacto] =
    UseModal(false);
  const [isOpenFichaContacto, openFichaContacto, CloseFichaContacto] =
    UseModal(false);
  const [
    isOpenAddCitasDisponibles,
    openAddCitasDisponibles,
    CloseAddCitasDisponibles,
  ] = UseModal(false);
  const [IsOpenAlertConfirm, OpenAlertConfirm, ClosedAlertConfirm] =
    UseModal(false);

  const [EstatusModalEliminarCita, setEstatusModalEliminarCita] =
    useState(false);

  const navigate = useNavigate();

  const [listCitas, setListCitas] = useState([]);
  const [listCitasFilter, setListCitasFilter] = useState([]);
  const [listUrgencia, setListUrgencia] = useState([]);
  const [listPrimeraVez, setListPrimeraVez] = useState([]);
  const [listSeguimiento, setListSeguimiento] = useState([]);
  const [AddHorarios, setAddHorarios] = useState([]);
  const [horarios, setHorarios] = useState([]);
  const [listCitasOcupadas, setListCitasOcupadas] = useState([]);
  const [listHorariosDisponibles, setListHorariosDisponibles] = useState([]);

  const [fecha, cambiarFecha] = useState("");

  const [FechaInicio, setFechaInicio] = useState(fecha_hoy);
  const [FechaFin, setFechaFin] = useState(fecha_hoy);
  const [FechaFilter, setFechaFilter] = useState(fecha_hoy);
  const [EstadoFiltro, setEstadoFiltro] = useState("Todos");

  const [Tipocita, setTipocita] = useState(null);
  const [Horario, setHorario] = useState("");
  const [Motivo, setMotivo] = useState("");
  const [Comentario, setComentario] = useState("");
  const [Fecha, setFecha] = useState("");

  const [DatosContacto, setDatosContacto] = useState({
    id: "",
    nombre: "",
    imagen: "",
  });
  const [DatosCita, setDatosCita] = useState({
    nombre: "",
    tipo: "",
    estado: "",
    hora: "",
    fecha: "",
  });
  const [AddTipoContacto, setAddTipoContacto] = useState(null);
  const [AddTipocita, setAddTipocita] = useState(null);
  const [AddHorario, setAddHorario] = useState(null);
  const [AddMotivo, setAddMotivo] = useState("");
  const [AddComentario, setAddComentario] = useState("");
  const [AddFecha, setAddFecha] = useState(fecha_hoy);

  const [infocontacto, setinfocontacto] = useState("");
  const [ImagenContacto, setImagenContacto] = useState({
    name: "",
    url: "",
    file: "",
    size: 0,
    formato: "",
  });
  const [Tipocontacto, setTipocontacto] = useState(null);
  const [Nombre, setNombre] = useState("");
  const [ApellidoP, setApellidoP] = useState("");
  const [ApellidoM, setApellidoM] = useState("");
  const [Celular, setCelular] = useState("");
  const [Correo, setCorreo] = useState("");

  const [estatusCita, setEstatusCita] = useState("");

  const [loader, setLoader] = useState(false);
  const [loaderDetalle, setLoaderDetalle] = useState(false);

  const [idCita, setIdCita] = useState("");
  const [infoCita, setInfoCita] = useState("");

  const [inputsEditable, setInputsEditable] = useState(true);

  const [contactList, setContactList] = useState([]);
  const [contactListFilter, setContactListFilter] = useState([]);
  const [idContacto, setIdContacto] = useState("");

  // VARIABLES MODAL ADD CONTACTO
  const [ProfileImage, setProfileImage] = useState({
    name: "",
    url: "",
    file: "",
  });
  const [AddNombrecontacto, setAddNombrecontacto] = useState("");
  const [AddApellidoPcontacto, setAddApellidoPcontacto] = useState("");
  const [AddApellidoMcontacto, setAddApellidoMcontacto] = useState("");
  const [AddCelularcontacto, setAddCelularcontacto] = useState("");
  const [AddCorreocontacto, setAddCorreocontacto] = useState("");

  const [EstadosList, setEstadosList] = useState([]);
  const [LocalidadesList, setLocalidadesList] = useState([]);

  // VARIABLES MODAL FICHA CONTACTO
  const [DataParejaExist, setDataParejaExist] = useState(false);
  const [DataFichaId, setDataFichaId] = useState({ historial: [], ficha: [] });
  const [TipoSangre, setTipoSangre] = useState("");
  const [Alergias, setAlergias] = useState("");
  const [Refiere, setRefiere] = useState("");
  const [ObservacionesPaciente, setObservacionesPaciente] = useState("");
  const [EstadoCivil, setEstadoCivil] = useState(null);
  const [Ocupacion, setOcupacion] = useState("");
  const [Sexo, setSexo] = useState(null);
  const [Nacimiento, setNacimiento] = useState("");
  const [Edad, setEdad] = useState(0);
  const [Nacionalidad, setNacionalidad] = useState("");
  const [Estado, setEstado] = useState(null);
  const [Localidad, setLocalidad] = useState(null);
  const [NombrePareja, setNombrePareja] = useState("");
  const [EdadPareja, setEdadPareja] = useState("");
  const [OcupacionPareja, setOcupacionPareja] = useState("");
  const [NotasPareja, setNotasPareja] = useState("");

  // EXPLORACION FISICA
  const [TArterial, setTArterial] = useState("");
  const [FCardiaca, setFCardiaca] = useState("");
  const [FRespiratoria, setFRespiratoria] = useState("");
  const [Temperatura, setTemperatura] = useState("");
  const [Altura, setAltura] = useState("");
  const [Peso, setPeso] = useState("");
  const [IMC, setIMC] = useState("");
  const [Talla, setTalla] = useState("");
  const [SOxigeno, setSOxigeno] = useState("");
  const [ExploracionFisica, setExploracionFisica] = useState("");

  const [existConfigCitas, setExistConfigCitas] = useState(0);
  const [toggleState, setToggleState] = useState(1);
  const [AddCitaVista, setAddCitaVista] = useState(0);

  const [FiltroActive, setFiltroActive] = useState(false);

  // NUEVA CONSULTA
  const [MotivoConsulta, setMotivoConsulta] = useState("");
  const [TipoConsulta, setTipoConsulta] = useState(null);

  const [FechaInicioCitas, setFechaInicioCitas] = useState(fecha_hoy);
  const [FechaFinCitas, setFechaFinCitas] = useState(fecha_hoy);

  const [HorarioInicio, setHorarioInicio] = useState("");
  const [HorarioFin, setHorarioFin] = useState("");

  const [FechaHoraCita, setFechaHoraCita] = useState({ fecha: "", hora: "" });

  const [TabsAddCita, setTabsAddCita] = useState("1");

  // MANEJA DE MENSAJES
  const [open, setOpen] = useState(false);

  const [OpenSetting, setOpenSetting] = useState(false);
  const [typeMessage, setTypeMessage] = useState("");
  const [message, setMessage] = useState("");

  const [MensajeAddTipoContacto, setMensajeAddTipoContacto] = useState("true");
  const [ComboAddTipoCita, setComboAddTipoCita] = useState("true");
  const [ComboAddHorarioCita, setComboAddHorarioCita] = useState("true");
  const [MensajeContacto, setMensajeContacto] = useState("true");

  const [MensajeFechaInicio, setMensajeFechaInicio] = useState("true");
  const [MensajeFechaFin, setMensajeFechaFin] = useState("true");

  // Estatus mensajes ADD CONTACTO
  const [MensajeAddNombrecontacto, setMensajeAddNombrecontacto] =
    useState("true");
  const [MensajeAddApellidoPcontacto, setMensajeAddApellidoPcontacto] =
    useState("true");
  const [MensajeAddApellidoMcontacto, setMensajeAddApellidoMcontacto] =
    useState("true");
  const [MensajeAddCelularcontacto, setMensajeAddCelularcontacto] =
    useState("true");
  const [MensajeAddCorreocontacto, setMensajeAddCorreocontacto] =
    useState("true");

  const [MensajeMotivoConsulta, setMensajeMotivoConsulta] = useState("true");
  const [MensajeTipoConsulta, setMensajeTipoConsulta] = useState("true");

  const [MensajeNombre, setMensajeNombre] = useState("true");
  const [MensajeApellidoP, setMensajeApellidoP] = useState("true");
  const [MensajeApellidoM, setMensajeApellidoM] = useState("true");
  const [MensajeCelular, setMensajeCelular] = useState("true");
  const [MensajeCorreo, setMensajeCorreo] = useState("true");

  // MENSAJES GENERO Y FECHA NACIMIENTO
  const [MensajeNacimiento, setMensajeNacimiento] = useState("true");
  const [MensajeSexo, setMensajeSexo] = useState("true");

  const toggleTab = (index) => {
    setToggleState(index);
  };

  // ******* FUNCIONES *******

  async function getCitas() {
    setLoader(true);
    let response = await getCitas_request();
    if (response.ok) {
      // console.log(response.data);
      setListCitasFilter(response.data);
      setListCitas(response.data);
      let urgencia = [];
      let primeraVez = [];
      let seguimiento = [];
      response.data.forEach((element) => {
        if (element.TipoCita === 0) {
          urgencia.push(element);
        } else if (element.TipoCita === 1) {
          primeraVez.push(element);
        } else if (element.TipoCita === 2) {
          seguimiento.push(element);
        }
      });
      setListUrgencia(urgencia);
      setListPrimeraVez(primeraVez);
      setListSeguimiento(seguimiento);
      CloseModalFiltro(true);
    } else {
      setListUrgencia([]);
      setListPrimeraVez([]);
      setListSeguimiento([]);
      // setOpen(true);
      // setTypeMessage("error");
      // setMessage(response.mensaje);
    }
    setLoader(false);
    return;
  }

  async function getCitasFilterDate(dateIni, dateFin) {
    // async function getCitasFilterDate(date) {
    setLoader(true);
    // console.log(date);
    let data = new FormData();
    data.append("FechaInicio", dateIni);
    data.append("FechaFin", dateFin);
    let response = await getCitasFilter_request(4, data);
    if (response.ok) {
      // console.log(response.data);
      // return;
      setListCitasFilter(response.data);
      let urgencia = [];
      let primeraVez = [];
      let seguimiento = [];
      response.data.forEach((element) => {
        if (element.TipoCita === 0) {
          urgencia.push(element);
        } else if (element.TipoCita === 1) {
          primeraVez.push(element);
        } else if (element.TipoCita === 2) {
          seguimiento.push(element);
        }
      });
      setListUrgencia(urgencia);
      setListPrimeraVez(primeraVez);
      setListSeguimiento(seguimiento);
      setListCitas(response.data);
    } else {
      setListUrgencia([]);
      setListPrimeraVez([]);
      setListSeguimiento([]);
      setListCitas([]);
      setOpen(true);
      setTypeMessage("error");
      setMessage(response.mensaje);
    }
    // console.log(estadoFiltro);

    setLoader(false);
    return;
  }

  async function getCitasDisponibles(date, hours) {
    setLoaderDetalle(true);
    let data = new FormData();
    let horarios_disponibles = [];
    // console.log(dateIni);
    // console.log(dateFin);
    data.append("FechaInicio", date);
    data.append("FechaFin", date);
    // let response = await getCitasDisponibles_request(data);
    let response = await getCitasFilter_request(4, data);
    if (response.ok) {
      // console.log(response.data);
      response.data.forEach((cita) => {
        let hora = `${cita.HoraCita.substring(0, 1) === "0"
            ? cita.HoraCita.substring(1, 5)
            : cita.HoraCita.substring(0, 5)
          } ${cita.HoraCita.substring(
            cita.HoraCita.length - 5,
            cita.HoraCita.length
          ) === "a. m."
            ? "a.m"
            : "p.m"
          }`;
        // console.log(hora);
        // console.log(hours.Horario);
        hours.Horario.forEach((horario) => {
          if (hora !== horario) {
            horarios_disponibles.push(horario);
          }
        });
      });
      // console.log(horarios_disponibles);
      // let temp = [];
      // Object.keys(response.data).forEach((x)=>{
      // console.log(response.data[x]);
      // });
      setListCitasOcupadas(response.data);
      setListHorariosDisponibles(horarios_disponibles);
    } else {
      setListCitasOcupadas([]);
      if (hours.Horario.length > 0) {
        hours.Horario.forEach((horario) => {
          // if (hora !== horario) {
          horarios_disponibles.push(horario);
          // }
        });
        setListHorariosDisponibles(horarios_disponibles);
      }

      // setOpen(true);
      // setTypeMessage("error");
      // setMessage(response.mensaje);
    }
    setLoaderDetalle(false);
    return;
  }

  async function detailCita(id, option) {
    openModalDetalle(true);
    setLoaderDetalle(true);
    let response = await detailCitas_request(option === 1 ? id : idCita);
    if (response.ok) {
      // console.log(response.data);
      setInfoCita(response.data);
      let fecha_temp = response.data.FechaCita.substring(
        response.data.FechaCita.length - 10,
        response.data.FechaCita.length
      );
      let year = fecha_temp.substring(fecha_temp.length - 4, fecha_temp.length);
      let month = fecha_temp.substring(
        fecha_temp.length - 7,
        fecha_temp.length - 5
      );
      let day = fecha_temp.substring(
        fecha_temp.length - 10,
        fecha_temp.length - 8
      );
      setFecha(year + "-" + month + "-" + day);
      // console.log(response.data.Comentarios);
      setEstatusCita(response.data.Estado);
      setHorario(response.data.HoraCita);
      setMotivo(response.data.Comentarios);
      setComentario(response.data.Adicional);
      setTipocita(
        response.data.TipoCita === "Primera vez"
          ? "1"
          : response.data.TipoCita === "Urgencia"
            ? "0"
            : "2"
      );
      cambiarFecha(year + "-" + month + "-" + day);
      if (option === 1) {
        setIdCita(id);
      }
      if (option === 2) {
        setInputsEditable(false);
        getHorarios(fecha);
      }
      // document.getElementById("motivo_cita").value = response.data.Comentarios ;
    } else {
      setOpen(true);
      setTypeMessage("error");
      setMessage(response.mensaje);
      CloseModalDetalle(true);
    }
    setLoaderDetalle(false);
    return;
  }

  async function getHorarios(fecha) {
    setLoaderDetalle(true);
    // console.log(fecha);
    // return;
    // let data = { "Fecha": "2023-02-15" };
    let data = { Fecha: fecha };
    let response = await getHorarios_request(data);
    if (response.ok) {
      // console.log(response.data);
      setOpen(true);
      setTypeMessage("success");
      setMessage("Horarios existentes");
      let array = [];
      response.data.forEach((element) => {
        array.push({ value: element, label: element });
      });
      // console.log(array);
      setHorarios(array);
      getCitasDisponibles(fecha, { Horario: response.data });
    } else {
      setHorarios([]);
      getCitasDisponibles(fecha, { Horario: [] });
      setOpen(true);
      setTypeMessage("error");
      setMessage(response.mensaje);
    }
    setLoaderDetalle(false);
    return;
  }

  async function getHorariosDisponibles(date) {
    setLoaderDetalle(true);
    let data = new FormData();
    // console.log(dateIni);
    // console.log(dateFin);
    data.append("fechaInicio", date);
    data.append("fechaFin", date);
    let response = await getCitasDisponibles_request(data);
    if (response.ok) {
      // console.log(response.data);
      getCitasDisponibles(date, response.data[0]);
    } else {
      // setOpen(true);
      // setTypeMessage("error");
      // setMessage(response.mensaje);
    }
    setLoaderDetalle(false);
    return;
  }

  async function getContactos() {
    setLoader(true);
    let response = await getContacts_request();
    if (response.ok) {
      // console.log(response.data);
      let list_temp = [];
      let list_secondary = [];
      response.data.forEach((element) => {
        if (element.Type === 3 || element.Type === 5) {
          list_temp.push({
            value: element.IdContacto,
            label: (
              <div style={{ display: "flex", gap: "1" }}>
                <img
                  className="ImgMedicoSelect"
                  src={
                    !!element.Imagen_url ? `https://${element.Imagen_url}` : ""
                  }
                />{" "}
                <div
                  style={{ marginLeft: "1%" }}
                >{`${element.Nombre} ${element.ApellidoPaterno} ${element.ApellidoMaterno}`}</div>
              </div>
            ),
            //   <div style={{ display:"flex"}}>
            //     <img
            //   style={{
            //   backgroundColor: "#dde3e6",
            //   borderRadius: "5rem",
            //   width: "45px",
            //   height: "45px"}}
            //   src={!!element.Imagen_url ? `https://${element.Imagen_url}`: Iconos[0]} alt=""/>
            // </div>,
          });
          list_secondary.push(element);
        }
      });
      setContactList(list_secondary);
      setContactListFilter(list_secondary);
    } else {
      // setOpen(true);
      // setTypeMessage("error");
      // setMessage(response.mensaje);
    }
    setLoader(false);
    return;
  }

  async function getConfigCitas() {
    let response = await getConfigCitas_request();
    if (response.ok) {
      setExistConfigCitas(1);
      // console.log(response.data);
    } else {
      setExistConfigCitas(2);
    }
  }

  async function saveCita() {
    // console.log("Guardando...");
    // console.log(!!AddTipocita);
    // console.log(AddTipocita);
    // console.log(!!AddHorario);
    // console.log(AddHorario);
    // console.log(idContacto);
    // console.log(id);
    // return;
    setLoaderDetalle(true);
    // return;
    if (AddTipocita === "3") {
      if (!!HorarioInicio && !!HorarioFin) {
        // console.log("Cita de procedimiento");
        return;
        let data = new FormData();
        data.append("Nombre", DatosContacto.id);
        data.append("Fecha", AddFecha);
        data.append("Hora", Horario);
        data.append("Comentarios", AddMotivo);
        data.append("Adicional", AddComentario);
        data.append("TipoCita", AddTipocita);
        let response = await addCita_request(data);
        if (response.ok) {
          setOpen(true);
          setTypeMessage("success");
          setMessage("Cita creada");
          clearInputs(1);
          CloseModalcitaCalendar(false);
          // getCitasFilterDate(FechaFilter);
          getCitasFilterDate(FechaInicio, FechaFin);
        } else {
          setOpen(true);
          setTypeMessage("error");
          setMessage(response.mensaje);
        }
      } else {
        setOpen(true);
        setTypeMessage("warning");
        setMessage("Faltan campos por completar");
        if (!!!AddTipocita) {
          setComboAddTipoCita("false");
        }

        if (!!!HorarioInicio || !!!HorarioFin) {
          setComboAddHorarioCita("false");
        }
      }
    } else {
      if (!!AddHorario && !!DatosContacto.id && !!AddTipocita) {
        let data = new FormData();
        data.append("Nombre", DatosContacto.id);
        data.append("Fecha", AddFecha);
        data.append("Hora", AddHorario);
        data.append("Comentarios", AddMotivo);
        data.append("Adicional", AddComentario);
        data.append("TipoCita", AddTipocita);
        let response = await addCita_request(data);
        if (response.ok) {
          setOpen(true);
          setTypeMessage("success");
          setMessage("Cita creada");
          clearInputs(1);
          // CloseModalcitaCalendar(false);
          CloseAddCitasDisponibles(false);
          // getCitasFilterDate(FechaFilter);
          getCitasFilterDate(FechaInicio, FechaFin);
        } else {
          setOpen(true);
          setTypeMessage("error");
          setMessage(response.mensaje);
        }
      } else {
        // console.log("AQUI");
        // console.log(DatosContacto.id.trim());
        if (!!!AddHorario) {
          setComboAddHorarioCita("false");
        }

        if (!!!DatosContacto.id) {
          // console.log(MensajeContacto);
          setMensajeContacto("false");
        }

        if (!!!AddTipocita) {
          setComboAddTipoCita("false");
        }

        setOpen(true);
        setTypeMessage("warning");
        setMessage("Faltan campos por completar");
      }
    }
    setLoaderDetalle(false);
    return;
  }

  async function saveCitaNewContact() {
    setLoaderDetalle(true);
    // return;
    if (AddTipocita === "3") {
      if (!!HorarioInicio && !!HorarioFin) {
        // console.log("Cita de procedimiento");
        return;
        let data = new FormData();
        data.append("Nombre", DatosContacto.id);
        data.append("Fecha", AddFecha);
        data.append("Hora", Horario);
        data.append("Comentarios", AddMotivo);
        data.append("Adicional", AddComentario);
        data.append("TipoCita", AddTipocita);
        let response = await addCita_request(data);
        if (response.ok) {
          setOpen(true);
          setTypeMessage("success");
          setMessage("Cita creada");
          clearInputs(1);
          CloseModalcitaCalendar(false);
          // getCitasFilterDate(FechaFilter);
          getCitasFilterDate(FechaInicio, FechaFin);
        } else {
          setOpen(true);
          setTypeMessage("error");
          setMessage(response.mensaje);
        }
      } else {
        setOpen(true);
        setTypeMessage("warning");
        setMessage("Faltan campos por completar");
        if (!!!AddTipocita) {
          setComboAddTipoCita("false");
        }

        if (!!!HorarioInicio || !!!HorarioFin) {
          setComboAddHorarioCita("false");
        }
      }
    } else {
      if (!!AddHorario && !!DatosContacto.id && !!AddTipocita) {
        let data = new FormData();
        data.append("Nombre", DatosContacto.id);
        data.append("Fecha", AddFecha);
        data.append("Hora", AddHorario);
        data.append("Comentarios", AddMotivo);
        data.append("Adicional", AddComentario);
        data.append("TipoCita", AddTipocita);
        let response = await addCita_request(data);
        if (response.ok) {
          setOpen(true);
          setTypeMessage("success");
          setMessage("Cita creada");
          clearInputs(1);
          // CloseModalcitaCalendar(false);
          CloseAddCitasDisponibles(false);
          // getCitasFilterDate(FechaFilter);
          getCitasFilterDate(FechaInicio, FechaFin);
        } else {
          setOpen(true);
          setTypeMessage("error");
          setMessage(response.mensaje);
        }
      } else {
        if (!!!AddHorario) {
          setComboAddHorarioCita("false");
        }

        if (!!!DatosContacto.id) {
          // console.log(MensajeContacto);
          setMensajeContacto("false");
        }

        if (!!!AddTipocita) {
          setComboAddTipoCita("false");
        }

        setOpen(true);
        setTypeMessage("warning");
        setMessage("Faltan campos por completar");
      }
    }
    setLoaderDetalle(false);
    return;
  }

  async function saveCitaNewContactDos(id) {
    setLoaderDetalle(true);
    // return;
    if (AddTipocita === "3") {
      if (!!HorarioInicio && !!HorarioFin) {
        // console.log("Cita de procedimiento");
        return;
        let data = new FormData();
        data.append("Nombre", !!DatosContacto.id ? DatosContacto.id : id);
        data.append("Fecha", AddFecha);
        data.append("Hora", Horario);
        data.append("Comentarios", AddMotivo);
        data.append("Adicional", AddComentario);
        data.append("TipoCita", AddTipocita);
        let response = await addCita_request(data);
        if (response.ok) {
          setOpen(true);
          setTypeMessage("success");
          setMessage("Cita creada");
          clearInputs(1);
          CloseModalcitaCalendar(false);
          // getCitasFilterDate(FechaFilter);
          getCitasFilterDate(FechaInicio, FechaFin);
        } else {
          setOpen(true);
          setTypeMessage("error");
          setMessage(response.mensaje);
        }
      } else {
        setOpen(true);
        setTypeMessage("warning");
        setMessage("Faltan campos por completar");
        if (!!!AddTipocita) {
          setComboAddTipoCita("false");
        }

        if (!!!HorarioInicio || !!!HorarioFin) {
          setComboAddHorarioCita("false");
        }
      }
    } else {
      if (!!AddHorario) {
        let data = new FormData();
        data.append("Nombre", id);
        data.append("Fecha", AddFecha);
        data.append("Hora", AddHorario);
        data.append("Comentarios", AddMotivo);
        data.append("Adicional", AddComentario);
        data.append("TipoCita", AddTipocita);
        let response = await addCita_request(data);
        if (response.ok) {
          setOpen(true);
          setTypeMessage("success");
          setMessage("Cita creada");
          clearInputs(1);
          CloseModalcitaCalendar(false);
          // getCitasFilterDate(FechaFilter);
          getCitasFilterDate(FechaInicio, FechaFin);
        } else {
          setOpen(true);
          setTypeMessage("error");
          setMessage(response.mensaje);
        }
      } else {
        // console.log("AQUI");
        // console.log(id.trim());
        if (!!!AddHorario) {
          setComboAddHorarioCita("false");
        }

        setOpen(true);
        setTypeMessage("warning");
        setMessage("Faltan campos por completar");
      }
    }
    setLoaderDetalle(false);
    return;
  }

  const reprogramarCita = async (e) => {
    e.preventDefault();
    setLoaderDetalle(true);
    // console.log("Guardando...");
    // console.log(!!Tipocita);
    // console.log(Tipocita);
    // console.log(!!Horario);
    // console.log(Horario);
    // console.log(idCita);
    // return;
    if (!!Tipocita && !!Horario && !!Fecha) {
      let data = new FormData();
      data.append("Fecha", Fecha);
      data.append("Hora", Horario);
      data.append("Comentarios", Motivo);
      data.append("Adicional", Comentario);
      data.append("TipoCita", Tipocita);
      let response = await reprogramarCita_request(data, idCita);
      if (response.ok) {
        setOpen(true);
        setTypeMessage("success");
        setMessage("Cita reagendada");
        clearInputs(2);
        CloseModalDetalle(true);
        // getCitasFilterDate(FechaFilter);
        getCitasFilterDate(FechaInicio, FechaFin);
        setInputsEditable(true);
      } else {
        setOpen(true);
        setTypeMessage("error");
        setMessage(response.mensaje);
      }
    } else {
      setOpen(true);
      setTypeMessage("warning");
      setMessage("Faltan campos por completar");
    }
    setLoaderDetalle(false);
    return;
  };

  async function saveContact() {
    // console.log(AddNombrecontacto);
    // console.log(AddApellidoPcontacto);
    // console.log(AddTipocita);
    // console.log(AddHorario);
    // return;
    setLoaderDetalle(true);
    let data = new FormData();
    if (
      !!AddNombrecontacto &&
      !!AddApellidoPcontacto &&
      MensajeAddNombrecontacto !== "false" &&
      MensajeAddApellidoPcontacto !== "false" &&
      MensajeAddApellidoMcontacto !== "false" &&
      MensajeAddCelularcontacto !== "false" &&
      MensajeAddCorreocontacto !== "false" &&
      !!AddTipocita &&
      !!AddHorario
    ) {
      data.append("Nombre", AddNombrecontacto);
      data.append("ApellidoPaterno", AddApellidoPcontacto);
      data.append("ApellidoMaterno", AddApellidoMcontacto);
      if (!!AddCelularcontacto) {
        data.append("Celular", AddCelularcontacto);
      }
      if (!!AddCorreocontacto) {
        data.append("Email", AddCorreocontacto);
      }

      data.append("Tipo", "Paciente");
      if (ProfileImage.file !== "") {
        data.append("Imagen", ProfileImage.file, ProfileImage.name);
      }
      let response = await addContact_request(data);
      if (response.ok) {
        setOpen(true);
        setTypeMessage("success");
        setMessage("Contacto guardado");
        // setIdContacto(response.data.IdContacto);
        cleanInputsContacto();
        ClosedAddContacto(true);
        saveCitaNewContact(response.data.IdContacto);

        // getContactos();
        // CloseModalcontacto1(false);
        // clean_inputsAdd();
      } else {
        setOpen(true);
        setTypeMessage("error");
        setMessage(response.mensaje);
      }
    } else {
      setOpen(true);
      setTypeMessage("warning");
      setMessage("Faltan campos por completar");
      if (!!!AddNombrecontacto) {
        setMensajeAddNombrecontacto("false");
      }

      if (!!!AddApellidoPcontacto) {
        setMensajeAddApellidoPcontacto("false");
      }

      if (!!!AddTipocita) {
        setComboAddTipoCita("false");
      }

      if (!!!AddHorario) {
        setComboAddHorarioCita("false");
      }
    }
    // console.log("AQUUI");
    setLoaderDetalle(false);
    return;
  }

  async function saveContactDos() {
    // console.log(AddNombrecontacto);
    // console.log(AddApellidoPcontacto);
    // console.log(AddTipocita);
    // console.log(AddHorario);
    // return;
    setLoaderDetalle(true);
    let data = new FormData();
    if (
      !!AddNombrecontacto &&
      !!AddApellidoPcontacto &&
      MensajeAddNombrecontacto !== "false" &&
      MensajeAddApellidoPcontacto !== "false" &&
      MensajeAddApellidoMcontacto !== "false" &&
      MensajeAddCelularcontacto !== "false" &&
      MensajeAddCorreocontacto !== "false"
    ) {
      data.append("Nombre", AddNombrecontacto);
      data.append("ApellidoPaterno", AddApellidoPcontacto);
      data.append("ApellidoMaterno", AddApellidoMcontacto);
      if (!!AddCelularcontacto) {
        data.append("Celular", AddCelularcontacto);
      }
      if (!!AddCorreocontacto) {
        data.append("Email", AddCorreocontacto);
      }

      data.append("Tipo", "Paciente");
      if (ProfileImage.file !== "") {
        data.append("Imagen", ProfileImage.file, ProfileImage.name);
      }
      let response = await addContact_request(data);
      if (response.ok) {
        // console.log(response.data);
        // return;
        getContactos();
        setOpen(true);
        setTypeMessage("success");
        setMessage("Contacto guardado");
        ClosedAddContacto(true);
        CloseModalcita1(true);
        cleanInputsContacto();
        setDatosContacto({
          id: response.data.IdContacto,
          nombre: `${response.data.Nombre} ${response.data.ApellidoPaterno} ${response.data.ApellidoMaterno}`,
          imagen: response.data.Imagen_url,
        });

        // openModalcitaCalendar(true);
        openAddCitasDisponibles(true);
        // getContactos();
        // CloseModalcontacto1(false);
        // clean_inputsAdd();
      } else {
        setOpen(true);
        setTypeMessage("error");
        setMessage(response.mensaje);
      }
    } else {
      if (!!!AddNombrecontacto) {
        setMensajeAddNombrecontacto("false");
      }

      if (!!!AddApellidoPcontacto) {
        setMensajeAddApellidoPcontacto("false");
      }

      setOpen(true);
      setTypeMessage("warning");
      setMessage("Faltan campos por completar");
    }
    // console.log("AQUUI");
    setLoaderDetalle(false);
    return;
  }

  async function cancelarCita() {
    let response = await cancelCitas_request(idCita);
    if (response.ok) {
      setOpen(true);
      setTypeMessage("success");
      setMessage("Cita cancelada");
      // getCitasFilterDate(FechaFilter);
      getCitasFilterDate(FechaInicio, FechaFin);
    } else {
      setOpen(true);
      setTypeMessage("error");
      setMessage(response.mensaje);
    }
  }

  async function confirmarCita() {
    setLoader(true);
    let response = await confirmCitas_request(idCita);
    if (response.ok) {
      setOpen(true);
      setTypeMessage("success");
      setMessage("Cita confirmada");
      // getCitasFilterDate(FechaFilter);
      getCitasFilterDate(FechaInicio, FechaFin);
    } else {
      setOpen(true);
      setTypeMessage("error");
      setMessage(response.mensaje);
    }
    setLoader(false);
    return;
  }

  async function atenderCita() {
    setLoader(true);
    let response = await atenderCitas_request(idCita);
    if (response.ok) {
      setOpen(true);
      setTypeMessage("success");
      setMessage("Cita atendida");
      // getCitasFilterDate(FechaFilter);
      getCitasFilterDate(FechaInicio, FechaFin);
    } else {
      setOpen(true);
      setTypeMessage("error");
      setMessage(response.mensaje);
    }
    setLoader(false);
    return;
  }

  async function eliminarCita() {
    setLoader(true);
    let response = await deleteCitas_request(idCita);
    if (response.ok) {
      setOpen(true);
      setTypeMessage("success");
      setMessage("Cita eliminada");
      // getCitasFilterDate(FechaFilter);
      getCitasFilterDate(FechaInicio, FechaFin);
      setEstatusModalEliminarCita(false);
    } else {
      setOpen(true);
      setTypeMessage("error");
      setMessage(response.mensaje);
    }
    setLoader(false);
    return;
  }

  async function contactDetail() {
    openEditContacto(true);
    // console.log(option);
    // console.log(id);
    // console.log(idContacto);
    // return;
    setLoaderDetalle(true);

    let response = await contactDetail_request(DatosContacto.id);
    if (response.ok) {
      setinfocontacto(response.data);
      setTipocontacto(response.data.Type.toString());
      setNombre(response.data.Nombre);
      setApellidoP(response.data.ApellidoPaterno);
      setApellidoM(response.data.ApellidoMaterno);
      setCelular(response.data.Celular);
      setCorreo(response.data.Email);
      setImagenContacto({
        name: "",
        url: `https://${response.data.Imagen_url}`,
        file: "",
        size: 0,
        formato: "",
      });
      // openEditContacto(true);
    } else {
      setOpen(true);
      setTypeMessage("error");
      setMessage(response.mensaje);
    }
    setLoaderDetalle(false);
    return;
  }

  async function editContact() {
    setLoaderDetalle(true);
    // return;
    if (
      !!Tipocontacto &&
      !!Nombre &&
      MensajeNombre === "true" &&
      !!ApellidoP &&
      MensajeApellidoP === "true" &&
      MensajeApellidoM === "true" &&
      MensajeCelular === "true" &&
      MensajeCorreo === "true"
    ) {
      let data = new FormData();
      data.append("Nombre", Nombre);
      data.append("ApellidoPaterno", ApellidoP);
      data.append("ApellidoMaterno", ApellidoM);
      data.append("Celular", Celular);
      data.append("Email", Correo);
      data.append("Tipo", Tipocontacto === "1" ? "Médico" : "Paciente");
      if (!!ImagenContacto.name) {
        data.append("Imagen", ImagenContacto.file, ImagenContacto.name);
      }

      let response = await editContact_request(data, infocontacto.IdContacto);
      if (response.ok) {
        setOpen(true);
        setTypeMessage("success");
        setMessage("Contacto editado");
        CloseEditContacto(true);
        getCitasFilterDate(FechaInicio, FechaFin);
        getContactos();
      } else {
        setOpen(true);
        setTypeMessage("error");
        setMessage(response.mensaje);
      }
    } else {
      if (!!!Nombre) {
        setMensajeNombre("false");
      }

      if (!!!ApellidoP) {
        setMensajeApellidoP("false");
      }

      setOpen(true);
      setTypeMessage("warning");
      setMessage("Faltan campos por completar");
    }
    setLoaderDetalle(false);
    return;
  }

  async function addFichaContacto() {
    setLoader(true);
    if (!!Sexo && !!Nacimiento) {
      // let data = new FormData();
      let data = {
        idUsuario: DatosContacto.id,
        antecedentesHereditarios: "",
        antecedentesPatologicos: "",
        antecedentesNoPatologicos: "",
        sintomasGenerales: "",
        aparatoRespiratorio: "",
        aparatoDigestivo: "",
        aparatoCardiovascular: "",
        sistemaNefrourologico: "",
        sistemaEndocrino: "",
        sistemaHematopoyetico: "",
        sistemaNervioso: "",
        sistemaMusculo: "",
        piel: "",
        organosSentidos: "",
        esferaPsiquica: "",
        tipoSangre: TipoSangre,
        alergias: Alergias,
        observaciones: ObservacionesPaciente,
        refiere: Refiere,
        sexo: Sexo,
        nacimiento: Nacimiento,
        nacionalidad: Nacionalidad,
        estado: Estado,
        localidad: Localidad,
        cp: "",
        peso: Peso,
        altura: Altura,
        Talla: Talla,
        tArterial: TArterial,
        fCardiaca: FCardiaca,
        fRespiratoria: FRespiratoria,
        sOxigeno: SOxigeno,
        temperatura: Temperatura,
        // exploracionFisica: ExploracionFisica,
        ocupacion: Ocupacion,
        estadoCivil: EstadoCivil,
        nombrePareja: NombrePareja,
        edadPareja: EdadPareja,
        ocupacionPareja: OcupacionPareja,
        nota: NotasPareja,
      };

      let response = await addExpediente_request(data, DatosContacto.id);
      if (response.ok) {
        setOpen(true);
        setTypeMessage("success");
        setMessage("ficha guardada");
        cleanInputsFicha();
        CloseFichaContacto(true);
      } else {
        setOpen(true);
        setTypeMessage("error");
        setMessage(response.mensaje);
      }
    } else {
      // cambiarNotasValido(false);
      if (!!!Sexo) {
        setMensajeSexo("false");
      }
      if (!!!Nacimiento) {
        setMensajeNacimiento("false");
      }

      setOpen(true);
      setTypeMessage("warning");
      setMessage("Faltan campos por completar");
    }
    setLoader(false);
    return;
  }

  async function getHistoriaClinica() {
    setLoaderDetalle(true);
    let response = await getHistoriasClinicas_request(DatosContacto.id);
    if (response.ok) {
      // console.log(response.data);
      let historial = response.data.historial;
      let fichaId = response.data.ficha;
      setDataFichaId({
        historial: response.data.historial,
        ficha: response.data.ficha,
      });
      // setSexo(data.);
      setTipoSangre(historial.tipoSangre);
      setAlergias(historial.alergias);
      setRefiere(historial.refiere);

      setAltura(!!historial.altura ? historial.altura : "");
      setTArterial(historial.tArterial);
      setFCardiaca(historial.fCardiaca);
      setFRespiratoria(historial.fRespiratoria);
      setTemperatura(historial.temperatura);
      setAltura(historial.altura);
      setPeso(historial.peso);
      setEstadoCivil(
        historial.estadoCivil !== undefined
          ? historial.estadoCivil !== null
            ? historial.estadoCivil.toString()
            : null
          : null
      );
      setOcupacion(historial.ocupacion);
      setNombrePareja(historial.nombrePareja);
      setEdadPareja(historial.edadPareja);
      setOcupacionPareja(historial.ocupacionPareja);
      setNotasPareja(historial.nota);
      // setIMC(historial.)
      if (!!historial.peso && !!historial.altura) {
        let peso_temp = historial.peso;
        let altura_temp = historial.altura;
        let imc = peso_temp / (altura_temp * altura_temp);
        setIMC(imc.toFixed(2));
      }
      setSexo(!!fichaId.sexo && fichaId.sexo!== null ? fichaId.sexo : "");

      if (!!fichaId.fechaNacimiento) {
        let arrayFechaTemp = fichaId.fechaNacimiento.split("-");
        let fechaTemp = `${arrayFechaTemp[2]}-${arrayFechaTemp[1]}-${arrayFechaTemp[0]}`;
        getEdad(fechaTemp);
        setNacimiento(fechaTemp);
      }
      setNacionalidad(fichaId.nacionalidad);
      if (!!fichaId.estado) {
        setEstado(fichaId.estado);
        getLocalidades(fichaId.estado);
        setLocalidad(fichaId.localidad);
      }
      // setCodigoPostal(fichaId.cp);
      // console.log(data.estudios);
    } else {
      setOpen(true);
      setTypeMessage("error");
      setMessage(response.mensaje);
    }
    setLoaderDetalle(false);
    return;
  }

  async function getEstados() {
    let response = await getEstado_request();
    if (response.ok) {
      // console.log(response.data);
      let estados_temp = [];
      response.data.forEach((estado) => {
        estados_temp.push({
          value: estado.id.toString(),
          label: estado.Estado,
        });
      });
      setEstadosList(estados_temp);
    } else {
      setEstadosList([]);
    }
  }

  async function getLocalidades(estado) {
    let response = await getLocalidad_request(estado);
    if (response.ok) {
      // console.log(response.data);
      let localidades_temp = [];
      response.data.forEach((estado) => {
        localidades_temp.push({ value: estado, label: estado });
      });
      setLocalidadesList(localidades_temp);
    } else {
      setLocalidadesList([]);
    }
  }

  function cleanInputsContacto() {
    setAddNombrecontacto("");
    setAddApellidoPcontacto("");
    setAddApellidoMcontacto("");
    setAddCelularcontacto("");
    setAddCorreocontacto("");
    setMensajeAddNombrecontacto("true");
    setMensajeAddApellidoPcontacto("true");
    setMensajeAddApellidoMcontacto("true");
    setMensajeAddCelularcontacto("true");
    setMensajeAddCorreocontacto("true");
  }

  function filterContacts(text) {
    var chars = {
      á: "a",
      é: "e",
      í: "i",
      ó: "o",
      ú: "u",
      à: "a",
      è: "e",
      ì: "i",
      ò: "o",
      ù: "u",
      ñ: "n",
      Á: "A",
      É: "E",
      Í: "I",
      Ó: "O",
      Ú: "U",
      À: "A",
      È: "E",
      Ì: "I",
      Ò: "O",
      Ù: "U",
      Ñ: "N",
    };
    var expr = /[áàéèíìóòúùñ]/gi;
    let filter = [];
    contactListFilter.forEach((contacto) => {
      if (
        contacto.Nombre.replace(expr, function (e) {
          return chars[e];
        })
          .toLowerCase()
          .indexOf(text.target.value.toLowerCase()) > -1
      ) {
        filter.push(contacto);
      }
    });

    setContactList(filter);
  }

  function filterList(text) {
    // console.log(text.target.value);
    // console.log(solicitudesSearch);
    var chars = {
      á: "a",
      é: "e",
      í: "i",
      ó: "o",
      ú: "u",
      à: "a",
      è: "e",
      ì: "i",
      ò: "o",
      ù: "u",
      ñ: "n",
      Á: "A",
      É: "E",
      Í: "I",
      Ó: "O",
      Ú: "U",
      À: "A",
      È: "E",
      Ì: "I",
      Ò: "O",
      Ù: "U",
      Ñ: "N",
    };
    var expr = /[áàéèíìóòúùñ]/gi;

    let filterUrgencia = [];
    let filterPrimeraVez = [];
    let filterSeguimiento = [];
    let filterTodas = [];
    listCitasFilter.forEach((cita) => {
      // if (!!cita.ApellidoMaterno) {
      if (
        cita.Full_Name.replace(expr, function (e) {
          return chars[e];
        })
          .toLowerCase()
          .indexOf(
            text.target.value
              .replace(expr, function (e) {
                return chars[e];
              })
              .toLowerCase()
          ) > -1 ||
        cita.FechaCita.replace(expr, function (e) {
          return chars[e];
        })
          .toLowerCase()
          .indexOf(
            text.target.value
              .replace(expr, function (e) {
                return chars[e];
              })
              .toLowerCase()
          ) > -1 ||
        cita.Estado.replace(expr, function (e) {
          return chars[e];
        })
          .toLowerCase()
          .indexOf(
            text.target.value
              .replace(expr, function (e) {
                return chars[e];
              })
              .toLowerCase()
          ) > -1
      ) {
        if (cita.TipoCita === 0) {
          filterUrgencia.push(cita);
        } else if (cita.TipoCita === 1) {
          filterPrimeraVez.push(cita);
        } else if (cita.TipoCita === 2) {
          filterSeguimiento.push(cita);
        }
        filterTodas.push(cita);
      }
    });
    setListUrgencia(filterUrgencia);
    setListPrimeraVez(filterPrimeraVez);
    setListSeguimiento(filterSeguimiento);
    setListCitas(filterTodas);
  }

  function filterOptions(input, option) {
    var chars = {
      á: "a",
      é: "e",
      í: "i",
      ó: "o",
      ú: "u",
      à: "a",
      è: "e",
      ì: "i",
      ò: "o",
      ù: "u",
      ñ: "n",
      Á: "A",
      É: "E",
      Í: "I",
      Ó: "O",
      Ú: "U",
      À: "A",
      È: "E",
      Ì: "I",
      Ò: "O",
      Ù: "U",
      Ñ: "N",
    };
    var expr = /[áàéèíìóòúùñ]/gi;
    return (
      option?.label
        .replace(expr, function (e) {
          return chars[e];
        })
        .toLowerCase()
        .indexOf(
          input
            .replace(expr, function (e) {
              return chars[e];
            })
            .toLowerCase()
        ) > -1
    );
  }

  function filterSort(optionA, optionB) {
    var chars = {
      á: "a",
      é: "e",
      í: "i",
      ó: "o",
      ú: "u",
      à: "a",
      è: "e",
      ì: "i",
      ò: "o",
      ù: "u",
      ñ: "n",
      Á: "A",
      É: "E",
      Í: "I",
      Ó: "O",
      Ú: "U",
      À: "A",
      È: "E",
      Ì: "I",
      Ò: "O",
      Ù: "U",
      Ñ: "N",
    };
    var expr = /[áàéèíìóòúùñ]/gi;
    return (
      optionA?.label
        .replace(expr, function (e) {
          return chars[e];
        })
        .toLowerCase() ?? ""
    )
      .replace(expr, function (e) {
        return chars[e];
      })
      .toLowerCase()
      .localeCompare(
        (
          optionB?.label
            .replace(expr, function (e) {
              return chars[e];
            })
            .toLowerCase() ?? ""
        )
          .replace(expr, function (e) {
            return chars[e];
          })
          .toLowerCase()
      );
  }

  function clearInputs(opcion) {
    if (opcion === 1) {
      setDatosContacto({ id: "", nombre: "", imagen: "" });
      setAddTipoContacto(null);
      setAddTipocita(null);
      setAddHorario(null);
      setAddMotivo("");
      setAddFecha(fecha_hoy);
      setAddComentario("");
    } else {
      setTipocita(null);
      setHorario(null);
      setMotivo("");
      setFecha(fecha_hoy);
    }
  }

  function openDeleteCitas() {
    confirm();
  }

  function AgregarCita() {
    if (!!AddTipocita) {
      if (AddTipocita === "3") {
        saveCita();
      } else {
        // if (!!AddTipoContacto) {
        // if (AddTipoContacto === "0") {
        saveCita();
        // } else {
        //   saveContact();
        // }
        // } else {
        //   if (!!!AddTipoContacto) {
        //     setMensajeAddTipoContacto("false");
        //   }

        //   setOpen(true);
        //   setTypeMessage("warning");
        //   setMessage("Faltan campos por completar");
        // }
      }
    } else {
      // if (!!!AddTipoContacto) {
      //   setMensajeAddTipoContacto("false");
      // }

      if (!!!AddTipocita) {
        setComboAddTipoCita("false");
      }

      if (!!!AddHorario) {
        setComboAddHorarioCita("false");
      }

      setOpen(true);
      setTypeMessage("warning");
      setMessage("Faltan campos por completar");
    }
  }

  function validacionImagen(e) {
    //aquí evaluamos si ya hay imagenes antes de este input, para saber en dónde debe empezar el index del proximo array
    let formato = e.currentTarget.files[0].type.split("/");
    // console.log(formato);
    if (formato[1] === "jpeg" || formato[1] === "png") {
      if (e.currentTarget.files[0].size <= 5000000) {
        const files = e.currentTarget.files;

        //el array con las imagenes nuevas
        let arrayImages = "";

        Object.keys(files).forEach((i) => {
          const file = files[i];
          let url = URL.createObjectURL(file);
          let formato = file.type.split("/");
          arrayImages = {
            name: file.name,
            url: url,
            file: file,
            size: file.size,
            formato: formato[1],
          };
        });

        setImagenContacto(arrayImages);
      } else {
        setOpen(true);
        setTypeMessage("warning");
        setMessage("La imagen excede el tamaño maximo permitido (5MB)");
      }
    } else {
      setOpen(true);
      setTypeMessage("warning");
      setMessage("Solo se permiten imagenes");
    }

    document.getElementById("imagenEditContacto").value = "";
  }

  function getEdad(dateString) {
    let hoy = new Date();
    let fNacimiento = new Date(dateString.replace(/-/g, "/"));
    // console.log(fNacimiento);
    let edad = hoy.getFullYear() - fNacimiento.getFullYear();
    let diferenciaMeses = hoy.getMonth() - fNacimiento.getMonth();
    if (
      diferenciaMeses < 0 ||
      (diferenciaMeses === 0 && hoy.getDate() < fNacimiento.getDate())
    ) {
      edad--;
    }
    // console.log(edad);
    setEdad(edad);
    // return edad;
  }

  function cleanInputsFicha() {
    // VARIABLES MODAL FICHA CONTACTO
    setTipoSangre("");
    setAlergias("");
    setRefiere("");
    setObservacionesPaciente("");
    setEstadoCivil(null);
    setOcupacion("");
    setSexo(null);
    setNacimiento("");
    setNacionalidad("");
    setEstado(null);
    setLocalidad(null);
    setNombrePareja("");
    setEdadPareja("");
    setOcupacionPareja("");
    setNotasPareja("");

    // EXPLORACION FISICA
    setTArterial("");
    setFCardiaca("");
    setFRespiratoria("");
    setTemperatura("");
    setAltura("");
    setPeso("");
    setIMC("");
    setTalla("");
    setSOxigeno("");
    setExploracionFisica("");

    setMensajeNacimiento("true");
    setMensajeSexo("true");
  }

  // MODAL ELIMINAR CITA
  const [modal, ModalEliminarCita] = Modal.useModal();
  const confirm = () => {
    modal.confirm({
      title: "¿Desea eliminar esta cita?",
      okText: "Confirmar",
      cancelText: "Cancelar",
      onOk: () => eliminarCita(),
      onCancel: () => setEstatusModalEliminarCita(false),
      open: EstatusModalEliminarCita,
      icon: <ExclamationCircleOutlined />,
      content: (
        <div>
          <p>Paciente: {DatosCita.nombre}</p>
          <p>Fecha: {DatosCita.fecha}</p>
          <p>Hora: {DatosCita.hora}</p>
          <p>
            Tipo:{" "}
            {DatosCita.tipo === 0
              ? "Urgencia"
              : DatosCita.tipo === 1
                ? "Primera vez"
                : "Seguimiento"}
          </p>
          <p>Estado: {DatosCita.estado}</p>
        </div>
      ),
    });
  };

  // console.log(fecha_format.getFullYear());

  useEffect(() => {
    // getCitas();
    // getCitasFilterDate(FechaFilter);
    getCitasFilterDate(FechaInicio, FechaFin);
    getContactos();
    getConfigCitas();
    getEstados();
  }, []);

  // console.log(moment("es-mx"));
  return (
    <Stack>
      {/* <div className="contenedorPrincipal"> */}
      <NavbarNuevo
        mensajesError={{
          setOpen: setOpen,
          setTypeMessage: setTypeMessage,
          setMessage: setMessage,
        }}
        searchBar={
          <>
            <div className="Cebecera">
              <div className="Encabesado">
                <div>
                  <b>Citas</b>
                </div>
                <div
                  style={{
                    color: "#148f9f",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <img
                    onClick={() => {
                      navigate("/app/CitasConfig");
                    }}
                    style={{ width: "20px" }}
                    src={Confi}
                    alt=""
                  />
                  &nbsp;&nbsp;
                  <Row
                    className="BotonNewCita"
                    onClick={() => {
                      if (existConfigCitas === 1) {
                        getHorarios(fecha_hoy);
                        // getHorariosDisponibles(fecha_hoy);
                        openModalcitaCalendar(true);
                        // openAddCitasDisponibles(true);
                        clearInputs(1);
                      } else {
                        setOpenSetting(true);
                        setTypeMessage("warning");
                        setMessage(
                          "Configure un horario en caso de ya tenerlo recarga la paginá"
                        );
                        // window.location.reload(true);
                      }
                    }}
                  >
                    <b>Nueva</b>
                    <img style={{ width: "12px" }} src={Agregarcitas} alt="" />
                  </Row>
                </div>
              </div>
              <div className="searchBarFiltro">
                <Input
                  type="text"
                  className="inputblue"
                  placeholder="Nombre o estado"
                  onChange={filterList}
                />
                <div style={{ display: "flex", width: "100%" }}>
                  {/* <DatePicker
                    // inputReadOnly={true}
                    placeholder="DD/MM/AAAA"
                    value={!!FechaFilter ? dayjs(FechaFilter) : ""}
                    format={dateFormatList}
                    locale={locale}
                    style={{ margin: ".8% 0" }}
                    size="small"
                    onBlur={(e) => {
                      // console.log(e.target.value);
                      // console.log(e.target.value.split("-"));
                      let arrayFechaFormat = e.target.value.split("-");
                      let arrayFecha = e.target.value.split("/");
                      if (arrayFecha.length === 3) {
                        let fecha = new Date(`${arrayFecha[1]}/${arrayFecha[0]}/${arrayFecha[2]}`);
                        // let temp = e.$d
                        // console.log(fecha.getMonth());
                        let mesObtenido = fecha.getMonth() + 1;
                        // console.log(mesObtenido);
                        let mesCalculo = mesObtenido.toString().length === 1 ? `0${mesObtenido}` : mesObtenido;
                        // console.log(mesCalculo);
                        let fecha_enviar = fecha.getFullYear() + "-" + mesCalculo + "-" + fecha.getDate();
                        // console.log(fecha_enviar);
                        setFechaFilter(fecha_enviar);
                        getCitasFilterDate(fecha_enviar);
                      } else if (arrayFechaFormat.length === 3) {
                        let fecha = new Date(`${arrayFechaFormat[1]}/${arrayFechaFormat[0]}/${arrayFechaFormat[2]}`);
                        // let temp = e.$d
                        // console.log(fecha.getMonth());
                        let mesObtenido = fecha.getMonth() + 1;
                        // console.log(mesObtenido);
                        let mesCalculo = mesObtenido.toString().length === 1 ? `0${mesObtenido}` : mesObtenido;
                        // console.log(mesCalculo);
                        let fecha_enviar = fecha.getFullYear() + "-" + mesCalculo + "-" + fecha.getDate();
                        setFechaFilter(fecha_enviar);
                        getCitasFilterDate(fecha_enviar);
                      }

                    }}
                    onSelect={(e) => {
                      let temp = e.$d;
                      let mesObtenido = temp.getMonth() + 1;
                      // console.log(mesObtenido);
                      let mesCalculo = mesObtenido.toString().length === 1 ? `0${mesObtenido}` : mesObtenido;
                      let fecha_enviar = temp.getFullYear() + "-" + mesCalculo + "-" + temp.getDate();
                      setFechaFilter(fecha_enviar);
                      getCitasFilterDate(fecha_enviar);
                    }}
                  /> */}

                  <RangePicker
                    value={[
                      !!FechaInicio ? dayjs(FechaInicio) : "",
                      !!FechaFin ? dayjs(FechaFin) : "",
                    ]}
                    className="rangeCitasPrincipal"
                    // dropdownClassName="rangeDropdown"
                    // dropdownAlign={"center"}
                    // popupStyle={{display:"flex"}}
                    // popupClassName="rangeDropdown"
                    locale={locale}
                    format={dateFormatList}
                    inputReadOnly={true}
                    onChange={(e) => {
                      // console.log(e);
                      let tempIni = e[0].$d;
                      let tempFin = e[1].$d;
                      let mesObtenidoIni = tempIni.getMonth() + 1;
                      let mesObtenidoFin = tempFin.getMonth() + 1;
                      // console.log(mesObtenido);
                      let mesCalculoIni =
                        mesObtenidoIni.toString().length === 1
                          ? `0${mesObtenidoIni}`
                          : mesObtenidoIni;
                      let mesCalculoFin =
                        mesObtenidoFin.toString().length === 1
                          ? `0${mesObtenidoFin}`
                          : mesObtenidoFin;
                      let fecha_enviarIni =
                        tempIni.getFullYear() +
                        "-" +
                        mesCalculoIni +
                        "-" +
                        tempIni.getDate();
                      let fecha_enviarFin =
                        tempFin.getFullYear() +
                        "-" +
                        mesCalculoFin +
                        "-" +
                        tempFin.getDate();
                      setFechaInicio(fecha_enviarIni);
                      setFechaFin(fecha_enviarFin);
                      getCitasFilterDate(fecha_enviarIni, fecha_enviarFin);
                    }}
                  />
                  {/* <img
                    onClick={() => {
                      openModalFiltro(true);
                      setMensajeFechaInicio("true");
                      setMensajeFechaFin("true");
                    }}
                    style={{ width: "30px", cursor: "pointer" }}
                    src={Filtrocitas} alt=""
                  /> */}
                  {/* {FiltroActive && (
                    <Button
                      style={{
                        background: "white",
                        color: "#148F9F",
                        border: "0",
                        height: "30px",
                        padding: "0 1%",
                        margin: ".8% 0",
                        // marginLeft: "3%",
                        marginRight: "0",
                      }}
                      onClick={() => {
                        setFiltroActive(false);
                        setEstadoFiltro("Todos");
                        setFechaFilter(fecha_hoy);
                        getCitasFilterDate(fecha_hoy);
                      }}><Cancel sx={{ color: "#148f9f" }} />Filtro</Button>
                  )} */}
                </div>
              </div>

              <div style={{ display: "flex", gap: "1rem", marginTop: "1%" }}>
                <Row style={{ alignItems: "center" }}>
                  <CheckCircleOutline color="disabled" />
                  Pendiente
                </Row>
                <Row style={{ alignItems: "center" }}>
                  <CheckCircleOutline color="warning" />
                  Confirmada
                </Row>
                <Row style={{ alignItems: "center" }}>
                  <CheckCircleOutline color="success" /> Atendida
                </Row>
                <Row style={{ alignItems: "center" }}>
                  <CancelOutlined color="error" /> Cancelada
                </Row>
              </div>
            </div>
            <div className="botonescitass">
              <div
                className={
                  toggleState === 1 ? "tabsCitass active-tabss" : "tabsCitass"
                }
                onClick={() => toggleTab(1)}
              >
                Todas
              </div>
              <div
                className={
                  toggleState === 2 ? "tabsCitass active-tabss" : "tabsCitass"
                }
                onClick={() => toggleTab(2)}
              >
                Primera vez
              </div>
              <div
                className={
                  toggleState === 3 ? "tabsCitass active-tabss" : "tabsCitass"
                }
                onClick={() => toggleTab(3)}
              >
                Seguimiento
              </div>
              <div
                className={
                  toggleState === 4 ? "tabsCitass active-tabss" : "tabsCitass"
                }
                onClick={() => toggleTab(4)}
              >
                Urgencia
              </div>
            </div>
          </>
        }
      />

      <Content
        className="site-layout"
        style={{
          padding: "0 3%",
          // height: "calc(70vh - 52px)",
          width: "100%",
          overflow: "initial",
        }}
      >
        {loader ? (
          <Loader />
        ) : (
          <>
            {/* <div className={toggleState === 1 ? "contenido  active-contenido" : "contenido"} style={{ height: "70%" }}> */}
            {toggleState === 1 && (
              <>
                <Cards
                  openModalDetalle={openModalDetalle}
                  listCitas={listCitas}
                  setIdCita={setIdCita}
                  detailCita={detailCita}
                  getHistoriaClinica={getHistoriaClinica}
                  cancelarCita={cancelarCita}
                  // eliminarCita={eliminarCita}
                  ModalEliminarCita={openDeleteCitas}
                  setTipoConsulta={setTipoConsulta}
                  openAddNota={OpenModalAddNota}
                  datosContacto={setDatosContacto}
                  atenderCita={atenderCita}
                  confirmarCita={confirmarCita}
                  setDatosCita={setDatosCita}
                  contactDetail={contactDetail}
                />
                {listCitas.length === 0 && <p className="noCitas">Sin citas</p>}
              </>
            )}
            {/* </div> */}
            {/* <div className={toggleState === 2 ? "contenido  active-contenido" : "contenido"} style={{ height: "70%" }}> */}

            {toggleState === 2 && (
              <>
                <Cards
                  openModalDetalle={openModalDetalle}
                  listCitas={listPrimeraVez}
                  setIdCita={setIdCita}
                  detailCita={detailCita}
                  getHistoriaClinica={getHistoriaClinica}
                  cancelarCita={cancelarCita}
                  // eliminarCita={eliminarCita}
                  ModalEliminarCita={openDeleteCitas}
                  setTipoConsulta={setTipoConsulta}
                  openAddNota={OpenModalAddNota}
                  datosContacto={setDatosContacto}
                  atenderCita={atenderCita}
                  confirmarCita={confirmarCita}
                  setDatosCita={setDatosCita}
                  contactDetail={contactDetail}
                />
                {listPrimeraVez.length === 0 && (
                  <p className="noCitas">Sin citas de primera vez</p>
                )}
              </>
            )}
            {/* </div> */}

            {/* <div className={toggleState === 3 ? "contenido  active-contenido" : "contenido"}> */}

            {toggleState === 3 && (
              <>
                <Cards
                  openModalDetalle={openModalDetalle}
                  listCitas={listSeguimiento}
                  setIdCita={setIdCita}
                  detailCita={detailCita}
                  getHistoriaClinica={getHistoriaClinica}
                  cancelarCita={cancelarCita}
                  // eliminarCita={eliminarCita}
                  ModalEliminarCita={openDeleteCitas}
                  setTipoConsulta={setTipoConsulta}
                  openAddNota={OpenModalAddNota}
                  datosContacto={setDatosContacto}
                  atenderCita={atenderCita}
                  confirmarCita={confirmarCita}
                  setDatosCita={setDatosCita}
                  contactDetail={contactDetail}
                />
                {listSeguimiento.length === 0 && (
                  <p className="noCitas">Sin citas de seguimiento</p>
                )}
              </>
            )}
            {/* </div> */}
            {/* <div className={toggleState === 4 ? "contenido  active-contenido" : "contenido"}> */}

            {toggleState === 4 && (
              <>
                <Cards
                  openModalDetalle={openModalDetalle}
                  listCitas={listUrgencia}
                  setIdCita={setIdCita}
                  detailCita={detailCita}
                  getHistoriaClinica={getHistoriaClinica}
                  cancelarCita={cancelarCita}
                  // eliminarCita={eliminarCita}
                  ModalEliminarCita={openDeleteCitas}
                  setTipoConsulta={setTipoConsulta}
                  openAddNota={OpenModalAddNota}
                  datosContacto={setDatosContacto}
                  atenderCita={atenderCita}
                  confirmarCita={confirmarCita}
                  setDatosCita={setDatosCita}
                  contactDetail={contactDetail}
                />
                {listUrgencia.length === 0 && (
                  <p className="noCitas">Sin citas de urgencia</p>
                )}
              </>
            )}
            {/* </div> */}
          </>
        )}
        {/* </div> */}
      </Content>

      {/* </div> */}
      <ModalDetalle isOpen={isOpenModalDetalle} closeModal={CloseModalDetalle}>
        <div>
          <div className="TopCitasModal">
            <b
              style={{ cursor: "pointer" }}
              onClick={() => {
                if (inputsEditable) {
                  // setOpenDetalle(0);
                  CloseModalDetalle(true);
                } else {
                  setInputsEditable(true);
                  setMotivo(infoCita.Comentarios);
                  setHorario(infoCita.HoraCita);
                }
              }}
            >
              X
            </b>
            <b style={{ color: "#ffffff" }}>Detalle de cita</b>
            {inputsEditable ? (
              infoCita.Estado === "Confirmada" ||
                infoCita.Estado === "Pendiente" ? (
                <img
                  onClick={() => {
                    setInputsEditable(false);
                    getHorarios(fecha);
                  }}
                  src={Editarcita}
                  style={{ width: "20px", cursor: "pointer" }}
                  alt=""
                />
              ) : (
                <div></div>
              )
            ) : (
              <img
                onClick={reprogramarCita}
                src={CheckGuardar}
                style={{ width: "20px", cursor: "pointer" }}
                alt=""
              />
            )}
          </div>
          <div className="ModalDetalleCita">
            {loaderDetalle ? (
              <LoaderModals />
            ) : (
              <div className="GridItem2">
                <div className="all">
                  {(infoCita.Estado === "Confirmada" ||
                    infoCita.Estado === "Pendiente") && (
                      <div style={{ display: "flex", justifyContent: "end" }}>
                        <Button
                          type="button"
                          style={{
                            background: "#148f9f",
                            border: "0",
                            color: "white",
                            display: "flex",
                            alignItems: "center",
                            gap: ".5rem",
                          }}
                          onClick={() => {
                            CloseModalDetalle(true);
                            openFichaContacto(true);
                            getHistoriaClinica();
                          }}
                        >
                          Ficha
                          <EyeFilled />
                        </Button>
                      </div>
                    )}
                  <img
                    className="Img-contacto"
                    id="Img-cont"
                    src={
                      !!infoCita.Imagen_url
                        ? "https://" + infoCita.Imagen_url
                        : Imag
                    }
                    alt=""
                  />
                  <h3>{infoCita.Full_Name}</h3>

                  <div className="Contenido-detalle">
                    {!inputsEditable && (
                      <Calendar
                        locale={locale}
                        value={dayjs(Fecha)}
                        fullscreen={false}
                        onSelect={(e) => {
                          let temp = e.$d;
                          let mesObtenido = temp.getMonth() + 1;
                          let mesCalculo =
                            mesObtenido.toString().length === 1
                              ? `0${mesObtenido}`
                              : mesObtenido;
                          let fecha_enviar =
                            temp.getFullYear() +
                            "-" +
                            mesCalculo +
                            "-" +
                            temp.getDate();
                          setHorarios([]);
                          setFecha(fecha_enviar);
                          getHorarios(fecha_enviar);
                          setHorario("");
                        }}
                      />
                    )}
                    {inputsEditable && (
                      <InputAntd
                        className="inputblue"
                        value={fecha}
                        // setStateValue={setAddNombrecontacto}
                        label="Fecha:"
                        style={{ margin: "2% 0" }}
                        type="date"
                        disabled={true}
                      />
                    )}
                    <SelectAntd
                      label="Horario:"
                      placeholder="Elige un horario"
                      options={horarios}
                      setStateValue={setHorario}
                      setStateError={setComboAddHorarioCita}
                      textError="Debe seleccionar un horario"
                      value={Horario}
                      valueError={ComboAddHorarioCita}
                      style={{ width: "100%", fontWeight: "normal" }}
                      disabled={inputsEditable}
                    />

                    <SelectAntd
                      label="Tipo cita:"
                      placeholder="Elige un tipo de cita"
                      options={[
                        { value: "0", label: "Urgencia" },
                        { value: "1", label: "Primera Vez" },
                        { value: "2", label: "Seguimiento" },
                      ]}
                      style={{ width: "100%", fontWeight: "normal" }}
                      setStateValue={setTipocita}
                      value={Tipocita}
                      disabled={inputsEditable}
                    />

                    <InputAntd
                      className="inputblue"
                      value={infoCita.Estado}
                      // setStateValue={setAddNombrecontacto}
                      label="Estatus:"
                      style={{
                        color:
                          infoCita.Estado === "Confirmada"
                            ? "#EB9C37"
                            : infoCita.Estado === "Cancelada"
                              ? "red"
                              : infoCita.Estado === "Atendida"
                                ? "green"
                                : "grey",
                        margin: "2% 0",
                      }}
                      type="text"
                      readOnly={true}
                    />

                    <InputAntd
                      className="inputblue"
                      value={infoCita.Creado}
                      // setStateValue={setAddNombrecontacto}
                      label="Cita creada por:"
                      style={{ margin: "2% 0" }}
                      type="text"
                      readOnly={true}
                    />

                    <TextAreaAntd
                      label="Motivo de cita:"
                      value={Motivo}
                      placeholder="Escriba un motivo de cita (opcional)"
                      style={{
                        marginBottom: ".5%",
                        fontFamily: "sans-serif",
                      }}
                      columns="30"
                      autoSize={true}
                      setStateValue={setMotivo}
                      readOnly={inputsEditable}
                    />

                    <TextAreaAntd
                      label="Comentarios o notas:"
                      value={Comentario}
                      placeholder="Escriba un comentario o nota (opcional)"
                      style={{
                        marginBottom: ".5%",
                        fontFamily: "sans-serif",
                      }}
                      columns="30"
                      autoSize={true}
                      setStateValue={setComentario}
                      readOnly={inputsEditable}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </ModalDetalle>

      <ModalCitas1 isOpen={isOpenModalcita1} closeModal={CloseModalcita1}>
        <div className="TopCitasModal">
          <b>Nueva cita</b>{" "}
          <b
            style={{ cursor: "pointer" }}
            onClick={() => {
              CloseModalcita1(true);
              openAddCitasDisponibles(true);
            }}
          >
            x
          </b>
        </div>
        <div style={{ display: "flex", justifyContent: "right", margin: "1%" }}>
          <Button
            type="button"
            onClick={() => {
              CloseModalcita1(true);
              OpenAddContacto(true);
            }}
            style={{
              width: "150px",
              backgroundColor: "#148f9f",
              color: "white",
            }}
          >
            Nuevo contacto
          </Button>
        </div>
        <div className="ModalAddCita">
          <form action="" onSubmit={() => { }} style={{ padding: "0" }}>
            <div className="Buscar">
              <p>Buscar:</p>
              <input
                style={{ width: "80%" }}
                type="text"
                className="inputblue"
                placeholder="Escribe Nombre..."
                onChange={filterContacts}
              />
            </div>
            <br />
            <p>Contacto:</p>
            <Addcitas
              contactList={contactList}
              closeModal={CloseModalcita1}
              setIdContacto={setIdContacto}
              extras={[setDatosContacto, openAddCitasDisponibles]}
            />
          </form>
        </div>
      </ModalCitas1>

      {/* <ModalContactos
            isOpen={isOpenModalcita1}
            closeModal={CloseModalcita1}
            optionClose={false}
            titulo={
              // "Nueva cita"
              <div className="TopCitasModal">
                <b>Nueva cita</b> <b style={{ cursor: "pointer" }} onClick={() => { CloseModalcita1(true); openModalcitaCalendar(true); }}>x</b>
              </div>
              // <b>Nueva cita</b>
            }
            encabezado={
              <>

                <div style={{ display: "flex", justifyContent: "end", margin: "0 10px" }}>
                  <Button type="button" onClick={() => {
                    CloseModalcita1(true);
                    OpenAddContacto(true);
                  }}
                    style={{
                      width: "150px",
                      backgroundColor: "#148f9f",
                      color: "white"
                    }}>Nuevo contacto</Button>
                </div>
                <div className="Buscar">
                  <p>Buscar:</p>
                  <input style={{ width: "80%" }} type="text" className="inputblue" placeholder="Escribe Nombre..." onChange={filterContacts} />
                </div>
              </>
            }
            contenido={
              // <div className="ModalAddCitaDos" >
              //   <form action="" onSubmit={() => { }} style={{ padding: "0" }}>
              //     <p>Contacto:</p>
              //     <Addcitas contactList={contactList} closeModal={CloseModalcita1} setIdContacto={setIdContacto} extras={[setDatosContacto, openModalcitaCalendar]} />
              //   </form>
              // </div>
              <>
                <p>Contacto:</p>
                <Addcitas contactList={contactList} closeModal={CloseModalcita1} setIdContacto={setIdContacto} extras={[setDatosContacto, openModalcitaCalendar]} />
              </>
            }
          /> */}

      {/* Modal de filtro */}
      {/* <ModalFiltro
            isOpen={isOpenModalFiltro}
            closeModal={CloseModalFiltro}
            titulos={["Ordena tus citas", "Hoy", "Mañana", "Semana"]}
            OptionsSelect={[{ label: "Todos", value: "Todos" }, { label: "Confirmada", value: "Confirmada" }, { label: "Cancelada", value: "Cancelada" }]}
            functions={[getCitas, getCitasFilter]}
            statesVariables={{
              estadoFiltro: setEstadoFiltro,
              fechaInicio: setFechaInicio,
              mensajeFechaInicio: setMensajeFechaInicio,
              fechaFin: setFechaFin,
              mensajeFechaFin: setMensajeFechaFin
            }}
            variables={
              {
                estadoFiltro: EstadoFiltro,
                fechaInicio: FechaInicio,
                mensajeFechaInicio: MensajeFechaInicio,
                fechaFin: FechaFin,
                mensajeFechaFin: MensajeFechaFin
              }
            }
          /> */}

      <ModalCitasCalendar
        isOpen={isOpenModalcitaCalendar}
        closeModal={CloseModalcitaCalendar}
        optionClose={false}
      >
        <div className="TopCitasModal">
          <b>Nueva cita</b>{" "}
          <b style={{ cursor: "pointer" }} onClick={CloseModalcitaCalendar}>
            x
          </b>
        </div>
        <div className="ModalAddCita">
          {loaderDetalle ? (
            <LoaderModals />
          ) : (
            <>
              <Header
                style={{
                  position: "sticky",
                  top: 0,
                  zIndex: 999,
                  width: "100%",
                  background: "white",
                  padding: "0 10px",
                }}
              >
                <div style={{ display: "flex", justifyContent: "end" }}>
                  <Button
                    type="button"
                    onClick={() => {
                      saveCita(); /* AgregarCita(); */
                    }}
                    style={{
                      width: "30%",
                      backgroundColor: "#148f9f",
                      color: "white",
                    }}
                  >
                    Crear
                  </Button>
                </div>
              </Header>
              <Content
                className="site-layout"
                style={{
                  padding: "0 10px",
                  // height: "calc(70vh - 52px)",
                  width: "100%",
                  overflow: "initial",
                  // background: "#eaeef0"
                }}
              >
                {/* <form style={{ padding: "0" }}> */}
                <CalendarPage detailCita={detailCita} CloseModalcitaCalendar={CloseModalcitaCalendar} />

                <Row style={{ justifyContent: "space-between", gap: "1rem" }}>
                  {AddTipocita === "3" ? (
                    <Campos
                      label="Seleciona un rango de hora"
                      textError="Debe seleccionar un rango de hora"
                      valueError={ComboAddHorarioCita}
                    >
                      <TimePicker.RangePicker
                        inputReadOnly
                        placeholder={["Hora inicio", "Hora fin"]}
                        style={{ width: "100%" }}
                        value={[
                          !!HorarioInicio ? dayjs(HorarioInicio, "h:mm A") : "",
                          !!HorarioFin ? dayjs(HorarioFin, "h:mm A") : "",
                        ]}
                        use12Hours={true}
                        format="h:mm A"
                        onChange={(e) => {
                          // console.log(e);
                          if (!!e) {
                            setHorarioInicio(e[0].format("h:mm A"));
                            setHorarioFin(e[1].format("h:mm A"));
                            setComboAddHorarioCita("true");
                          } else {
                            setHorarioInicio("");
                            setHorarioFin("");
                            setComboAddHorarioCita("false");
                          }
                        }}
                        status={ComboAddHorarioCita === "false" && "error"}
                        locale={locale}
                      />
                    </Campos>
                  ) : (
                    <SelectAntd
                      label="Horario:"
                      placeholder={
                        horarios.length === 0
                          ? "Sin horarios"
                          : "Elige un horario"
                      }
                      options={horarios}
                      setStateValue={setAddHorario}
                      setStateError={setComboAddHorarioCita}
                      textError="Debe seleccionar un horario"
                      value={AddHorario}
                      valueError={ComboAddHorarioCita}
                      style={{ width: "100%", fontWeight: "normal" }}
                      styleDiv={{ width: "45%" }}
                      disabled={horarios.length === 0 && true}
                    />
                  )}
                  <Campos
                    label="Tipo cita:"
                    valueError={ComboAddTipoCita}
                    textError="Debe seleccionar un tipo"
                    styleDiv={{ width: "45%" }}
                  >
                    <Select
                      value={AddTipocita}
                      status={ComboAddTipoCita === "false" && "error"}
                      style={{ width: "100%", fontWeight: "normal" }}
                      placeholder="Elige un tipo de cita"
                      options={[
                        { value: "0", label: "Urgencia" },
                        { value: "1", label: "Primera Vez" },
                        { value: "2", label: "Seguimiento" },
                        // { value: "3", label: "Procedimiento" },
                      ]}
                      onSelect={(e) => {
                        if (e === "3") {
                          setHorario(null);
                          setMensajeAddNombrecontacto("true");
                          setMensajeAddApellidoPcontacto("true");
                          setMensajeAddApellidoMcontacto("true");
                          setMensajeAddCelularcontacto("true");
                          setMensajeAddCorreocontacto("true");
                          setMensajeContacto("true");
                        }
                        setAddTipocita(e);
                        setComboAddTipoCita("true");
                      }}
                    />
                  </Campos>
                </Row>

                {/* <Campos
                    label="Tipo de contacto:"
                    textError={"Debe seleccionar un tipo de contacto"}
                    valueError={MensajeAddTipoContacto}
                  >
                    <Select
                      value={AddTipoContacto}
                      placeholder="Elige un tipo de contacto"
                      options={[{ label: "Contacto existente", value: "0" }, { label: "Contacto nuevo", value: "1" }]}
                      style={{ width: "100%", fontWeight: "normal" }}
                      onSelect={(e) => {
                        if (AddTipoContacto === "0") {
                          setMensajeAddNombrecontacto("true");
                          setMensajeAddApellidoPcontacto("true");
                          setMensajeAddApellidoMcontacto("true");
                          setMensajeAddCelularcontacto("true");
                          setMensajeAddCorreocontacto("true");
                        } else {
                          setMensajeContacto("true");
                        }
                        setAddTipoContacto(e);
                        setMensajeAddTipoContacto("true");
                      }}
                      status={MensajeAddTipoContacto === "false" && "error"}
                    />
                  </Campos> */}
                <SelectAntd
                  label="Contacto:"
                  value={
                    !!DatosContacto.id
                      ? {
                        value: "",
                        label: (
                          <div style={{ display: "flex", gap: "1" }}>
                            <img
                              className="ImgMedicoSelect"
                              src={
                                !!DatosContacto.imagen
                                  ? `https://${DatosContacto.imagen}`
                                  : ""
                              }
                            />{" "}
                            <div style={{ marginLeft: "1%" }}>
                              {DatosContacto.nombre}
                            </div>
                          </div>
                        ),
                      }
                      : null
                  }
                  style={{
                    width: "100%",
                    margin: "1% 0",
                  }}
                  placeholder="Selecciona un contacto"
                  onClick={() => {
                    OpenModalcita1(true);
                    CloseModalcitaCalendar(true);
                  }}
                  setStateError={setMensajeContacto}
                  valueError={MensajeContacto}
                  textError="Debe seleccionar un contacto"
                  readOnly={true}
                  open={false}
                  vacio={false}
                />
                {/* {!!AddTipoContacto && (
                    <>
                      {AddTipoContacto === "1" ? (
                        <>
                          <InputAntd
                            className="inputblue"
                            value={AddNombrecontacto}
                            setStateValue={setAddNombrecontacto}
                            label="Nombre:"
                            style={{ margin: "2% 0" }}
                            placeholder="Nombre"
                            setStateError={setMensajeAddNombrecontacto}
                            textError={["Debe escribir un nombre", "Solo se permiten letras (max. 20 caracteres)"]}
                            type="text"
                            valueError={MensajeAddNombrecontacto}
                            vacio={false}
                            disabled={false}
                            expresionRegular={expresiones.Letras}
                          />
                          <InputAntd
                            className="inputblue"
                            value={AddApellidoPcontacto}
                            setStateValue={setAddApellidoPcontacto}
                            label="Apellido Paterno:"
                            style={{ margin: "2% 0" }}
                            placeholder="Apellido Paterno:"
                            setStateError={setMensajeAddApellidoPcontacto}
                            textError={["Debe escribir un apellido paterno", "Solo se permiten letras (max. 20 caracteres)"]}
                            type="text"
                            valueError={MensajeAddApellidoPcontacto}
                            vacio={false}
                            disabled={false}
                            expresionRegular={expresiones.Letras}
                          />

                          <InputAntd
                            className="inputblue"
                            value={AddApellidoMcontacto}
                            setStateValue={setAddApellidoMcontacto}
                            label="Apellido Materno:"
                            style={{ margin: "2% 0" }}
                            placeholder="Apellido Materno"
                            setStateError={setMensajeAddApellidoMcontacto}
                            textError={["", "Solo se permiten letras (max. 20 caracteres)"]}
                            type="text"
                            valueError={MensajeAddApellidoMcontacto}
                            vacio={true}
                            disabled={false}
                            expresionRegular={expresiones.Letras}
                          />

                          <InputAntd
                            className="inputblue"
                            value={AddCelularcontacto}
                            setStateValue={setAddCelularcontacto}
                            label="Celular"
                            id="celular"
                            style={{ margin: "2% 0" }}
                            placeholder="Celular:"
                            setStateError={setMensajeAddCelularcontacto}
                            textError={["", "Celular solo puede contener numeros", "Celular debe contener 10 dígitos"]}
                            type="text"
                            valueError={MensajeAddCelularcontacto}
                            vacio={true}
                            disabled={false}
                            expresionRegular={expresiones.Numeros}
                            maxLength={10}
                          />
                          
                          <InputAntd
                            className="inputblue"
                            value={AddCorreocontacto}
                            setStateValue={setAddCorreocontacto}
                            label="Correo"
                            style={{ margin: "2% 0" }}
                            placeholder="Correo:"
                            setStateError={setMensajeAddCorreocontacto}
                            textError={["", "Formato incorrecto"]}
                            type="text"
                            valueError={MensajeAddCorreocontacto}
                            vacio={true}
                            disabled={false}
                            expresionRegular={expresiones.Correo}
                          />
                        </>
                      ) : (
                        <SelectAntd
                          label="Contacto:"
                          value={!!DatosContacto.id ? { value: "", label: <div style={{ display: "flex", gap: "1" }}><img className="ImgMedicoSelect" src={!!DatosContacto.imagen ? `https://${DatosContacto.imagen}` : ""} /> <div style={{ marginLeft: "1%" }}>{DatosContacto.nombre}</div></div> } : null}
                          style={{
                            width: "100%",
                            margin: "1% 0"
                          }}
                          placeholder="Selecciona un contacto"
                          onClick={() => {
                            OpenModalcita1(true);
                            CloseModalcitaCalendar(true);
                          }}
                          setStateError={setMensajeContacto}
                          valueError={MensajeContacto}
                          textError="Debe seleccionar un contacto"
                          readOnly={true}
                          open={false}
                          vacio={false}
                        />
                      )}
                    </>
                  )} */}

                <TextAreaAntd
                  label="Motivo de cita:"
                  value={AddMotivo}
                  placeholder="Escriba un motivo de cita (opcional)"
                  style={{
                    marginBottom: ".5%",
                    fontFamily: "sans-serif",
                  }}
                  columns="30"
                  autoSize={true}
                  setStateValue={setAddMotivo}
                />

                <TextAreaAntd
                  label="Comentarios o notas:"
                  value={AddComentario}
                  placeholder="Escriba un comentario o nota (opcional)"
                  style={{
                    marginBottom: ".5%",
                    fontFamily: "sans-serif",
                  }}
                  columns="30"
                  autoSize={true}
                  setStateValue={setAddComentario}
                />

                {/* <div style={{ display: "flex", justifyContent: "center" }}>
                    <Button type="button" onClick={() => { saveCita();}}
                      style={{
                        width: "30%",
                        backgroundColor: "#148f9f",
                        color: "white"
                      }}>Crear</Button>
                  </div> */}
                {/* </form> */}
              </Content>
            </>
          )}
        </div>
      </ModalCitasCalendar>

      <ModalAddNota
        isOpen={isOpenModalAddNota}
        closeModal={CloseModalAddNota}
        styleDet={styleNota}
      >
        <div className="topCloseModalNotas1">
          <b>Datos de consulta</b>{" "}
          <b
            style={{ cursor: "pointer" }}
            onClick={() => {
              CloseModalAddNota(true);
              setMotivoConsulta("");
              setTipoConsulta(null);
              setDatosContacto({ id: "", nombre: "", imagen: "" });
            }}
          >
            X
          </b>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            background: "#eaeef0",
          }}
        >
          <img
            className="Img-contacto"
            id="Img-cont"
            src={
              !!DatosContacto.imagen ? "https://" + DatosContacto.imagen : Imag
            }
            alt=""
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            background: "#eaeef0",
          }}
        >
          <h4>{DatosContacto.nombre}</h4>
        </div>
        <div className="ModalNotasMed2" style={{ background: "#eaeef0" }}>
          <SelectAntd
            value={TipoConsulta}
            style={{ width: "100%", margin: "2% 0" }}
            placeholder="selecciona un tipo"
            options={[
              { value: "1", label: "Primera vez" },
              { value: "2", label: "Seguimiento" },
            ]}
            setStateValue={setTipoConsulta}
            setStateError={setMensajeTipoConsulta}
            valueError={MensajeTipoConsulta}
            textError="Debe elegir un tipo"
          />
          <TextAreaAntd
            value={MotivoConsulta}
            placeholder="Motivo de consulta"
            style={{
              margin: "2% 0",
              fontFamily: "sans-serif",
            }}
            columns="30"
            autoSize={true}
            setStateValue={setMotivoConsulta}
            setStateError={setMensajeMotivoConsulta}
            valueError={MensajeMotivoConsulta}
            textError={["Debe escribir un motivo de consulta"]}
            vacio={false}
          />
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              style={{
                background: "#148F9F",
                border: "#148F9F",
                color: "white",
              }}
              onClick={() => {
                if (!!TipoConsulta && !!MotivoConsulta) {
                  // setopcionModal(1);
                  CloseModalAddNota(true);
                  navigate("/app/NuevaConsulta", {
                    //  replace: false,
                    state: {
                      id: DatosContacto.id,
                      imagen: DatosContacto.imagen,
                      nombre: DatosContacto.nombre,
                      motivo: MotivoConsulta,
                      tipoConsulta: TipoConsulta,
                      idCita: idCita,
                    },
                  });
                } else {
                  if (!!!TipoConsulta) {
                    setMensajeTipoConsulta("false");
                  }

                  if (!!!MotivoConsulta) {
                    setMensajeMotivoConsulta("false");
                  }
                  setOpen(true);
                  setTypeMessage("warning");
                  setMessage("Faltan campos por completar");
                }
              }}
            >
              Continuar
            </Button>
          </div>
        </div>
      </ModalAddNota>

      {ModalEliminarCita}

      <ModalEditContacto
        isOpen={isOpenEditContacto}
        closeModal={CloseEditContacto}
        styleDet={styleDet}
      >
        <>
          {loaderDetalle ? (
            <LoaderModals />
          ) : (
            <div>
              <div
                style={{
                  width: "100%",
                  background: "#148f9f",
                  color: "#fff",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: "1% 2%",
                }}
              >
                <b
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    CloseEditContacto(true);
                  }}
                >
                  x
                </b>
                <b>Detalle de contacto</b>

                <div></div>
              </div>
              <div className="ModalConsultasStyle">
                {/* <div className="encabezadodetallecontacto"> */}
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Row>
                    <div>
                      <label htmlFor="imagenContacto1">
                        <img
                          className="imagencont"
                          src={
                            !!ImagenContacto.url
                              ? ImagenContacto.url
                              : Iconos[19]
                          }
                        />
                      </label>
                      {infocontacto.YgiaUser === "Sin Ygia" && (
                        <input
                          type="file"
                          id="imagenEditContacto"
                          accept="/image/x-PNG,image/jpeg"
                          style={{ display: "none" }}
                        />
                      )}
                    </div>
                  </Row>
                </div>
                {/* </div> */}
                <form>
                  <div>
                    {Tipocontacto === "4" ? (
                      <InputAntd
                        className="inputblue"
                        value="Asistente"
                        label="Tipo de contacto:"
                        style={{ margin: "1% 0" }}
                        placeholder="Tipo de contacto"
                        type="text"
                        vacio={true}
                        readOnly={true}
                      />
                    ) : (
                      <SelectAntd
                        label="Tipo de contacto:"
                        value={Tipocontacto}
                        style={{ width: "100%", margin: "1% 0" }}
                        placeholder="Tipo de contacto"
                        options={[
                          { value: "1", label: "Médico" },
                          { value: "3", label: "Paciente" },
                        ]}
                        setStateValue={setTipocontacto}
                        disabled={true}
                      />
                    )}
                    <InputAntd
                      className="inputblue"
                      value={Nombre}
                      setStateValue={setNombre}
                      label="Nombre:"
                      style={{ margin: "1% 0" }}
                      placeholder="Escribe tu nombre"
                      setStateError={setMensajeNombre}
                      textError={[
                        "Debe escribir un nombre",
                        "Solo se permiten letras (max. 20 caracteres)",
                      ]}
                      type="text"
                      valueError={MensajeNombre}
                      vacio={false}
                      expresionRegular={expresiones.Letras}
                      maxLength={40}
                    />

                    <InputAntd
                      className="inputblue"
                      value={ApellidoP}
                      setStateValue={setApellidoP}
                      label="Apellido paterno:"
                      style={{ margin: "1% 0" }}
                      placeholder="Escribe tu primer apellido"
                      setStateError={setMensajeApellidoP}
                      textError={[
                        "Debe escribir un apellido",
                        "Solo se permiten letras (max. 20 caracteres)",
                      ]}
                      type="text"
                      valueError={MensajeApellidoP}
                      vacio={false}
                      expresionRegular={expresiones.Letras}
                      maxLength={20}
                    />

                    <InputAntd
                      className="inputblue"
                      value={ApellidoM}
                      setStateValue={setApellidoM}
                      label="Apellido materno:"
                      style={{ margin: "1% 0" }}
                      placeholder="Escribe tu segundo apellido"
                      setStateError={setMensajeApellidoM}
                      textError={[
                        "",
                        "Solo se permiten letras (max. 20 caracteres)",
                      ]}
                      type="text"
                      valueError={MensajeApellidoM}
                      vacio={true}
                      expresionRegular={expresiones.Letras}
                      maxLength={20}
                    />

                    <InputAntd
                      className="inputblue"
                      value={Celular}
                      setStateValue={setCelular}
                      label="Celular:"
                      id="celular"
                      style={{ margin: "1% 0" }}
                      placeholder="Escribe tu celular"
                      setStateError={setMensajeCelular}
                      textError={[
                        "",
                        "Celular solo puede contener numeros",
                        "Celular debe contener 10 dígitos",
                      ]}
                      type="text"
                      valueError={MensajeCelular}
                      vacio={true}
                      expresionRegular={expresiones.Numeros}
                      maxLength={10}
                    />

                    <InputAntd
                      className="inputblue"
                      value={Correo}
                      setStateValue={setCorreo}
                      label="Correo:"
                      style={{ margin: "1% 0" }}
                      placeholder="Escribe tu correo eléctronico"
                      setStateError={setMensajeCorreo}
                      textError={["", "Formato incorrecto"]}
                      type="text"
                      valueError={MensajeCorreo}
                      vacio={true}
                      expresionRegular={expresiones.Correo}
                    />
                    <p style={{ textAlign: "center" }}>
                      {"Usuario " + infocontacto.YgiaUser}
                    </p>
                  </div>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Button
                      style={{ background: "#148f9f", color: "white" }}
                      onClick={() => {
                        editContact();
                      }}
                    >
                      Guardar
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          )}
        </>
      </ModalEditContacto>

      <ModalAddContacto
        isOpen={IsOpenAddContacto}
        closeModal={ClosedAddContacto}
        optionClose={false}
        styleDet={styleDet}
      >
        <div
          style={{
            width: "100%",
            background: "#148f9f",
            color: "#fff",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "1% 2%",
          }}
        >
          <b>Nuevo contacto</b>
          <b
            style={{ cursor: "pointer" }}
            onClick={() => {
              ClosedAddContacto(true);
              OpenModalcita1(true);
              cleanInputsContacto();
            }}
          >
            x
          </b>
        </div>
        <div className="scrolMotivoConsulta">
          {loaderDetalle ? (
            <LoaderModals />
          ) : (
            <div style={{ padding: "2%", width: "100%" }}>
              <div style={{ textAlign: "center", marginBottom: "1%" }}>
                <label htmlFor="imgPerfilConsulta">
                  <img
                    style={{
                      width: " 45px",
                      height: "45px",
                      overflow: "hidden",
                      borderRadius: "5rem",
                      objectFit: "cover",
                      objectPosition: "center",
                      border: "2px solid #d7d7d7",
                    }}
                    src={!!ProfileImage.url ? ProfileImage.url : Iconos[0]}
                    alt=""
                  />
                  <input
                    id="imgPerfilConsulta"
                    style={{ display: "none" }}
                    type="file"
                    accept="/image/x-PNG,image/jpeg"
                    onChange={(e) => {
                      const file = e.target.files[0];
                      if (file && file.type.substring(0, 5) === "image") {
                        setProfileImage({
                          name: e.target.value,
                          url: URL.createObjectURL(e.target.files[0]),
                          file: e.target.files[0],
                        });
                      }
                    }}
                  />
                </label>
              </div>
              <br />
              <InputAntd
                className="inputblue"
                value={AddNombrecontacto}
                setStateValue={setAddNombrecontacto}
                label="Nombre:"
                style={{ margin: "2% 0" }}
                placeholder="Nombre"
                setStateError={setMensajeAddNombrecontacto}
                textError={[
                  "Debe escribir un nombre",
                  "Solo se permiten letras",
                ]}
                type="text"
                valueError={MensajeAddNombrecontacto}
                vacio={false}
                disabled={false}
                expresionRegular={expresiones.Letras}
                maxLength={40}
              />

              <InputAntd
                className="inputblue"
                value={AddApellidoPcontacto}
                setStateValue={setAddApellidoPcontacto}
                label="Apellido Paterno:"
                style={{ margin: "2% 0" }}
                placeholder="Apellido Paterno:"
                setStateError={setMensajeAddApellidoPcontacto}
                textError={[
                  "Debe escribir un apellido paterno",
                  "Solo se permiten letras",
                ]}
                type="text"
                valueError={MensajeAddApellidoPcontacto}
                vacio={false}
                disabled={false}
                expresionRegular={expresiones.Letras}
                maxLength={25}
              />
              <InputAntd
                className="inputblue"
                value={AddApellidoMcontacto}
                setStateValue={setAddApellidoMcontacto}
                label="Apellido Materno:"
                style={{ margin: "2% 0" }}
                placeholder="Apellido Materno"
                setStateError={setMensajeAddApellidoMcontacto}
                textError={["", "Solo se permiten letras"]}
                type="text"
                valueError={MensajeAddApellidoMcontacto}
                vacio={true}
                disabled={false}
                expresionRegular={expresiones.Letras}
                maxLength={25}
              />
              <InputAntd
                className="inputblue"
                value={AddCelularcontacto}
                setStateValue={setAddCelularcontacto}
                label="Celular"
                id="celular"
                style={{ margin: "2% 0" }}
                placeholder="Celular:"
                setStateError={setMensajeAddCelularcontacto}
                textError={[
                  "",
                  "Celular solo puede contener numeros",
                  "Celular debe contener 10 dígitos",
                ]}
                type="text"
                valueError={MensajeAddCelularcontacto}
                vacio={true}
                disabled={false}
                expresionRegular={expresiones.Numeros}
                maxLength={10}
              />

              <InputAntd
                className="inputblue"
                value={AddCorreocontacto}
                setStateValue={setAddCorreocontacto}
                label="Correo"
                style={{ margin: "2% 0" }}
                placeholder="Correo:"
                setStateError={setMensajeAddCorreocontacto}
                textError={["", "Formato incorrecto"]}
                type="text"
                valueError={MensajeAddCorreocontacto}
                vacio={true}
                disabled={false}
                expresionRegular={expresiones.Correo}
              />
              <div>
                <Button
                  style={{
                    width: "100%",
                    background: "#148f9f",
                    borderColor: "#148f9f",
                    color: "white",
                  }}
                  size="sm"
                  onClick={(e) => {
                    // saveContact(e);
                    saveContactDos();
                  }}
                >
                  Crear Paciente
                </Button>
              </div>
            </div>
          )}
        </div>
      </ModalAddContacto>

      <ModalFicha
        isOpen={isOpenFichaContacto}
        closeModal={CloseFichaContacto}
        optionClose={false}
        styleDet={styleFichaContacto}
      >
        <div
          style={{
            width: "100%",
            background: "#148f9f",
            color: "#fff",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "1% 2%",
          }}
        >
          <b>Ficha de información</b>
          <b
            style={{ cursor: "pointer" }}
            onClick={() => {
              CloseFichaContacto(true);
              OpenAlertConfirm(true);
            }}
          >
            x
          </b>
        </div>
        <div className="ModalFichaContacto">
          {loaderDetalle ? (
            <LoaderModals />
          ) : (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  padding: "1% 2%",
                  width: "100%",
                }}
              >
                <Button
                  type="button"
                  style={{
                    background: "#148f9f",
                    border: "0",
                    color: "white",
                    display: "flex",
                    alignItems: "center",
                    gap: ".5rem",
                  }}
                  onClick={() => {
                    addFichaContacto();
                  }}
                >
                  Guardar
                </Button>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "3%",
                }}
              >
                <img
                  style={{
                    width: "65px",
                    height: "65px",
                    borderRadius: "30px",
                  }}
                  src={
                    !!DatosContacto.imagen
                      ? `https://${DatosContacto.imagen}`
                      : Iconos[0]
                  }
                  alt=""
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "1%",
                }}
              >
                <b>
                  {!!DatosContacto.nombre ? DatosContacto.nombre : "Usuario"}
                </b>
              </div>

              <div className="scrolDetalleConsults">
                {/* INFORMACION GENERAL */}
                <Accordionn>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>
                      {MensajeSexo === "false" &&
                        MensajeNacimiento === "false" && (
                          <>
                            <CancelRounded color="error" />
                            &nbsp;
                          </>
                        )}
                      Información general
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <InputAntd
                      label="Tipo de sangre:"
                      className="inputblue"
                      value={TipoSangre}
                      setStateValue={setTipoSangre}
                      placeholder="Tipo de sangre"
                      vacio={true}
                      style={{ width: "30%" }}
                      maxLength={15}
                      disabled={
                        DataFichaId.historial.tipoSangre !== undefined
                          ? !!DataFichaId.historial.tipoSangre
                            ? true
                            : false
                          : false
                      }
                    />
                    <TextAreaAntd
                      label="Alergias:"
                      value={Alergias}
                      placeholder="Alergias"
                      style={{
                        // margin: ".5% 0",
                        marginBottom: ".5%",
                        fontFamily: "sans-serif",
                      }}
                      columns="30"
                      autoSize={true}
                      setStateValue={setAlergias}
                      disabled={
                        DataFichaId.historial.alergias !== undefined
                          ? !!DataFichaId.historial.alergias
                            ? true
                            : false
                          : false
                      }
                    />

                    <TextAreaAntd
                      label="Quien Refiere:"
                      value={Refiere}
                      placeholder="Quien Refiere"
                      style={{
                        // margin: ".5% 0",
                        marginBottom: ".5%",
                        fontFamily: "sans-serif",
                      }}
                      columns="30"
                      autoSize={true}
                      setStateValue={setRefiere}
                      disabled={
                        DataFichaId.historial.refiere !== undefined
                          ? !!DataFichaId.historial.refiere
                            ? true
                            : false
                          : false
                      }
                    />

                    {/* <TextAreaAntd
                      label="Observaciones sobre el paciente:"
                      value={ObservacionesPaciente}
                      placeholder="Observaciones sobre el paciente"
                      style={{
                        // margin: ".5% 0",
                        marginBottom: ".5%",
                        fontFamily: "sans-serif",
                      }}
                      columns="30"
                      autoSize={true}
                      setStateValue={setObservacionesPaciente}
                      disabled={DataFichaId.historial.observaciones !== undefined ? !!DataFichaId.historial.observaciones ?  true : false : false}
                    /> */}

                    <Row className="rowsToCol">
                      <SelectAntd
                        label="Estado civil:"
                        value={EstadoCivil}
                        style={{ width: "100%" }}
                        placeholder="Seleccione un estado civil"
                        options={[
                          { value: "0", label: "Soltero/a" },
                          { value: "1", label: "Casado/a" },
                          { value: "2", label: "Viudo/a" },
                          { value: "3", label: "Divorciado/a" },
                          { value: "4", label: "Separado/a" },
                          { value: "5", label: "Concubinato" },
                        ]}
                        setStateValue={setEstadoCivil}
                      // styleDiv={{ width: "45%" }}
                      // setStateError={setMensajeSexo}
                      // valueError={MensajeSexo}
                      // textError={"Debe seleccionar un estado civil"}
                      />
                      <InputAntd
                        className="inputblue"
                        value={Ocupacion}
                        setStateValue={setOcupacion}
                        label="Ocupación:"
                        placeholder="Escribe una ocupación"
                        // valueError={MensajeAddNombrecontacto}
                        vacio={true}
                      // disabled={false}
                      // expresionRegular={expresiones.Letras}
                      // styleDiv={{ width: "45%" }}
                      />
                    </Row>

                    {/* <Row style={{ alignItems: "center", justifyContent: "space-between" }}> */}
                    <Row className="rowsToCol">
                      <SelectAntd
                        label="Género:"
                        value={Sexo}
                        style={{ width: "100%" }}
                        placeholder="Género"
                        options={[
                          { value: "Femenino", label: "Femenino" },
                          { value: "Masculino", label: "Masculino" },
                        ]}
                        setStateValue={setSexo}
                        // styleDiv={{ width: "45%" }}
                        setStateError={setMensajeSexo}
                        valueError={MensajeSexo}
                        textError={"Debe seleccionar un género"}
                        disabled={
                          DataFichaId.ficha.sexo !== undefined
                            ? !!DataFichaId.ficha.sexo
                              ? true
                              : false
                            : false
                        }
                      />
                      <DatePickerAntd
                        label="Fecha de nacimiento:"
                        placeholder="DD/MM/AAAA"
                        style={{ width: "100%", background: "white" }}
                        value={!!Nacimiento ? dayjs(Nacimiento) : ""}
                        setStateValue={setNacimiento}
                        // styleDiv={{ width: "45%" }}
                        setEdad={setEdad}
                        setStateError={setMensajeNacimiento}
                        valueError={MensajeNacimiento}
                        textError={["Debe elegir una fecha de nacimiento"]}
                        disabled={
                          DataFichaId.ficha.fechaNacimiento !== undefined
                            ? !!DataFichaId.ficha.fechaNacimiento
                              ? true
                              : false
                            : false
                        }
                      />
                    </Row>
                    <InputAntd
                      className="inputblue"
                      value={Nacionalidad}
                      setStateValue={setNacionalidad}
                      label="Nacionalidad:"
                      placeholder="Nacionalidad"
                      // valueError={MensajeAddNombrecontacto}
                      vacio={true}
                      // disabled={false}
                      // expresionRegular={expresiones.Letras}
                      disabled={
                        DataFichaId.ficha.nacionalidad !== undefined
                          ? !!DataFichaId.ficha.nacionalidad
                            ? true
                            : false
                          : false
                      }
                    />

                    <Row className="rowsToCol">
                      <Campos
                        label="Estado:"
                        children={
                          <Select
                            showSearch
                            style={{
                              width: "100%",
                              margin: ".5% 0",
                            }}
                            placeholder="Estado"
                            optionFilterProp="children"
                            filterOption={filterOptions}
                            filterSort={filterSort}
                            value={Estado}
                            onSelect={(e) => {
                              setEstado(e);
                              getLocalidades(e);
                            }}
                            options={EstadosList}
                            disabled={
                              DataFichaId.ficha.estado !== undefined
                                ? !!DataFichaId.ficha.estado
                                  ? true
                                  : false
                                : false
                            }
                          />
                        }
                      />

                      <Campos
                        label="Localidad:"
                        children={
                          <Select
                            showSearch
                            style={{
                              width: "100%",
                              margin: ".5% 0",
                            }}
                            placeholder="Localidad"
                            optionFilterProp="children"
                            filterOption={filterOptions}
                            filterSort={filterSort}
                            value={Localidad}
                            onSelect={(e) => {
                              setLocalidad(e);
                            }}
                            options={LocalidadesList}
                            disabled={
                              LocalidadesList.length === 0
                                ? true
                                : DataFichaId.ficha.estado !== undefined
                                  ? !!DataFichaId.ficha.estado
                                    ? true
                                    : false
                                  : false
                            }
                          />
                        }
                      />
                    </Row>
                  </AccordionDetails>
                </Accordionn>
                {/* DATOS DE PAREJA */}
                {!!!DataFichaId.historial ? (
                  <Accordionn>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography>Datos de pareja</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <InputAntd
                        label="Nombre:"
                        className="inputblue"
                        value={NombrePareja}
                        setStateValue={setNombrePareja}
                        placeholder="Escriba un nombre completo"
                        vacio={true}
                        styleDiv={{ width: "100%" }}
                      // maxLength={15}
                      />
                      <Row className="rowsToCol">
                        <InputAntd
                          className="inputblue"
                          value={OcupacionPareja}
                          setStateValue={setOcupacionPareja}
                          label="Ocupación:"
                          placeholder="Escribe una ocupación"
                          // valueError={MensajeAddNombrecontacto}
                          vacio={true}
                        // disabled={false}
                        // expresionRegular={expresiones.Letras}
                        // styleDiv={{ width: "70%" }}
                        />

                        <InputAntd
                          label="Edad:"
                          className="inputblue"
                          value={EdadPareja}
                          setStateValue={setEdadPareja}
                          placeholder="Edad"
                          vacio={true}
                          // styleDiv={{ width: "15%" }}
                          maxLength={2}
                        />
                      </Row>
                      <TextAreaAntd
                        label="Notas/Comentarios:"
                        value={NotasPareja}
                        placeholder="Escriba una nota o comentario"
                        style={{
                          // margin: ".5% 0",
                          marginBottom: ".5%",
                          fontFamily: "sans-serif",
                        }}
                        columns="30"
                        autoSize={true}
                        setStateValue={setNotasPareja}
                      />
                    </AccordionDetails>
                  </Accordionn>
                ) : (
                  !!!DataFichaId.historial.edadPareja &&
                  !!!DataFichaId.historial.ocupacionPareja &&
                  !!!DataFichaId.historial.nota &&
                  !!!DataFichaId.historial.nombrePareja && (
                    <Accordionn>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography>Datos de pareja</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <InputAntd
                          label="Nombre:"
                          className="inputblue"
                          value={NombrePareja}
                          setStateValue={setNombrePareja}
                          placeholder="Escriba un nombre completo"
                          vacio={true}
                          styleDiv={{ width: "100%" }}
                        // maxLength={15}
                        />
                        <Row className="rowsToCol">
                          <InputAntd
                            className="inputblue"
                            value={OcupacionPareja}
                            setStateValue={setOcupacionPareja}
                            label="Ocupación:"
                            placeholder="Escribe una ocupación"
                            // valueError={MensajeAddNombrecontacto}
                            vacio={true}
                          // disabled={false}
                          // expresionRegular={expresiones.Letras}
                          // styleDiv={{ width: "70%" }}
                          />

                          <InputAntd
                            label="Edad:"
                            className="inputblue"
                            value={EdadPareja}
                            setStateValue={setEdadPareja}
                            placeholder="Edad"
                            vacio={true}
                            // styleDiv={{ width: "15%" }}
                            maxLength={2}
                          />
                        </Row>
                        <TextAreaAntd
                          label="Notas/Comentarios:"
                          value={NotasPareja}
                          placeholder="Escriba una nota o comentario"
                          style={{
                            // margin: ".5% 0",
                            marginBottom: ".5%",
                            fontFamily: "sans-serif",
                          }}
                          columns="30"
                          autoSize={true}
                          setStateValue={setNotasPareja}
                        />
                      </AccordionDetails>
                    </Accordionn>
                  )
                )}

                {/* EXPLORACION FISICA */}
                <Accordionn>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography> Exploración física</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {/* <p style={{fontWeight:"500"}}>Signos vitales</p> */}
                    <div className="exploFisicaDetalle">
                      <InputAntd
                        label="Altura:"
                        style={{ width: "100%" }}
                        styleLabel={{ fontSize: "14px" }}
                        type=""
                        id="altura"
                        placeholder="Altura (Mtrs.)"
                        value={Altura}
                        setStateValue={setAltura}
                        vacio={true}
                        disabled={false}
                        extras={["IMC", setIMC, Peso]}
                      />

                      <InputAntd
                        label="Peso:"
                        style={{ width: "100%" }}
                        styleLabel={{ fontSize: "14px" }}
                        type=""
                        id="peso"
                        placeholder="Peso (Kg.)"
                        value={Peso}
                        setStateValue={setPeso}
                        vacio={true}
                        disabled={false}
                        extras={["IMC", setIMC, Altura]}
                      />

                      <InputAntd
                        label="IMC:"
                        style={{ width: "100%" }}
                        styleLabel={{ fontSize: "14px" }}
                        type=""
                        placeholder="IMC"
                        value={IMC}
                        setStateValue={setIMC}
                        vacio={true}
                        // disabled={true}
                        readOnly={true}
                      />

                      <Campos
                        label="Tipo de peso"
                        children={
                          <Input
                            style={{ width: "100%" }}
                            type=""
                            placeholder="Tipo de peso"
                            value={
                              !!IMC
                                ? IMC < 18
                                  ? "Insuficiente"
                                  : IMC >= 18 && IMC <= 25
                                    ? "Peso normal"
                                    : IMC > 25 && IMC <= 30
                                      ? "Sobrepeso"
                                      : IMC > 30 && IMC <= 40
                                        ? "Obesidad"
                                        : IMC > 40 && "Obesidad mórbida"
                                : ""
                            }
                            readOnly={true}
                            status={
                              !!IMC
                                ? IMC < 18
                                  ? "warning"
                                  : IMC >= 18 && IMC <= 25
                                    ? ""
                                    : IMC > 25 && IMC <= 30
                                      ? "warning"
                                      : IMC > 30 && IMC <= 40
                                        ? "warning"
                                        : IMC > 40 && "error"
                                : ""
                            }
                          />
                        }
                      />
                    </div>
                    <div className="exploFisicaDetalle">
                      <InputAntd
                        label="T. Arterial"
                        style={{ width: "100%" }}
                        styleLabel={{ fontSize: "14px" }}
                        type=""
                        placeholder="T. Arterial"
                        value={TArterial}
                        setStateValue={setTArterial}
                        // label="Nombre"
                        vacio={true}
                        disabled={false}
                      />

                      <InputAntd
                        label="F. Cardiaca"
                        style={{ width: "100%" }}
                        styleLabel={{ fontSize: "14px" }}
                        type=""
                        placeholder="F. Cardiaca"
                        value={FCardiaca}
                        setStateValue={setFCardiaca}
                        vacio={true}
                        disabled={false}
                      />

                      <InputAntd
                        label="F. Respiratoria"
                        style={{ width: "100%" }}
                        styleLabel={{ fontSize: "14px" }}
                        type=""
                        placeholder="F. Respiratoria"
                        value={FRespiratoria}
                        setStateValue={setFRespiratoria}
                        vacio={true}
                        disabled={false}
                      />

                      <InputAntd
                        label="Temperatura"
                        style={{ width: "100%" }}
                        styleLabel={{ fontSize: "14px" }}
                        type=""
                        placeholder="Temperatura"
                        value={Temperatura}
                        setStateValue={setTemperatura}
                        vacio={true}
                        disabled={false}
                      />

                      <InputAntd
                        label="O2"
                        style={{ width: "100%" }}
                        type=""
                        placeholder="O2"
                        styleLabel={{ fontSize: "14px" }}
                        value={SOxigeno}
                        setStateValue={setSOxigeno}
                        vacio={true}
                        disabled={false}
                      />
                    </div>
                    {/* <TextAreaAntd
                          label="Exploración física "
                          placeholder="Exploración física"
                          style={{
                            margin: ".5% 0",
                            fontFamily: "sans-serif",
                          }}
                          name=""
                          id=""
                          cols="30"
                          autoSize
                          value={ExploracionFisica}
                          setStateValue={setExploracionFisica}
                        /> */}
                  </AccordionDetails>
                </Accordionn>
              </div>
            </>
          )}
        </div>
      </ModalFicha>

      <ModalAlertConfirm
        isOpen={IsOpenAlertConfirm}
        closeModal={ClosedAlertConfirm}
        optionClose={true}
        styleDet={styleConfirm}
      >
        <div
          style={{
            width: "100%",
            background: "#148f9f",
            color: "#fff",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "1% 2%",
          }}
        >
          <b>¡Advertencia!</b>
          <b style={{ cursor: "pointer" }} onClick={ClosedAlertConfirm}>
            x
          </b>
        </div>
        <div className="ModalListContactosConsult" style={{ padding: "2%" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "10px 0",
            }}
          >
            <b>¿Desea regresar a la vista anterior?</b>
          </div>
          <div style={{ display: "flex", gap: "1rem" }}>
            <Button
              style={{
                width: "100%",
                background: "#148f9f",
                borderColor: "#148f9f",
                color: "white",
              }}
              size="sm"
              onClick={() => {
                ClosedAlertConfirm(true);
                openFichaContacto(true);
              }}
            >
              Cancelar
            </Button>
            <Button
              style={{
                width: "100%",
                background: "#148f9f",
                borderColor: "#148f9f",
                color: "white",
              }}
              size="sm"
              onClick={() => {
                cleanInputsFicha();
                ClosedAlertConfirm(true);
                openModalDetalle(true);
              }}
            >
              Regresar
            </Button>
            <Button
              style={{
                width: "100%",
                background: "#148f9f",
                borderColor: "#148f9f",
                color: "white",
              }}
              size="sm"
              onClick={() => {
                if (!!Sexo && !!Nacimiento) {
                  addFichaContacto();
                } else {
                  // cambiarNotasValido(false);
                  ClosedAlertConfirm(true);
                  openFichaContacto(true);
                  if (!!!Sexo) {
                    setMensajeSexo("false");
                  }
                  if (!!!Nacimiento) {
                    setMensajeNacimiento("false");
                  }

                  setOpen(true);
                  setTypeMessage("warning");
                  setMessage("Faltan campos por completar");
                }
              }}
            >
              Regresar y guardar
            </Button>
          </div>
        </div>
      </ModalAlertConfirm>

      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={() => setOpen(false)}
      >
        <Alert
          onClose={() => setOpen(false)}
          severity={typeMessage}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>

      <Snackbar
        open={OpenSetting}
        autoHideDuration={6000}
        onClose={() => setOpenSetting(false)}
      >
        <Alert
          onClose={() => setOpenSetting(false)}
          severity={typeMessage}
          sx={{ width: "100%" }}
        >
          {message}
          <img style={{ width: "20px" }} src={Confi_white} alt="" />
        </Alert>
      </Snackbar>
    </Stack>
  );
}
export default CitasNuevo;

// BOTONES DEL DETALLEE
{
  /* <div style={{display:"flex"}}>
          <h1><img src={CancelarCita} style={{width:"20px",cursor:"pointer"}} alt="" />&nbsp;</h1>
          <h1><img src={Editarcita} style={{width:"20px",cursor:"pointer"}} alt="" />&nbsp;</h1>
          <h1><img src={EliminarCita} style={{width:"20px",cursor:"pointer"}} alt="" /></h1>
          </div> */
}
