import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import {
  getContacts_request,
  addContact_request,
} from "../services/contactos_services";
import {
  getConsultas_request,
  detailConsultas_request,
  getConsultasPeriod_request,
  addResumenMedico_request,
  previewResumenMedico_request,
  documentoResumenMedico_request,
} from "../services/consultas_services";

// importando Iconos
import Iconos from "../componentes/IconosDeSitio";
import CloseIcon from '@mui/icons-material/Close';
import Agregar from "../Img/Iconos/AgregarBlanco.svg";
import AddIcon from "@mui/icons-material/AddCircle";
import Icono from "../componentes/IconosDeSitio";
import { AddCircle, Cancel, CheckCircle, Edit, FilePresent, OndemandVideo } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';

import "./styles/Consultas.scss";

import ButtonAntd from "../componentes/GeneralComponents/ButtonAntd";

import TextArea from "antd/es/input/TextArea";
import InputAntd from "../componentes/GeneralComponents/InputAntd";
import SelectAntd from "../componentes/GeneralComponents/SelectAntd";
import TextAreaAntd from "../componentes/GeneralComponents/TextAreaAntd";
import { DatePicker, Input, Table, Button, Layout, Modal, Row, Avatar, Image, Checkbox } from "antd";
import { Label } from "../componentes/GeneralComponents/Formularios";
import Accordionn from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// Importando metodo de modales
import { UseModal } from "../hooks/UseModal";

// Impotaando componente Modal
import ModalDetalleConsulta from "../componentes/GeneralComponents/ModalDetalle";
import ModalMotivoConsulta from "../componentes/GeneralComponents/ModalDetalle";
import ModalTipoUsuario from "../componentes/GeneralComponents/ModalDetalle";
import ModalSelectContact from "../componentes/GeneralComponents/ModalDetalle";
import ModalAddContacto from "../componentes/GeneralComponents/ModalDetalle";
import ModalResumenMedico from "../componentes/GeneralComponents/ModalDetalle";
import AddLista from "../componentes/GeneralComponents/ModListDeContactos";

// Acordion libreria mui

import dayjs from "dayjs";
import "dayjs/locale/es";
import locale from "antd/es/date-picker/locale/es_ES";

import { Loader } from "../componentes/GeneralComponents/Loader";
import LoaderModals from "../componentes/GeneralComponents/Loader_modals";
import ConsultasCard from "../componentes/Consultas/consultasCard";
import NavbarNuevo from "../componentes/GeneralComponents/Navbar_Nuevo";

import Stack from "@mui/material/Stack";
import SnackBar from "../componentes/GeneralComponents/Snackbar";
import { Empty } from "antd";
import { sendRecetas_request } from "../services/recetas_services";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import ReactGA from "react-ga4";

const { Header, Content, Footer } = Layout;
function Consultas() {
  // ******** VARIABLES ********

  const navigate = useNavigate();
  const location = useLocation();

  // Tamaños de modal
  const styleDet = {
    height: "clac(100vh - 52px)",
    width: "clac(100vw - 52px)",
    margin: "0 2%",
  };

  const columns = [
    {
      title: "Medicamento",
      dataIndex: "Concepto",
      key: "medicamento",
    },
    {
      title: "Dosis",
      dataIndex: "Descripcion",
      key: "dosis",
    },
  ];

  // Expresiones
  const expresiones = {
    Numeros: /^\d{1,10}$/, // numeros.
    // telefono: /^\d{1,10}$/, // 7 a 14 numeros.
    // telefono: /^[0-9]{1,10}$/, // numeros.
    Letras: /^[ñíóáéú a-zA-Z-ZÀ-ÿ\s]{1,}$/, // Letras y espacios, pueden llevar acentos.
    Correo: /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i, //Letras y numeros sin espacios y caracteres especiales (-,_,.)
  };

  let fecha_format = new Date(Date.now());
  let mes = fecha_format.getMonth() + 1;
  let mes_correcto = mes.toString().length === 1 ? `0${mes}` : mes;
  let fechaActual = `${fecha_format.getFullYear()}-${mes_correcto}-${fecha_format.getDate()}`;
  // let fechaActual = `${fecha_format.getDate()}-${mes_correcto}-${fecha_format.getFullYear()}`;

  const dateFormatList = ["DD/MM/YYYY", "DD/MM/YY", "DD-MM-YYYY", "DD-MM-YY"];

  // ******** USE STATES ********

  // todos los Modales
  const [IsOpenEstudio, openEstudio, CloseEstudio] = UseModal(false);
  const [IsOpeNotaDetalle, OpenNotaDetalle, CloseNotaDetalle] = UseModal(false);
  const [IsOpenMotivoConsulta, OpenMotivoConsulta, ClosedMotivoConsulta] =
    UseModal(false);
  const [IsOpenCostoConsulta, OpenCostoConsulta, ClosedCostoConsulta] =
    UseModal(false);
  const [IsOpenTipoUsuario, OpenTipoUsuario, ClosedTipoUsuario] =
    UseModal(false);
  const [IsOpenAddContacto, OpenAddContacto, ClosedAddContacto] =
    UseModal(false);
  const [IsOpenSelectContacto, OpenSelectContacto, ClosedSelectContacto] =
    UseModal(false);
  const [IsOpenResumenMedico, OpenResumenMedico, ClosedResumenMedico] =
    UseModal(false);

  // Estados para visualizaciones
  const [Consulta, setConsulta] = useState(false);
  const [opcionModal, setopcionModal] = useState(0);

  // Estado de imagen para modal de agregar pasiente
  const [ProfileImage, setProfileImage] = useState({
    name: "",
    url: "",
    file: "",
  });

  const [ConsultasList, setConsultasList] = useState([]);
  const [ConsultasListFilter, setConsultasListFilter] = useState([]);

  const [MedicosList, setMedicosList] = useState([]);
  const [MedicosListFilter, setMedicosListFilter] = useState([]);

  // Lista de modal
  const [contactList, setContactList] = useState([]);
  const [contactListFilter, setContactListFilter] = useState([]);
  const [DatosContacto, setDatosContacto] = useState({ id: "", nombre: "", imagen: "" });

  const [DatosMedico, setDatosMedico] = useState({ id: "", nombre: "", imagen: "" });

  const [IdConsulta, setIdConsulta] = useState("");

  // VARIABLES MODAL ADD CONTACTO
  const [AddNombrecontacto, setAddNombrecontacto] = useState("");
  const [AddApellidoPcontacto, setAddApellidoPcontacto] = useState("");
  const [AddApellidoMcontacto, setAddApellidoMcontacto] = useState("");
  const [AddCelularcontacto, setAddCelularcontacto] = useState("");
  const [AddCorreocontacto, setAddCorreocontacto] = useState("");
  const [AddNotaContacto, setAddNotaContacto] = useState("");

  const [Sexo, setSexo] = useState(null);
  const [Nacimiento, setNacimiento] = useState("");
  const [Edad, setEdad] = useState(0);

  // #### INICIO DE VARIABLES CONSULTA/NOTA EVOLUCION ####

  const [TipoConsulta, setTipoConsulta] = useState(null);

  // MOTIVO CONSULTA
  const [MotivoConsulta, setMotivoConsulta] = useState("");
  // EVOLUCION DEL PADECIMIENTO
  const [EvolucionPadecimiento, setEvolucionPadecimiento] = useState("");
  const [IMC, setIMC] = useState("");
  const [
    DiagnosticosSeleccionadosMostrados,
    setDiagnosticosSeleccionadosMostrados,
  ] = useState([]);

  const [ListaRecetas, setListaRecetas] = useState([]);

  // RECOMENDACIONES
  const [Recomendaciones, setRecomendaciones] = useState("");

  // ORDEN DE ESTUDIOS DE LABORATORIO
  const [OrdenLabTest, setOrdenLabTest] = useState({ descripcion: "", url: "" });

  // #### FIN DE VARIABLES CONSULTA/NOTA DE EVOLUCION ####

  // MANEJA DE MENSAJES
  const [open, setOpen] = useState(false);
  const [typeMessage, setTypeMessage] = useState("");
  const [message, setMessage] = useState("");

  const [loader, setLoader] = useState(false);
  const [loaderModal, setLoaderModal] = useState(false);
  const [loaderResumenMedico, setLoaderResumenMedico] = useState(false);

  const [EstatusModalEnviarReceta, setEstatusModalEnviarReceta] = useState(false);
  const [loaderReceta, setLoaderReceta] = useState(false);

  const [FechaFiltro, setFechaFiltro] = useState("");

  const [DetalleConsulta, setDetalleConsulta] = useState("");

  const [RowsReceta, setRowsReceta] = useState([]);

  const [ResumenMedico, setResumenMedico] = useState("");

  const [CierreModalResumen, setCierreModalResumen] = useState(false);

  const [OpenDatePicker, setOpenDatePicker] = useState(false);

  // Estatus mensajes ADD CONTACTO
  const [MensajeAddNombrecontacto, setMensajeAddNombrecontacto] =
    useState("true");
  const [MensajeAddApellidoPcontacto, setMensajeAddApellidoPcontacto] =
    useState("true");
  const [MensajeAddApellidoMcontacto, setMensajeAddApellidoMcontacto] =
    useState("true");
  const [MensajeAddCelularcontacto, setMensajeAddCelularcontacto] =
    useState("true");
  const [MensajeAddCorreocontacto, setMensajeAddCorreocontacto] =
    useState("true");
  // Estatus mensajes DATOS CONSULTA
  const [MensajeMotivoConsulta, setMensajeMotivoConsulta] = useState("true");
  const [MensajeTipoConsulta, setMensajeTipoConsulta] = useState("true");

  const [MensajeResumenMedico, setMensajeResumenMedico] = useState("true");

  // ******** FUNCIONES ********

  async function getConsultas() {
    setLoader(true);
    if (!!FechaFiltro) {
      setFechaFiltro("");
    }
    let data = new FormData();
    data.append("Tipo", "Consultorio");
    let response = await getConsultas_request(data);
    if (response.ok) {
      // console.log(response.data);
      setConsultasList(response.data);
      setConsultasListFilter(response.data);
    } else {
      setConsultasList([]);
      setConsultasListFilter([]);
      setOpen(true);
      setTypeMessage("error");
      setMessage(response.mensaje);
    }
    setLoader(false);
  }

  async function detailConsulta(idConsulta) {
    OpenNotaDetalle(true);
    setLoaderModal(true);
    let response = await detailConsultas_request(idConsulta);
    if (response.ok) {
      // console.log(response.data);
      setDetalleConsulta(response.data);
      // if (response.data.Partidas.length > 0) {
      //   let partidas = [];
      //   response.data.Partidas.forEach((element, index) => {
      //     partidas.push({
      //       cantidad: element.Cantidad,
      //       medicamento: element.Concepto,
      //       dosis: element.Descripcion,
      //     });
      //   });
      // console.log(partidas);
      //   setRowsReceta(partidas);
      // } else if (response.data.Partidas.length === undefined) {
      //   let partidas = [];
      //   partidas.push({
      //     cantidad: response.data.Partidas.Cantidad,
      //     medicamento: response.data.Partidas.Concepto,
      //     dosis: response.data.Partidas.Descripcion,
      //   });
      // console.log(partidas);
      //   setRowsReceta(partidas);
      // }
      if (!!response.data.Consulta.Peso && !!response.data.Consulta.Altura) {
        let peso_temp = response.data.Consulta.Peso;
        let altura_temp = response.data.Consulta.Altura;
        let imc = peso_temp / (altura_temp * altura_temp);
        setIMC(imc.toFixed(2));
      }
      setDatosContacto({
        id: response.data.Consulta.IdContacto,
        nombre: response.data.Consulta.Contacto,
        imagen: response.data.Consulta.Imagen_Url,
      });
      if (!!response.data.Consulta.fechaNacimiento) {
        getEdad(response.data.Consulta.fechaNacimiento);
      }

      if (response.data.Orden.length > 0) {
        setOrdenLabTest({ descripcion: response.data.Orden[0].descripcion, url: response.data.Orden[0].url });
      }
      setListaRecetas(response.data.Receta);
      let diag_temp = !!response.data.Consulta.Diagnostico ? response.data.Consulta.Diagnostico.split(",") : [];
      // console.log(diag_temp);
      let array_temp = [];
      if (diag_temp.length > 0) {
        diag_temp.forEach((element) => {
          array_temp.push({ index: "", diagnostico: element });
        });
      }
      setDiagnosticosSeleccionadosMostrados(array_temp);
      setSexo(response.data.Consulta.sexo);
      setMotivoConsulta(response.data.Consulta.Motivo);
      setEvolucionPadecimiento(response.data.Consulta.EvolucionPadecimiento);
      setRecomendaciones(response.data.Consulta.Recomendaciones);
    } else {
      setOpen(true);
      setTypeMessage("error");
      setMessage(response.mensaje);
      CloseNotaDetalle(true);
    }
    setLoaderModal(false);
  }

  async function addResumenMedico() {
    let data = new FormData();
    data.append("resumen", ResumenMedico);
    data.append("IdUsuario", DatosContacto.id);
    let response = await addResumenMedico_request(data);
    if (response.ok) {
      // console.log(response.data);
      setOpen(true);
      setTypeMessage("success");
      setMessage("Resumen médico creado");
      cleanInputsDetalleConsulta();
      ClosedResumenMedico(true);
      window.open(response.data, "_blank");
      // return;
      // cleanInputsDetalleConsulta();
      // ClosedResumenMedico(true);
      // resumenMedico(response.data);
    } else {
      setOpen(true);
      setTypeMessage("error");
      setMessage(response.mensaje);
    }
  }

  async function vistaPreviaResumenMedico() {
    setLoaderResumenMedico(true);
    let data = new FormData();
    data.append("resumen", ResumenMedico);
    data.append("IdUsuario", DatosContacto.id);
    if (!!ResumenMedico) {
      let response = await previewResumenMedico_request(data);
      if (response.ok) {
        // console.log(response.data.resumen);
        window.open(response.data.resumen, "_blank");
      } else {
        setOpen(true);
        setTypeMessage("error");
        setMessage(response.mensaje);
      }
    } else {
      setMensajeResumenMedico("false");
      setOpen(true);
      setTypeMessage("warning");
      setMessage("Faltan campos por completar");
    }
    setLoaderResumenMedico(false);
  }

  async function getConsultasFilter(fecha) {
    // console.log("FILTRANDO CONSULTAS");
    // console.log(fecha);
    setLoader(true);
    let data = new FormData();
    data.append("FechaInicio", fecha);
    data.append("FechaFin", fecha);
    data.append("Tipo", "Consultorio");
    let response = await getConsultasPeriod_request(data);
    if (response.ok) {
      // console.log(response.data);
      setConsultasList(response.data);
      setConsultasListFilter(response.data);
    } else {
      setConsultasList([]);
      setConsultasListFilter([]);
      setOpen(true);
      setTypeMessage("error");
      setMessage(response.mensaje);
    }
    setLoader(false);
    return;
  }

  // Metodo get modales
  async function getContactos() {
    let response = await getContacts_request();
    if (response.ok) {
      console.log(response.data);
      let array = [];
      let medicos = [
        {
          label: "Contactos",
          options: [],
        },
      ];
      let medicos_filter = [];
      response.data.forEach((element) => {
        if (element.Type !== 1 && element.Type !== 4) {
          array.push({
            usuario: `${element.Nombre} ${element.ApellidoPaterno} ${!!element.ApellidoMaterno ? element.ApellidoMaterno : ""}`,
            imagen: `${!!element.Imagen_url ? element.Imagen_url : ""}`,
            id: `${element.IdContacto}`,
          });
        } else if (element.Type === 1) {
          medicos[0].options.push({
            value: element.IdContacto,
            label: (
              <div style={{ display: "flex", gap: "1" }}>
                <img
                  className="ImgMedicoSelect"
                  src={
                    !!element.Imagen_url ? `https://${element.Imagen_url}` : ""
                  }
                />{" "}
                <div
                  style={{ marginLeft: "1%" }}
                >{`${element.Nombre} ${element.ApellidoPaterno} ${!!element.ApellidoMaterno ? element.ApellidoMaterno : ""} - ${element.YgiaUser}`}</div>
              </div>
            ),
          });
          medicos_filter.push(element);
        }
      });
      setContactList(array);
      setContactListFilter(array);
      setMedicosList(medicos);
      setMedicosListFilter(medicos_filter);
    } else {
    }
  }

  async function enviarReceta(idReceta) {
    // console.log(idReceta);
    setLoaderReceta(true);
    let response = await sendRecetas_request(idReceta);
    if (response.ok) {
      setOpen(true);
      setTypeMessage("success");
      setMessage("Receta enviada");
      detailConsulta(IdConsulta);
    } else {
      setOpen(true);
      setTypeMessage("error");
      setMessage(response.mensaje);
    }
    setLoaderReceta(false);
  }

  function filterContacts(text) {
    // console.log(text.target.value);
    // console.log(solicitudesSearch);
    var chars = {
      á: "a",
      é: "e",
      í: "i",
      ó: "o",
      ú: "u",
      à: "a",
      è: "e",
      ì: "i",
      ò: "o",
      ù: "u",
      ñ: "n",
      Á: "A",
      É: "E",
      Í: "I",
      Ó: "O",
      Ú: "U",
      À: "A",
      È: "E",
      Ì: "I",
      Ò: "O",
      Ù: "U",
      Ñ: "N",
    };
    var expr = /[áàéèíìóòúùñ]/gi;
    let filter = [];

    contactListFilter.forEach((contacto) => {
      if (
        contacto.usuario
          .replace(expr, function (e) {
            return chars[e];
          })
          .toLowerCase()
          .indexOf(
            text.target.value
              .replace(expr, function (e) {
                return chars[e];
              })
              .toLowerCase()
          ) > -1
      ) {
        filter.push(contacto);
      }
    });

    setContactList(filter);
  }

  const saveContact = async (e) => {
    e.preventDefault();
    // console.log(AddNombrecontacto);
    // console.log(AddApellidoPcontacto);
    setLoaderModal(true);
    let data = new FormData();
    if (
      !!AddNombrecontacto &&
      !!AddApellidoPcontacto &&
      MensajeAddNombrecontacto !== "false" &&
      MensajeAddApellidoPcontacto !== "false" &&
      MensajeAddApellidoMcontacto !== "false" &&
      MensajeAddCelularcontacto !== "false" &&
      MensajeAddCorreocontacto !== "false"
    ) {
      data.append("Nombre", AddNombrecontacto);
      data.append("ApellidoPaterno", AddApellidoPcontacto);
      data.append("ApellidoMaterno", AddApellidoMcontacto);
      data.append("Nota", AddNotaContacto);
      if (!!AddCelularcontacto) {
        data.append("Celular", AddCelularcontacto);
      }
      if (!!AddCorreocontacto) {
        data.append("Email", AddCorreocontacto);
      }

      data.append("Tipo", "Paciente");
      if (ProfileImage.file !== "") {
        data.append("Imagen", ProfileImage.file, ProfileImage.name);
      }
      let response = await addContact_request(data);
      if (response.ok) {
        ReactGA.event({
          category: "Consultas",
          action: "Nuevo contacto exitoso",
          label: "Boton para guardar contacto", // optional
          value: 99, // optional, must be a number
          nonInteraction: true, // optional, true/false
          transport: "xhr", // optional, beacon/xhr/image
        });
        setOpen(true);
        setTypeMessage("success");
        setMessage("Contacto guardado");
        setDatosContacto({
          id: response.data.IdContacto,
          nombre: `${response.data.Nombre} ${response.data.ApellidoPaterno} ${response.data.ApellidoMaterno}`,
          imagen: response.data.Imagen_url,
        });
        cleanInputsContacto();
        ClosedAddContacto(true);
        OpenMotivoConsulta(true);

        // getContactos();
        // CloseModalcontacto1(false);
        // clean_inputsAdd();
      } else {
        ReactGA.event({
          category: "Consultas",
          action: "Nuevo contacto erroneo",
          label: `${response.mensaje}`, // optional
          value: 99, // optional, must be a number
          nonInteraction: true, // optional, true/false
          transport: "xhr", // optional, beacon/xhr/image
        });
        setOpen(true);
        setTypeMessage("error");
        setMessage(response.mensaje);
      }
    } else {
      setOpen(true);
      setTypeMessage("warning");
      setMessage("Faltan campos por completar");
      if (!!!AddNombrecontacto) {
        setMensajeAddNombrecontacto("false");
      }

      if (!!!AddApellidoPcontacto) {
        setMensajeAddApellidoPcontacto("false");
      }
    }
    // console.log("AQUUI");
    setLoaderModal(false);
    return;
  };

  function cleanInputsContacto() {
    setProfileImage({ name: "", url: "", file: "" });
    setAddNombrecontacto("");
    setAddApellidoPcontacto("");
    setAddApellidoMcontacto("");
    setAddCelularcontacto("");
    setAddCorreocontacto("");
    setMensajeAddNombrecontacto("true");
    setMensajeAddApellidoPcontacto("true");
    setMensajeAddApellidoMcontacto("true");
    setMensajeAddCelularcontacto("true");
    setMensajeAddCorreocontacto("true");
  }

  function cleanInputsDetalleConsulta() {
    setDetalleConsulta("");
    setDatosContacto("");
    setNacimiento("");
    setListaRecetas([]);
    setDiagnosticosSeleccionadosMostrados([]);
    setSexo("");
    setMotivoConsulta("");
    setEvolucionPadecimiento("");
    setRecomendaciones("");
    setResumenMedico("");
  }

  function cleanInputsDatosConsulta() {
    setTipoConsulta(null);
    setMotivoConsulta("");
  }

  function filterList(text) {
    var chars = {
      á: "a",
      é: "e",
      í: "i",
      ó: "o",
      ú: "u",
      à: "a",
      è: "e",
      ì: "i",
      ò: "o",
      ù: "u",
      ñ: "n",
      Á: "A",
      É: "E",
      Í: "I",
      Ó: "O",
      Ú: "U",
      À: "A",
      È: "E",
      Ì: "I",
      Ò: "O",
      Ù: "U",
      Ñ: "N",
    };
    var expr = /[áàéèíìóòúùñ]/gi;
    let filter = [];

    ConsultasListFilter.forEach((consulta) => {
      if (
        consulta.Paciente.replace(expr, function (e) {
          return chars[e];
        })
          .toLowerCase()
          .indexOf(
            text.target.value
              .replace(expr, function (e) {
                return chars[e];
              })
              .toLowerCase()
          ) > -1 ||
        consulta.FechaConsulta.replace(expr, function (e) {
          return chars[e];
        })
          .toLowerCase()
          .indexOf(
            text.target.value
              .replace(expr, function (e) {
                return chars[e];
              })
              .toLowerCase()
          ) > -1
      ) {
        filter.push(consulta);
      }
    });
    setConsultasList(filter);
  }

  function crearResumenMedico() {
    let medicamentos_temp = [];
    if (ListaRecetas.length > 0) {
      ListaRecetas.forEach((receta) => {
        receta.partidas.forEach((medicamento) => {
          medicamentos_temp.push(medicamento);
        });
      });
    }
    let diagnosticos_temp = [];
    if (DiagnosticosSeleccionadosMostrados.length > 0) {
      DiagnosticosSeleccionadosMostrados.forEach((element) => {
        diagnosticos_temp.push(element.diagnostico);
      });
    }

    let resumen =
      `Paciente: \n${DatosContacto.nombre}\n
    \nEdad:\n${!!Edad ? Edad : "No especificada"}\n
    \nGénero:\n${Sexo}\n
    \nMotivo de consulta:\n${MotivoConsulta}\n
    \nEvolución del padecimiento:\n${!!EvolucionPadecimiento ? EvolucionPadecimiento : "Sin evolución del padecimiento"}\n
    \nExploración física:\n${!!DetalleConsulta.Consulta.ExploracionFisica ? DetalleConsulta.Consulta.ExploracionFisica : "Sin exploración física"}\n
    \nDiagnósticos:\n${diagnosticos_temp.toString()}\n`;
    resumen += `\n\nTratamientos:`
    if (medicamentos_temp.length > 0) {
      medicamentos_temp.forEach(element => {
        resumen += `\n${element.Concepto} - ${element.Descripcion}`;
      });
      resumen += `\n\n`
    } else {
      resumen += `\nSin tratamiento\n\n`;
    }

    resumen += `\nRecomendaciones para el paciente: \n${!!Recomendaciones ? Recomendaciones : "Sin recomendaciones"} `;

    setResumenMedico(resumen);

    OpenResumenMedico(true);
  }

  function getEdad(dateString) {
    let hoy = new Date();
    let fechaNacimiento = new Date(dateString.replace(/-/g, "/"));
    let edad = hoy.getFullYear() - fechaNacimiento.getFullYear();
    let diferenciaMeses = hoy.getMonth() - fechaNacimiento.getMonth();
    if (
      diferenciaMeses < 0 ||
      (diferenciaMeses === 0 && hoy.getDate() < fechaNacimiento.getDate())
    ) {
      edad--;
    }
    // console.log(edad);
    setEdad(edad);
    // return edad;
  }

  function navigateEditConsulta() {
    navigate("/app/ModificarConsulta", {
      //  replace: false,
      state: {
        id: DatosContacto.id,
        imagen: DatosContacto.imagen,
        nombre: DatosContacto.nombre,
        idConsulta: IdConsulta,
      },
    });
  }

  function redireccionExpediente() {
    navigate("/app/ExpedientesNuevoDetalle",
      {
        //  replace: false,
        state: {
          id: DatosContacto.id,
          imagen: DatosContacto.imagen,
          nombre: DatosContacto.nombre,
        },
      });
  }


  // MODAL ELIMINAR CITA
  const [modal, ModalEnviarReceta] = Modal.useModal();
  const confirm = (idReceta) => {
    modal.confirm({
      title: `¿Desea enviar la receta al paciente ${DetalleConsulta.Consulta.Contacto}?`,
      okText: "Enviar",
      cancelText: "Cancelar",
      onOk: () => enviarReceta(idReceta),
      onCancel: () => setEstatusModalEnviarReceta(false),
      open: EstatusModalEnviarReceta,
      icon: <ExclamationCircleOutlined />,
      // content: (
      //   <div>
      //     <p>Paciente: {DatosCita.nombre}</p>
      //     <p>Fecha: {DatosCita.fecha}</p>
      //     <p>Hora: {DatosCita.hora}</p>
      //     <p>
      //       Tipo:{" "}
      //       {DatosCita.tipo === 0
      //         ? "Urgencia"
      //         : DatosCita.tipo === 1
      //           ? "Primera vez"
      //           : "Seguimiento"}
      //     </p>
      //     <p>Estado: {DatosCita.estado}</p>
      //   </div>
      // ),
    });
  };

  useEffect(() => {
    getConsultas();
    getContactos();
    // console.log("USE EFFECT");
    return;
  }, []);

  return (
    <Stack>
      <NavbarNuevo
        modulo={"Consultas"}
        mensajesError={{
          setOpen: setOpen,
          setTypeMessage: setTypeMessage,
          setMessage: setMessage,
        }}
        searchBar={
          <div className="EncabezadoConsulta">
            <div className="espaciado">
              <div>
                <b>Consultas</b>
              </div>
              <ButtonAntd
                tipo={1}
                style={{ width: "100px" }}
                onClick={() => {
                  ReactGA.event({
                    category: "Consultas",
                    action: "Nueva consulta",
                    label: "Boton de nueva consulta", // optional
                    value: 99, // optional, must be a number
                    nonInteraction: true, // optional, true/false
                    transport: "xhr", // optional, beacon/xhr/image
                  });
                  // OpenTipoUsuario(true);
                  OpenSelectContacto(true);
                  cleanInputsDatosConsulta();
                }}
                children={
                  <Row style={{ width: "100%", gap: ".5rem", color: "white", justifyContent: "space-between", alignItems: "center" }}>
                    Nueva
                    <AddCircle fontSize="15px" />

                  </Row>
                }
              />
            </div>
            <div className="searchBarFiltroConsulta">
              {/* <Input
                style={{ width: "45%" }}
                placeholder="Paciente"
                type="text"
                className="inputblue"
                onChange={filterList}
              /> */}
              <Input
                prefix={<SearchIcon style={{ color: "#d7d7d7", width: "18px" }} />}
                // style={{ width: "45%" }}
                placeholder="Paciente"
                type="text"
                onChange={filterList}
              />
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  marginBottom: "1%",
                  justifyContent: "end",
                }}
              >
                {!!FechaFiltro && (
                  <Button
                    style={{
                      background: "white",
                      width: "100px",
                      color: "#148F9F",
                      border: "0",
                      height: "30px",
                      padding: "0 1%",
                      margin: ".8% 0",
                      // marginLeft: "3%",
                      marginRight: "0",
                      display: "flex",
                      alignItems: "center",
                    }}
                    onClick={() => {
                      setFechaFiltro("");
                      getConsultas();
                    }}
                  >
                    <Cancel sx={{ color: "#148f9f", width: "20px" }} />
                    Filtro
                  </Button>
                  // <b style={{ color: "#148f9f", cursor: "pointer", margin: ".8% 0", marginLeft: "1%" }} onClick={() => {
                  //   setFechaFiltro("");
                  //   getConsultas();
                  // }}><Cancel sx={{ color: "#148f9f" }} onClick={() => {
                  //   setFechaFiltro("");
                  //   getConsultas();
                  // }}></Cancel>Filtro</b>
                )}
                <DatePicker
                  inputReadOnly={true}
                  // open={OpenDatePicker}
                  className="rangeConsultasPrincipal"
                  placeholder="Filtro"
                  value={!!FechaFiltro ? dayjs(FechaFiltro) : ""}
                  format={dateFormatList}
                  locale={locale}
                  style={{ margin: ".8% 0" }}
                  size="small"
                  // onBlur={(e) => {
                  //   // console.log(e.target.value);
                  //   // console.log(e.target.value.split("-"));
                  //   let arrayFechaFormat = e.target.value.split("-");
                  //   let arrayFecha = e.target.value.split("/");
                  //   if (arrayFecha.length === 3) {
                  //     let fecha = new Date(
                  //       `${arrayFecha[1]} /${arrayFecha[0]}/${arrayFecha[2]}`
                  //     );
                  //     // let temp = e.$d
                  //     // console.log(fecha.getMonth());
                  //     let mesObtenido = fecha.getMonth() + 1;
                  //     // console.log(mesObtenido);
                  //     let mesCalculo =
                  //       mesObtenido.toString().length === 1
                  //         ? `0${mesObtenido}`
                  //         : mesObtenido;
                  //     // console.log(mesCalculo);
                  //     let fecha_enviar =
                  //       fecha.getFullYear() +
                  //       "-" +
                  //       mesCalculo +
                  //       "-" +
                  //       fecha.getDate();
                  //     // console.log(fecha_enviar);
                  //     setFechaFiltro(fecha_enviar);
                  //     getConsultasFilter(fecha_enviar);
                  //   } else if (arrayFechaFormat.length === 3) {
                  //     let fecha = new Date(
                  //       `${arrayFechaFormat[1]} /${arrayFechaFormat[0]}/${arrayFechaFormat[2]}`
                  //     );
                  //     // let temp = e.$d
                  //     // console.log(fecha.getMonth());
                  //     let mesObtenido = fecha.getMonth() + 1;
                  //     // console.log(mesObtenido);
                  //     let mesCalculo =
                  //       mesObtenido.toString().length === 1
                  //         ? `0${mesObtenido}`
                  //         : mesObtenido;
                  //     // console.log(mesCalculo);
                  //     let fecha_enviar =
                  //       fecha.getFullYear() +
                  //       "-" +
                  //       mesCalculo +
                  //       "-" +
                  //       fecha.getDate();
                  //     setFechaFiltro(fecha_enviar);
                  //     getConsultasFilter(fecha_enviar);
                  //     setOpenDatePicker(false);
                  //   }
                  // }}
                  // onPanelChange={(e)=>{
                  // console.log(e);
                  // }}
                  onSelect={(e) => {
                    let temp = e.$d;
                    let mesObtenido = temp.getMonth() + 1;
                    // console.log(mesObtenido);
                    let mesCalculo =
                      mesObtenido.toString().length === 1
                        ? `0${mesObtenido}`
                        : mesObtenido;
                    let fecha_enviar =
                      temp.getFullYear() +
                      "-" +
                      mesCalculo +
                      "-" +
                      temp.getDate();
                    // console.log(fecha_enviar);
                    setFechaFiltro(fecha_enviar);
                    getConsultasFilter(fecha_enviar);
                    // setOpenDatePicker(false);
                  }}
                // onChange={(e) => {
                // console.log(e);
                // }}
                // onKeyDown={(e)=>{
                //   // console.log(e);
                // }}
                // onClick={() => {
                //   setOpenDatePicker(true);
                // }}
                />

                {/* <Input 
                size="small"
                // style={{width:""}}
                type="date"
                  onChange={(e) => {

                    let fecha_array = e.target.value.split("-");
                    if (fecha_array[0][0] !== "0") {
                      // console.log(e.target.value);
                    }
                  }}
                ></Input> */}
              </div>
            </div>
          </div>
        }
      />

      <Content
        className="site-layout"
        style={{
          padding: "0 3%",
          width: "100%",
          overflow: "initial",
        }}
      >
        {loader ? (
          <Loader />
        ) : (
          <>
            <ConsultasCard
              lista={ConsultasList}
              setOpenDetail={detailConsulta}
              setIdConsulta={setIdConsulta}
              navigateEditConsulta={navigateEditConsulta}
              setDatosContacto={setDatosContacto}
              expediente={redireccionExpediente}
            />
            {ConsultasList.length === 0 && (
              <Empty description={<span style={{ color: "black" }}>No cuenta con consultas recientes</span>} />

            )}
          </>
        )}
      </Content>

      <ModalDetalleConsulta
        isOpen={IsOpeNotaDetalle}
        closeModal={CloseNotaDetalle}
        optionClose={true}
        styleDet={styleDet}
        extras={[
          "detalle_consulta",
          setDetalleConsulta,
          setIMC,
          cleanInputsDetalleConsulta,
        ]}
      >
        <div
          style={{
            width: "100%",
            background: "#148f9f",
            color: "#fff",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "1% 2%",
          }}
        >
          Detalle de consulta
          <CloseIcon style={{ cursor: "pointer", fontSize: 20 }}
            onClick={() => {
              CloseNotaDetalle(true);
              setDetalleConsulta("");
              setIMC("");
              setDatosContacto({ id: "", nombre: "", imagen: "" });
              setIdConsulta("");
            }} />
        </div>
        <div className="ModalDetalConsuts">
          {loaderModal ? (
            <LoaderModals className={"ModalDetalConsuts"} />
          ) : (
            <>
              <header style={{
                position: "sticky",
                top: 0,
                zIndex: 999,
                width: "100%",
                background: "white",
                padding: "5px 10px",
                border: "1px solid #d7d7d7"
              }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Avatar size={65} style={{ display: "flex", alignContent: "center", justifyContent: "center" }} src={
                    <Image
                      src={
                        !!DetalleConsulta
                          ? `https://${DetalleConsulta.Consulta.Imagen_Url}`
                          : Iconos[0]
                      }
                    />
                  } />
                </div >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "1%",
                  }}
                >
                  <b>
                    {!!DetalleConsulta
                      ? DetalleConsulta.Consulta.Contacto
                      : "Usuario"}
                  </b>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "1% 2%",
                    width: "100%",
                    gap: "1%"
                  }}
                >
                  <Button
                    type="button"
                    style={{
                      background: "#148f9f",
                      border: "0",
                      color: "white",
                      display: "flex",
                      alignItems: "center",
                      gap: ".5rem",
                    }}
                    onClick={() => {
                      CloseNotaDetalle(true);
                      setCierreModalResumen(true);
                      crearResumenMedico();
                    }}
                  >
                    Resumen médico <AddIcon fontSize="15" />
                  </Button>
                  <Button
                    type="button"
                    style={{
                      background: "#148f9f",
                      border: "0",
                      color: "white",
                      display: "flex",
                      alignItems: "center",
                      gap: ".5rem",
                    }}
                    onClick={() => {
                      navigateEditConsulta();
                    }}
                  >
                    Editar <Edit fontSize="15px" />
                  </Button>
                </div>
              </header>
              <Content
                className="site-layout"
                style={{
                  padding: "0 3%",
                  width: "100%",
                  overflow: "initial",
                }}>
                <div style={{ display: "flex", justifyContent: "end" }}>
                  <b>Fecha: {!!DetalleConsulta ? DetalleConsulta.Consulta.Fecha : ""}</b>
                </div>
                <TextAreaAntd
                  label="Motivo de consulta:"
                  value={
                    !!DetalleConsulta ? DetalleConsulta.Consulta.Motivo : ""
                  }
                  placeholder="Motivo de consulta"
                  style={{
                    marginBottom: ".5%",
                    fontFamily: "sans-serif",
                  }}
                  columns="30"
                  autoSize={true}
                  readOnly={true}
                />
                {/* Evolucion del padecimiento */}
                <Accordionn>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>Evolucion del padecimiento</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <TextArea
                      value={
                        !!DetalleConsulta
                          ? DetalleConsulta.Consulta.EvolucionPadecimiento
                          : ""
                      }
                      placeholder="Evolucion del padecimiento"
                      style={{
                        margin: ".5% 0",
                        fontFamily: "sans-serif",
                      }}
                      name=""
                      id=""
                      cols="30"
                      autoSize
                      readOnly={true}
                    />
                  </AccordionDetails>
                </Accordionn>
                {/* Exploración Física */}
                <Accordionn>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography> Exploración física {!!DetalleConsulta
                      && DetalleConsulta.Consulta.Altura === "N/A" &&
                      <Checkbox defaultChecked={true} style={{ marginLeft: "10px" }} disabled>N/A</Checkbox>}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {!!DetalleConsulta ? DetalleConsulta.Consulta.Altura === "N/A" ? (
                      <p style={{ textAlign: "center" }}>Sin información</p>
                    ) : (
                      <>
                        <div className="DisplayDetalle">
                          <div>
                            <InputAntd
                              label="Altura:"
                              style={{ width: "100%" }}
                              placeholder="Altura (Mtrs.)"
                              value={
                                !!DetalleConsulta
                                  ? DetalleConsulta.Consulta.Altura
                                  : ""
                              }
                              vacio={true}
                              readOnly={true}
                            />
                          </div>
                          <div>
                            <InputAntd
                              label="Peso:"
                              style={{ width: "100%" }}
                              type=""
                              placeholder="Peso (Kg.)"
                              value={
                                !!DetalleConsulta
                                  ? DetalleConsulta.Consulta.Peso
                                  : ""
                              }
                              vacio={true}
                              readOnly={true}
                            />
                          </div>
                          {!!IMC && (
                            <>
                              <div>
                                <InputAntd
                                  label="IMC:"
                                  style={{ width: "100%" }}
                                  type=""
                                  placeholder="IMC"
                                  value={IMC}
                                  vacio={true}
                                  readOnly={true}
                                />
                              </div>
                              <div>
                                <Label></Label>
                                <Input
                                  style={{ width: "100%" }}
                                  type=""
                                  placeholder="IMC"
                                  value={
                                    !!IMC
                                      ? IMC < 18
                                        ? "Insuficiente"
                                        : IMC >= 18 && IMC <= 25
                                          ? "Peso normal"
                                          : IMC > 25 && IMC <= 30
                                            ? "Sobrepeso"
                                            : IMC > 30 && IMC <= 40
                                              ? "Obesidad"
                                              : IMC > 40 && "Obesidad mórbida"
                                      : ""
                                  }
                                  readOnly={true}
                                  status={
                                    !!IMC
                                      ? IMC < 18
                                        ? "warning"
                                        : IMC >= 18 && IMC <= 25
                                          ? ""
                                          : IMC > 25 && IMC <= 30
                                            ? "warning"
                                            : IMC > 30 && IMC <= 40
                                              ? "warning"
                                              : IMC > 40 && "error"
                                      : ""
                                  }
                                />
                              </div>
                            </>
                          )}
                        </div>
                        <div className="DisplayDetalle">
                          <div>
                            <InputAntd
                              label="T. Arterial"
                              style={{ width: "100%" }}
                              type=""
                              placeholder="T. Arterial"
                              value={
                                !!DetalleConsulta
                                  ? DetalleConsulta.Consulta.TensionArterial
                                  : ""
                              }
                              vacio={true}
                              readOnly={true}
                            />
                          </div>
                          <div>
                            <InputAntd
                              label="F. Cardiaca"
                              style={{ width: "100%" }}
                              type=""
                              placeholder="F. Cardiaca"
                              value={
                                !!DetalleConsulta
                                  ? DetalleConsulta.Consulta.FrecuenciaCardiaca
                                  : ""
                              }
                              vacio={true}
                              readOnly={true}
                            />
                          </div>
                          <div>
                            <InputAntd
                              label="F. Respiratoria"
                              style={{ width: "100%" }}
                              type=""
                              placeholder="F. Respiratoria"
                              value={
                                !!DetalleConsulta
                                  ? DetalleConsulta.Consulta.FrecuenciaRespiratoria
                                  : ""
                              }
                              vacio={true}
                              readOnly={true}
                            />
                          </div>
                          <div>
                            <InputAntd
                              label="Temperatura"
                              style={{ width: "100%" }}
                              type=""
                              placeholder="Temperatura"
                              value={
                                !!DetalleConsulta
                                  ? DetalleConsulta.Consulta.Temperatura
                                  : ""
                              }
                              vacio={true}
                              readOnly={true}
                            />
                          </div>
                          <div>
                            <InputAntd
                              label="O2"
                              style={{ width: "100%" }}
                              type=""
                              placeholder="O2"
                              value={
                                !!DetalleConsulta
                                  ? DetalleConsulta.Consulta.SaturacionOxigeno
                                  : ""
                              }
                              vacio={true}
                              readOnly={true}
                            />
                          </div>
                        </div>
                        <TextArea
                          value={
                            !!DetalleConsulta
                              ? DetalleConsulta.Consulta.ExploracionFisica
                              : ""
                          }
                          placeholder="Exploración Física"
                          style={{
                            margin: ".5% 0",
                            fontFamily: "sans-serif",
                          }}
                          name=""
                          id=""
                          cols="30"
                          autoSize
                          readOnly={true}
                        />
                      </>
                    ) : (
                      <p style={{ textAlign: "center" }}>Sin información</p>
                    )}
                  </AccordionDetails>
                </Accordionn>
                {/* Estudios y analisis */}
                <Accordionn>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>Estudios y análisis</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {!!DetalleConsulta && !!DetalleConsulta.Estudios ? (
                      <>
                        <TextArea
                          value={DetalleConsulta.Estudios.Descripcion}
                          placeholder="Descripcion"
                          style={{
                            margin: ".5% 0",
                            fontFamily: "sans-serif",
                          }}
                          name=""
                          id=""
                          cols="30"
                          autoSize
                          readOnly={true}
                        />

                        <div>
                          <p style={{ fontWeight: "500" }}>Lista de archivos</p>

                          {DetalleConsulta.Documentos.map((documento) => (
                            <div
                              style={{ margin: "1% 0" }}

                            >
                              {documento.Multimedia.substring(
                                documento.Multimedia.toString().length,
                                documento.Multimedia.toString().length - 3
                              ) === "jpeg" ||
                                documento.Multimedia.substring(
                                  documento.Multimedia.toString().length,
                                  documento.Multimedia.toString().length - 3
                                ) === "png" ||
                                documento.Multimedia.substring(
                                  documento.Multimedia.toString().length,
                                  documento.Multimedia.toString().length - 3
                                ) === "jpg" ? (
                                <Row
                                  className="ListaDetalleEsAna"

                                >
                                  <Image
                                    style={{ width: "50px", height: "50px" }}
                                    src={"https://" + documento.Multimedia}
                                    alt=""
                                  />
                                  <div
                                    style={{
                                      width: "60%",
                                      height: "20px",
                                      overflow: "hidden",
                                      whiteSpace: "nowrap",
                                      textOverflow: "ellipsis",
                                    }}

                                  >
                                    <span>{documento.Nombre}</span>
                                  </div>
                                  <div></div>
                                </Row>
                              ) : documento.Multimedia.substring(
                                documento.Multimedia.toString().length,
                                documento.Multimedia.toString().length - 3
                              ) === "pdf" ? (
                                <Row
                                  className="ListaDetalleEsAna"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    window.open(
                                      "https://" + documento.Multimedia,
                                      "_blank"
                                    );
                                  }}
                                >
                                  <img
                                    style={{ width: "7%", marginRight: "3px" }}
                                    src={Iconos[22]}
                                  />
                                  <div
                                    style={{
                                      width: "60%",
                                      height: "20px",
                                      overflow: "hidden",
                                      whiteSpace: "nowrap",
                                      textOverflow: "ellipsis",
                                    }}
                                  >
                                    <span>{documento.Nombre}</span>
                                  </div>
                                  <div></div>
                                </Row>
                              ) : (
                                <Row
                                  className="ListaDetalleEsAna"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    window.open(
                                      "https://" + documento.Multimedia,
                                      "_blank"
                                    );
                                  }}
                                >
                                  <OndemandVideo sx={{ color: "#148F9F" }} />
                                  <div
                                    style={{
                                      width: "60%",
                                      height: "20px",
                                      overflow: "hidden",
                                      whiteSpace: "nowrap",
                                      textOverflow: "ellipsis",
                                    }}
                                  >
                                    <span>{documento.Nombre}</span>
                                  </div>
                                  <div></div>
                                </Row>
                              )}
                            </div>
                          ))}
                        </div>
                      </>
                    ) : (
                      <p style={{ textAlign: "center" }}>Sin información</p>
                    )}
                  </AccordionDetails>
                </Accordionn>
                {/* Diagnóstico clínico */}
                <Accordionn>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>Diagnóstico clínico</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <TextArea
                      value={
                        !!DetalleConsulta
                          ? DetalleConsulta.Consulta.Diagnostico
                          : ""
                      }
                      placeholder="Diagnóstico clínico"
                      style={{
                        margin: ".5% 0",
                        fontFamily: "sans-serif",
                      }}
                      name=""
                      id=""
                      cols="30"
                      autoSize
                      readOnly={true}
                    />
                    {/* <div style={{ position: "relative" }}>
      <Autosuggest
        suggestions={Diagnostico}
        onSuggestionsFetchRequested={
          onSuggestionsFetchRequested
        }
        onSuggestionsClearRequested={
          onSuggestionsClearRequested
        }
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
        onSuggestionSelected={eventEnter}
      />
    </div> */}
                  </AccordionDetails>
                </Accordionn>
                {/* Plan */}
                <Accordionn>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <Typography>Plan</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <TextAreaAntd
                      label="Plan médico"
                      id="plan_medico"
                      value={
                        !!DetalleConsulta ? DetalleConsulta.Consulta.Plan : ""
                      }
                      placeholder="Plan"
                      style={{
                        // margin: ".5% 0",
                        marginBottom: ".5%",
                        fontFamily: "sans-serif",
                      }}
                      columns="30"
                      autoSize={true}
                      vacio={false}
                      readOnly={true}
                    />
                    <h4>Receta</h4>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                        alignItems: "center",
                        gap: "1%",
                      }}
                    ></div>
                    {/* {DetalleConsulta.Receta.Partidas} */}
                    {!!DetalleConsulta && DetalleConsulta.Receta.map((receta, index) => (
                      <>
                        {receta.partidas.length > 0 ? (
                          <Accordionn key={index}>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <Typography>Receta {index + 1} - Folio: {receta.folio}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Row style={{ justifyContent: "space-between" }}>
                                {receta.enviar === 0 ? (
                                  <div></div>
                                ) : (
                                  <>
                                    {
                                      loaderReceta ?
                                        (<CircularProgress size={30} sx={{ color: "#148f9f" }} />) :
                                        (<ButtonAntd
                                          style={{ width: "110px" }}
                                          onClick={() => { confirm(receta.id) }}>
                                          Enviar a paciente
                                        </ButtonAntd>)
                                    }
                                  </>

                                )}
                                <FilePresent sx={{ fontSize: "30", color: "#148f9f", cursor: "pointer" }} onClick={() => {
                                  window.open(receta.url, "_blank");
                                }} />
                              </Row>
                              <Table columns={columns} dataSource={receta.partidas} scroll={{ x: 5 }} />
                            </AccordionDetails>
                          </Accordionn>

                        ) : (
                          <p style={{ textAlign: "center" }}>Sin información</p>
                        )}
                      </>
                    ))}


                    <hr
                      style={{
                        width: "100%",
                        border: "1px solid #148f9f",
                        opacity: "100%",
                        borderRadius: "5rem",
                      }}
                    />

                    <Row style={{ justifyContent: "start", margin: "2% 0" }}>
                      <h4 style={{ margin: "1% 0" }}>Ordenes</h4>
                    </Row>
                    {!!DetalleConsulta && DetalleConsulta.Orden.map((orden, index) => (
                      <>
                        {DetalleConsulta.Orden.length > 0 ? (
                          <Accordionn key={index}>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <Typography>
                                {orden.tipoOrden === 1 ? `Laboratorio` : `Interconsulta`}
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Row style={{ justifyContent: "end" }}>
                                <FilePresent sx={{ fontSize: "30", color: "#148f9f", cursor: "pointer" }} onClick={() => {
                                  window.open(orden.url, "_blank");
                                }} />
                              </Row>
                              {
                                orden.tipoOrden === 1 ? (
                                  <TextAreaAntd
                                    label="Descripción:"
                                    placeholder="Descripción"
                                    value={orden.descripcion}
                                    style={{
                                      marginBottom: ".5%",
                                      fontFamily: "sans-serif",
                                    }}
                                    columns="30"
                                    autoSize={true}
                                    true={true}
                                  />
                                ) : (

                                  <>
                                    {/* {
                                      contactList.forEach(user => {
                                        if (user.IdContacto === orden.idMedico) {
                                          setDatosMedico({ id: user.IdContacto, nombre: `${user.Nombre} ${user.ApellidoPaterno} ${user.ApellidoMaterno}`, imagen: user.Imagen_url })
                                        }
                                      })
                                    } */}
                                    {/* <Row style={{ justifyContent: "space-between", margin: "2% 0" }}>
                                      <Button
                                        type="button"
                                        onClick={() => addOrdenInter()}
                                        style={{
                                          // width: '60%',
                                          background: "#148f9f",
                                          border: "0",
                                          color: "white",
                                          display: "flex",
                                          alignItems: "center"
                                        }}

                                      >
                                        Guardar interconsulta
                                      </Button>
                                    </Row> */}

                                    {/* <SelectAntd
                                        label="Médico:"
                                        value={
                                          !!DatosMedico.id
                                            ? {
                                              value: "",
                                              label: (
                                                <div style={{ display: "flex", gap: "1" }}>
                                                  <img
                                                    className="ImgMedicoSelect"
                                                    src={
                                                      !!DatosMedico.imagen
                                                        ? `https://${DatosMedico.imagen}`
                                                        : ""
                                                    }
                                                  />{" "}
                                                  <div style={{ marginLeft: "1%" }}>
                                                    {DatosMedico.nombre}
                                                  </div>
                                                </div>
                                              ),
                                            }
                                            : null
                                        }
                                        style={{
                                          width: "100%",
                                          margin: "1% 0",
                                        }}
                                        placeholder="Selecciona un médico"
                                        onClick={() => {
                                          OpenModalContactos(true);
                                        }}
                                        setStateError={setMensajeDoctorSelect}
                                        valueError={MensajeDoctorSelect}
                                        textError="Debe seleccionar un médico"
                                        readOnly={true}
                                        open={false}
                                        vacio={false}
                                      /> */}

                                    {/* <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <b>Datos del médico</b>
                                      </div> */}
                                    {/* <div style={{ display: "flex", justifyContent: "space-between", gap: "1rem" }}>
                                        <InputAntd
                                          label="Nombre:"
                                          type=""
                                          placeholder="Escriba un nombre"
                                          value={NombreMedico}
                                          setStateValue={setNombreMedico}
                                          vacio={false}
                                          // style={{ width: "100%" }}
                                          styleDiv={{ width: "100%" }}
                                          setStateError={setMensajeNombreMedico}
                                          textError={["Debe escribir un nombre", ""]}
                                          valueError={MensajeNombreMedico}
                                          disabled={InputDisabled}
                                        />

                                        <InputAntd
                                          label="Apellido Paterno:"
                                          type=""
                                          placeholder="Escriba un apellido paterno"
                                          value={ApellidoPMedico}
                                          setStateValue={setApellidoPMedico}
                                          vacio={false}
                                          // style={{ width: "100%" }}
                                          setStateError={setMensajeApellidoPMedico}
                                          textError={["Debe escribir un apellido paterno", ""]}
                                          valueError={MensajeApellidoPMedico}
                                          styleDiv={{ width: "100%" }}
                                          disabled={InputDisabled}
                                        />

                                        <InputAntd
                                          label="Apellido Materno:"
                                          type=""
                                          placeholder="Escriba un apellido materno"
                                          value={ApellidoMMedico}
                                          setStateValue={setApellidoMMedico}
                                          vacio={true}
                                          styleDiv={{ width: "100%" }}
                                          disabled={InputDisabled}
                                        />

                                      </div>
                                      <div style={{ display: "flex", justifyContent: "space-between", gap: "1rem" }}>
                                        <InputAntd
                                          label="Especialidad:"
                                          type=""
                                          placeholder="Escriba una Especialidad"
                                          value={EspecialidadMedico}
                                          setStateValue={setEspecialidadMedico}
                                          vacio={false}
                                          setStateError={setMensajeEspecialidadMedico}
                                          textError={["Debe escribir una especialidad", ""]}
                                          valueError={MensajeEspecialidadMedico}
                                          styleDiv={{ width: "100%" }}
                                          // disabled={!!DoctorSelect || CheckNuevo ? false : true}
                                          disabled={!!DoctorSelect ? false : true}
                                        />

                                        <InputAntd
                                          label="Celular:"
                                          type=""
                                          placeholder="Escriba un celular"
                                          value={CelularMedico}
                                          setStateValue={setCelularMedico}
                                          vacio={true}
                                          styleDiv={{ width: "100%" }}
                                          disabled={InputDisabled}
                                        />

                                        <InputAntd
                                          label="Email:"
                                          type=""
                                          placeholder="Escriba un email"
                                          value={EmailMedico}
                                          setStateValue={setEmailMedico}
                                          vacio={true}
                                          styleDiv={{ width: "100%" }}
                                          disabled={InputDisabled}
                                        />

                                      </div> */}

                                    <b>Datos interconsulta</b>
                                    <TextAreaAntd
                                      label="Motivo:"
                                      value={orden.motivo}
                                      placeholder="Escriba un motivo"
                                      style={{
                                        margin: ".5% 0",
                                        fontFamily: "sans-serif",
                                      }}
                                      columns="30"
                                      autoSize={true}
                                      readOnly={true}
                                    />

                                    <TextAreaAntd
                                      label="Diagnóstico:"
                                      value={orden.diagnostico}
                                      placeholder="Escriba un diagnóstico"
                                      style={{
                                        margin: ".5% 0",
                                        fontFamily: "sans-serif",
                                      }}
                                      columns="30"
                                      autoSize={true}
                                      readOnly={true}
                                    />
                                  </>
                                )
                              }
                            </AccordionDetails>
                          </Accordionn>

                        ) : (
                          <p style={{ textAlign: "center" }}>Sin información</p>
                        )}
                      </>
                    ))}
                  </AccordionDetails>
                </Accordionn>
                {/* Recomendaciones */}
                <Accordionn>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>Recomendaciones para el paciente</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <TextArea
                      value={
                        !!DetalleConsulta
                          ? DetalleConsulta.Consulta.Recomendaciones
                          : ""
                      }
                      placeholder="Recomendaciones"
                      style={{
                        margin: ".5% 0",
                        fontFamily: "sans-serif",
                      }}
                      name=""
                      id=""
                      cols="30"
                      autoSize
                      readOnly={true}
                    />
                  </AccordionDetails>
                </Accordionn>
              </Content>
            </>
          )
          }
        </div >
      </ModalDetalleConsulta >

      <ModalMotivoConsulta
        isOpen={IsOpenMotivoConsulta}
        closeModal={ClosedMotivoConsulta}
        optionClose={false}
        styleDet={styleDet}
      >
        <div
          style={{
            width: "100%",
            background: "#148f9f",
            color: "#fff",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "1% 2%",
          }}
        >
          Datos de consulta
          <CloseIcon style={{ cursor: "pointer", fontSize: "20px" }}
            onClick={() => {
              ReactGA.event({
                category: "Consultas",
                action: "Boton cerrar motivo",
                label: "Boton para cerrar motivo de consulta", // optional
                value: 99, // optional, must be a number
                nonInteraction: true, // optional, true/false
                transport: "xhr", // optional, beacon/xhr/image
              });
              ClosedMotivoConsulta(true);
              setTipoConsulta(null);
              setMotivoConsulta("");
            }} />

        </div>
        <div className="scrolDatosConsulta">
          <SelectAntd
            label={"Tipo:"}
            requiredICon={true}
            value={TipoConsulta}
            style={{ width: "100%", margin: "2% 0" }}
            placeholder="selecciona un tipo"
            options={[
              { value: "1", label: "Primera vez" },
              { value: "2", label: "Seguimiento" },
            ]}
            setStateValue={setTipoConsulta}
            setStateError={setMensajeTipoConsulta}
            valueError={MensajeTipoConsulta}
            textError="Debe elegir un tipo"
          />
          <TextAreaAntd
            label={"Motivo de consulta"}
            requiredICon={true}
            value={MotivoConsulta}
            placeholder="Motivo de consulta"
            style={{
              margin: "2% 0",
              fontFamily: "sans-serif",
            }}
            columns="30"
            autoSize={true}
            setStateValue={setMotivoConsulta}
            setStateError={setMensajeMotivoConsulta}
            valueError={MensajeMotivoConsulta}
            textError={["Debe escribir un motivo de consulta"]}
            vacio={true}
          />
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              style={{
                background: "#148F9F",
                color: "white",
                border: "#148F9F",
              }}
              onClick={() => {
                if (!!TipoConsulta && !!MotivoConsulta) {
                  ReactGA.event({
                    category: "Consultas",
                    action: "Crear consulta",
                    label: "Boton para redirigirse a la consulta nueva", // optional
                    value: 99, // optional, must be a number
                    nonInteraction: true, // optional, true/false
                    transport: "xhr", // optional, beacon/xhr/image
                  });
                  // setopcionModal(1);
                  ClosedMotivoConsulta(true);
                  navigate("/app/NuevaConsulta", {
                    replace: true,
                    state: {
                      id: DatosContacto.id,
                      imagen: DatosContacto.imagen,
                      nombre: DatosContacto.nombre,
                      motivo: MotivoConsulta,
                      tipoConsulta: TipoConsulta,
                      idCita: "",
                    },
                  });
                } else {
                  if (!!!TipoConsulta) {
                    setMensajeTipoConsulta("false");
                  }

                  if (!!!MotivoConsulta) {
                    setMensajeMotivoConsulta("false");
                  }
                  setOpen(true);
                  setTypeMessage("warning");
                  setMessage("Faltan campos por completar");
                }
              }}
            >
              Continuar
            </Button>
          </div>
        </div>
      </ModalMotivoConsulta>

      <ModalTipoUsuario
        isOpen={IsOpenTipoUsuario}
        closeModal={ClosedTipoUsuario}
        optionClose={true}
        styleDet={styleDet}
      >
        <div
          style={{
            width: "100%",
            background: "#148f9f",
            color: "#fff",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "1% 2%",
          }}
        >
          <b>Contacto</b>
          <b style={{ cursor: "pointer" }} onClick={ClosedTipoUsuario}>
            x
          </b>
        </div>
        <div>
          <div className="ModalListContactosConsult" style={{ padding: "2%" }}>
            <Button
              style={{
                width: "100%",
                background: "#148f9f",
                borderColor: "#148f9f",
                color: "white",
              }}
              size="sm"
              onClick={() => {
                ClosedTipoUsuario(true);
                OpenAddContacto(true);
              }}
            >
              Paciente Nuevo
            </Button>
            <Button
              style={{
                width: "100%",
                background: "#148f9f",
                borderColor: "#148f9f",
                color: "white",
              }}
              size="sm"
              onClick={() => {
                ClosedTipoUsuario(true);
                OpenSelectContacto(true);
              }}
            >
              Paciente existente
            </Button>
          </div>
        </div>
      </ModalTipoUsuario>

      <ModalAddContacto
        isOpen={IsOpenAddContacto}
        closeModal={ClosedAddContacto}
        optionClose={false}
        styleDet={styleDet}
      ><>
          <div
            style={{
              width: "100%",
              background: "#148f9f",
              color: "#fff",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "1% 2%",
            }}
          >
            Nuevo contacto
            <CloseIcon style={{ cursor: "pointer", fontSize: "20px" }}
              onClick={() => {
                ClosedAddContacto(true);
                cleanInputsContacto();
                // OpenTipoUsuario(true);
                OpenSelectContacto(true);
              }} />
          </div>
          {loaderModal ?
            <>
              <LoaderModals className={"scrolNewContConsultas"} />
            </> :
            <div className="scrolNewContConsultas">
              <div style={{ padding: "2%", width: "100%" }}>
                <div style={{ textAlign: "center", marginBottom: "1%" }}>
                  <label htmlFor="imgPerfilConsulta" style={{ cursor: "pointer" }}>
                    <Avatar size={65} src={!!ProfileImage.url ? ProfileImage.url : Iconos[0]} />
                    <input
                      id="imgPerfilConsulta"
                      style={{ display: "none" }}
                      type="file"
                      accept="/image/x-PNG,image/jpeg"
                      onChange={(e) => {
                        const file = e.target.files[0];
                        if (file && file.type.substring(0, 5) === "image") {
                          setProfileImage({
                            name: e.target.value,
                            url: URL.createObjectURL(e.target.files[0]),
                            file: e.target.files[0],
                          });
                        }
                      }}
                    />
                  </label>
                </div>
                <br />
                <InputAntd
                  label="Nombre:"
                  requiredICon={true}
                  className="inputblue"
                  value={AddNombrecontacto}
                  setStateValue={setAddNombrecontacto}
                  style={{ margin: "2% 0" }}
                  placeholder="Nombre"
                  setStateError={setMensajeAddNombrecontacto}
                  textError={["Debe escribir un nombre", "Solo se permiten letras"]}
                  type="text"
                  valueError={MensajeAddNombrecontacto}
                  vacio={false}
                  disabled={false}
                  expresionRegular={expresiones.Letras}
                  maxLength={40}
                />

                <InputAntd
                  label="Apellido Paterno:"
                  requiredICon={true}
                  className="inputblue"
                  value={AddApellidoPcontacto}
                  setStateValue={setAddApellidoPcontacto}
                  style={{ margin: "2% 0" }}
                  placeholder="Apellido Paterno:"
                  setStateError={setMensajeAddApellidoPcontacto}
                  textError={[
                    "Debe escribir un apellido paterno",
                    "Solo se permiten letras",
                  ]}
                  type="text"
                  valueError={MensajeAddApellidoPcontacto}
                  vacio={false}
                  disabled={false}
                  expresionRegular={expresiones.Letras}
                  maxLength={25}
                />

                <InputAntd
                  className="inputblue"
                  value={AddApellidoMcontacto}
                  setStateValue={setAddApellidoMcontacto}
                  label="Apellido Materno:"
                  style={{ margin: "2% 0" }}
                  placeholder="Apellido Materno"
                  setStateError={setMensajeAddApellidoMcontacto}
                  textError={["", "Solo se permiten letras"]}
                  type="text"
                  valueError={MensajeAddApellidoMcontacto}
                  vacio={true}
                  disabled={false}
                  expresionRegular={expresiones.Letras}
                  maxLength={25}
                />

                <InputAntd
                  className="inputblue"
                  value={AddCelularcontacto}
                  setStateValue={setAddCelularcontacto}
                  label="Celular"
                  id="celular"
                  style={{ margin: "2% 0" }}
                  placeholder="Celular:"
                  setStateError={setMensajeAddCelularcontacto}
                  textError={[
                    "",
                    "Celular solo puede contener numeros",
                    "Celular debe contener 10 dígitos",
                  ]}
                  type="text"
                  valueError={MensajeAddCelularcontacto}
                  vacio={true}
                  disabled={false}
                  expresionRegular={expresiones.Numeros}
                  maxLength={10}
                />

                <InputAntd
                  className="inputblue"
                  value={AddCorreocontacto}
                  setStateValue={setAddCorreocontacto}
                  label="Correo"
                  style={{ margin: "2% 0" }}
                  placeholder="Correo:"
                  setStateError={setMensajeAddCorreocontacto}
                  textError={["", "Formato incorrecto"]}
                  type="text"
                  valueError={MensajeAddCorreocontacto}
                  vacio={true}
                  disabled={false}
                  expresionRegular={expresiones.Correo}
                />

                <TextAreaAntd
                  label="Comentarios o notas:"
                  value={AddNotaContacto}
                  placeholder="Escriba un comentario o nota (opcional)"
                  style={{
                    marginBottom: ".5%",
                    fontFamily: "sans-serif",
                  }}
                  columns="30"
                  autoSize={true}
                  setStateValue={setAddNotaContacto}
                />
                <div>
                  <Button
                    style={{
                      width: "100%",
                      background: "#148f9f",
                      borderColor: "#148f9f",
                      color: "white",
                    }}
                    size="sm"
                    onClick={(e) => {
                      saveContact(e);
                    }}
                  >
                    Crear Paciente
                  </Button>
                </div>
              </div>
            </div>
          }
        </>


      </ModalAddContacto>

      <ModalSelectContact
        isOpen={IsOpenSelectContacto}
        closeModal={ClosedSelectContacto}
        optionClose={true}
        styleDet={styleDet}
      >
        <div
          style={{
            width: "100%",
            background: "#148f9f",
            color: "#fff",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "1% 2%",
          }}
        >
          Selecciona un Contacto
          <CloseIcon style={{ cursor: "pointer", fontSize: "20px" }}
            onClick={() => {
              ClosedSelectContacto(true);
              // OpenTipoUsuario(true);
            }}
          />

        </div>
        <div className="ModalListContactosConsult">
          <div style={{ display: "flex", justifyContent: "end" }}>
            <Button type="button" onClick={() => {
              ReactGA.event({
                category: "Consultas",
                action: "Contacto nuevo",
                label: "Boton para abrir form de nuevo contacto", // optional
                value: 99, // optional, must be a number
                nonInteraction: true, // optional, true/false
                transport: "xhr", // optional, beacon/xhr/image
              });
              ClosedSelectContacto(true);
              OpenAddContacto(true);
            }}
              style={{
                width: "150px",
                backgroundColor: "#148f9f",
                color: "white"
              }}>Nuevo contacto</Button>
          </div>
          <p>Nombre</p>
          <Input
            placeholder="Buscar..."
            type="search"
            style={{ width: "100%", marginBottom: "10px" }}
            onChange={filterContacts}
          />

          <div
            className="contCardModal"
            style={{ borderTop: "1px solid #d5d5d5" }}
          >
            <AddLista
              CloseModal={ClosedSelectContacto}
              OpenModal={OpenMotivoConsulta}
              Lista={contactList}
              funcionExtra={[4, setDatosContacto, OpenMotivoConsulta]}
            />
          </div>
        </div>
      </ModalSelectContact>

      <ModalResumenMedico
        isOpen={IsOpenResumenMedico}
        closeModal={ClosedResumenMedico}
        optionClose={false}
        styleDet={styleDet}
      >
        <div
          style={{
            width: "100%",
            background: "#148f9f",
            color: "#fff",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "1% 2%",
          }}
        >
          Resumen médico
          <CloseIcon style={{ cursor: "pointer", fontSize: "20px" }}
            onClick={() => {
              ClosedResumenMedico(true);
              OpenNotaDetalle();
              setResumenMedico("");
            }} />

        </div>
        <div className="scrolResumenDeConsulta">
          <div
            style={{
              display: "flex",
              // justifyContent: "center", 
              width: "100%"
            }}
          >
            <TextAreaAntd
              label=""
              value={ResumenMedico}
              placeholder="Resumen médico"
              style={{
                width: "100%",
                marginBottom: ".5%",
                fontFamily: "sans-serif",
              }}
              columns="200"
              autoSize={true}
              setStateValue={setResumenMedico}
              setStateError={setMensajeResumenMedico}
              valueError={MensajeResumenMedico}
              textError={["Debe escribir un resumen médico"]}
              vacio={true}
            />
          </div>
          {loaderResumenMedico ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress size={40} sx={{ color: "#148f9f" }} />
            </div>
          ) : (
            <div style={{ display: "flex", justifyContent: "space-around", gap: "1rem", alignItems: "center", }}>
              <Button style={{ width: "35%", }}
                className="GeneralBotonWhite" type="button"
                onClick={() => {
                  vistaPreviaResumenMedico();
                }}
              >
                Vista previa
              </Button>
              <Button
                className="GeneralBoton" style={{ width: "35%", color: "white" }}
                onClick={() => {
                  if (!!ResumenMedico) {
                    ClosedResumenMedico(true);
                    addResumenMedico();
                  } else {
                    setMensajeResumenMedico("false");
                    setOpen(true);
                    setTypeMessage("warning");
                    setMessage("Faltan campos por completar");
                  }
                }}
              >
                Crear
              </Button>
            </div>
          )}
        </div>
      </ModalResumenMedico>

      {ModalEnviarReceta}

      <SnackBar
        setOpen={setOpen}
        open={open}
        typeMessage={typeMessage}
        message={message}
      />

    </Stack >
  );
}

export default Consultas;
