/*Importando Librerias*/
import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
/*Importando estilos*/
import './App.scss';
import AppRouter from './routers/AppRouter';
import ReactGA from "react-ga4";
import { Stack } from '@mui/material';
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { useDispatch, useSelector } from 'react-redux';
import { showSnackbar } from './redux/snackbarSlice';
import { gAnalytics_ID } from './services/apiUrl';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
// import "./firebase"

function App() {
  const dispatch = useDispatch();
  // let TRACKING_ID = ""
  // if (document.domain === "dev.ygia.app") {
  //   //   TRACKING_ID = "G-BNZP1BPTMT";
  //   ReactGA.initialize("G-BNZP1BPTMT");
  // } else if (document.domain === "ygia.app") {
  //   // TRACKING_ID = "G-VBVQQV9S6M";
  //   ReactGA.initialize("G-VBVQQV9S6M");
  // }
  // const PRO_TRACKING_ID = "G-VBVQQV9S6M";
  // const DEV_TRACKING_ID = "G-BNZP1BPTMT";
  // ReactGA.initialize(document.domain === "dev.ygia.app" ? DEV_TRACKING_ID : PRO_TRACKING_ID);
  ReactGA.initialize(gAnalytics_ID);

  const variable = useSelector((state) => state.snackbar);
  useEffect(() => {
    if (document.domain !== "localhost") {
      ReactGA.send({
        hitType: "pageview",
        page: window.location.pathname + window.location.search,
        title: "App"
      });
    }
    //       navigator.userAgent.match(/Android/i)
    //  || navigator.userAgent.match(/webOS/i)
    //  || navigator.userAgent.match(/iPhone/i)
    //  || navigator.userAgent.match(/iPad/i)
    //  || navigator.userAgent.match(/iPod/i)
    //  || navigator.userAgent.match(/BlackBerry/i)
    //  || navigator.userAgent.match(/Windows Phone/i) ||

  }, []);


  return (
    <div className='App'>

      <Stack>
        <AppRouter />
        <Snackbar
          open={variable.show}
          autoHideDuration={6000}
          onClose={() => dispatch(showSnackbar(false))}
        >
          <Alert
            onClose={() => dispatch(showSnackbar(false))}
            severity={variable.type}
            sx={{ width: "100%" }}
          >
            {variable.text}
          </Alert>
        </Snackbar>
      </Stack>
    </div>

  );
}

export default App;

