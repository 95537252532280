import React from "react";
// import BigCalendar from "react-big-calendar";
import { Calendar, dayjsLocalizer } from 'react-big-calendar'
import dayjs from 'dayjs'
import "dayjs/locale/es";
import "react-big-calendar/lib/css/react-big-calendar.css";

const localizer = dayjsLocalizer(dayjs)

export default function CalendarPage({ detailCita, CloseModalcitaCalendar }) {

    const myEventsList = [{
        title: "Cita 1",
        start: new Date('2023-06-27 10:00:00'),
        end: new Date('2023-06-27 10:30:00'),
        id: "873"
    },
    {
        title: "Cita 2",
        start: new Date('2023-06-27 10:00:00'),
        end: new Date('2023-06-27 10:30:00'),
        id: "738"
    },
    {
        title: "Cita 3",
        start: new Date('2023-06-27 10:00:00'),
        end: new Date('2023-06-27 10:30:00'),
        id: "877"
    },
    {
        title: "Cita 4",
        start: new Date('2023-06-27 10:30:00'),
        end: new Date('2023-06-27 11:00:00'),
        id: "874"
    }
    ]
    return (
        <div>
            <Calendar
                localizer={localizer}
                events={myEventsList}
                startAccessor="start"
                endAccessor="end"
                onSelectEvent={(e) => {
                    CloseModalcitaCalendar(true);
                    detailCita(e.id, 1);
                    // console.log("EVENTO SELECCIONADO");
                    // console.log(e);
                }}
                style={{ height: "500px" }}
                culture="es"
                messages={{
                    next: "siguiente",
                    previous: "anterior",
                    today: "Hoy",
                    month: "Mes",
                    week: "Semana",
                    day: "Día",
                    date:"Fecha",
                    time:"Hora",
                    event:"Evento"
                }}
            />
        </div>
    );

}
