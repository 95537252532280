import React, { useEffect } from "react";
import { Avatar, Button, Empty, Image, Input, InputNumber, Row, Select, Table } from "antd";
import {
  Label,
  LeyendaError,
} from "../componentes/GeneralComponents/Formularios2Grids";
import ButtonAntd from "../componentes/GeneralComponents/ButtonAntd";
import { useState } from "react";
import { Col } from "antd";
// importando style
import "./styles/PuntoDeVenta.scss";
import "../App.scss"
// Importando componente cards
import PuntoDeVentaCard from "../componentes/Punto-de-Venta/PuntoDeVentaCard";
// Importando iconos
import Agregar from "../Img/Iconos/AgregarBlanco.svg";
import filtro from "../Img/Iconos/Filtro.svg";
import EliminarIco from "../Img/Iconos/Basura.svg";
import CloseIcon from '@mui/icons-material/Close';

// importando modales
import { UseModal } from "../hooks/UseModal";
import Modal from "../componentes/GeneralComponents/ModalDetalle";
import ModalSelectContact from "../componentes/GeneralComponents/ModalDetalle";
import ModalFiltro from "../componentes/GeneralComponents/ModalFiltro";

import AddLista from "../componentes/GeneralComponents/ModListDeContactos";

import { Loader } from "../componentes/GeneralComponents/Loader";
import LoaderModals from "../componentes/GeneralComponents/Loader_modals";

// Services
import {
  getCargos_request,
  detailCobro_request,
  addCobroEfectivo_request,
  addCobroTarjeta_request,
  cancelarCobro_request,
  payCobro_request,
  getCobrosFilter_request,
} from "../services/puntoVenta_services";
import { getContacts_request } from "../services/contactos_services";

import Column from "antd/es/table/Column";
import IconosDeSitio from "../componentes/IconosDeSitio";
import TextArea from "antd/es/input/TextArea";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { Cancel } from "@mui/icons-material";
import { Content } from "antd/es/layout/layout";
import NavbarNuevo from "../componentes/GeneralComponents/Navbar_Nuevo";
import InputAntd from "../componentes/GeneralComponents/InputAntd";
import TextAreaAntd from "../componentes/GeneralComponents/TextAreaAntd";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function PuntoDeVenta() {
  // ******** VARIABLES ********
  const styleDet = {
    height: "clac(100vh - 52px)",
    width: "clac(100vw - 52px)",
    minWidth: "40vw",
    minHeight: "40vh",
    margin: "0 2%",
  };
  const styleDetAdd = {
    height: "clac(100vh - 52px)",
    width: "clac(100vw - 52px)",
    minWidth: "40vw",
    minHeight: "40vh",
    margin: "0 2%",
  };

  const columns = [
    {
      title: "Cantidad",
      dataIndex: "cantidad",
      key: "cantidad",
    },
    {
      title: "Concepto",
      dataIndex: "concepto",
      key: "concepto",
    },
    {
      title: "Descuento",
      dataIndex: "descuento",
      key: "descuento",
    },
    {
      title: "Precio unitario",
      dataIndex: "pUnitario",
      key: "pUnitario",
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
    },
  ];

  const formateadorMoneda = new Intl.NumberFormat("en", {
    style: "currency",
    currency: "MXN",
  });

  // ******** USE STATES ********

  const [ContinuacionCobro, setContinuacionCobro] = useState(0);
  const [PagarCobro, setPagarCobro] = useState(0);
  // usestate modales
  const [isOpenFiltroModal, OpenFiltroModal, closeFiltroModal] =
    UseModal(false);
  const [isOpenAddModal, OpenAddModal, closeAddModal] = UseModal(false);
  const [isOpenDetalle, OpenDetalleModal, closeDetalleModal] = UseModal(false);
  const [isOpenSelecContacto, OpenSelecContacto, closeSelecContacto] =
    UseModal(false);
  const [isOpenPagarModal, OpenPagarModal, closePagarModal] = UseModal(false);

  const [MenuDesp, setMenuDesp] = useState(null);

  // MANEJA DE MENSAJES
  const [open, setOpen] = useState(false);
  const [typeMessage, setTypeMessage] = useState("");
  const [message, setMessage] = useState("");

  const [CobrosList, setCobrosList] = useState([]);
  const [CobrosListFilter, setCobrosListFilter] = useState([]);
  const [IdCobro, setIdCobro] = useState("");

  const [DetailCobro, setDetailCobro] = useState("");
  const [RowsTable, setRowsTable] = useState([]);

  const [loader, setLoader] = useState(false);
  const [loaderModal, setLoaderModal] = useState(false);

  const [ContactList, setContactList] = useState([]);
  const [ContactListFilter, setContactListFilter] = useState([]);
  const [DatosContact, setDatosContact] = useState({
    nombre: "",
    imagen: "",
    idContacto: "",
    total: 0,
    idCobro: "",
  });

  // Form add cobro
  const [Concepto, setConcepto] = useState(null);
  const [NombreProducto, setNombreProducto] = useState("");
  const [Cantidad, setCantidad] = useState("");
  const [Precio, setPrecio] = useState(null);
  const [DescripcionProducto, setDescripcionProducto] = useState("");
  const [Descuento, setDescuento] = useState(null);
  // Lista de productos
  const [ProductosList, setProductosList] = useState([]);

  const [MetodoPago, setMetodoPago] = useState(null);

  const [Total, setTotal] = useState(0);
  const [MontoRecibido, setMontoRecibido] = useState(null);
  const [Cambio, setCambio] = useState(0);

  const [ValidacionMontoRecibido, setValidacionMontoRecibido] = useState("");

  const [FechaInicio, setFechaInicio] = useState("");
  const [FechaFin, setFechaFin] = useState("");
  const [EstadoFiltro, setEstadoFiltro] = useState("Todos");
  const [BotonCancelFiltro, setBotonCancelFiltro] = useState(0);
  const [FiltroActive, setFiltroActive] = useState(false);

  const [MensajeProductosList, setMensajeProductosList] = useState("true");
  const [MensajeConcepto, setMensajeConcepto] = useState("true");
  const [MensajeNombreProducto, setMensajeNombreProducto] = useState("true");
  const [MensajeDescripcionProducto, setMensajeDescripcionProducto] =
    useState("true");
  const [MensajePrecio, setMensajePrecio] = useState("true");
  const [MensajeCantidad, setMensajeCantidad] = useState("true");
  const [MensajeMetodoPago, setMensajeMetodoPago] = useState("true");
  const [MensajeMontoRecibido, setMensajeMontoRecibido] = useState("true");
  const [MensajeFechaInicio, setMensajeFechaInicio] = useState("true");
  const [MensajeFechaFin, setMensajeFechaFin] = useState("true");

  // ******** FUNCIONES ********

  async function getCobros() {
    setLoader(true);
    setBotonCancelFiltro(0);
    let response = await getCargos_request();
    if (response.ok) {
      // console.log(response.data);
      setCobrosList(response.data);
      setCobrosListFilter(response.data);
    } else {
      setCobrosList([]);
      setCobrosListFilter([]);
      setOpen(true);
      setTypeMessage("error");
      setMessage(response.mensaje);
    }
    setLoader(false);
    return;
  }

  async function getCobrosHoy() {
    setLoader(true);
    let data = new FormData();
    data.append("Estado", "Todos");

    // console.log(type);

    let response = await getCobrosFilter_request(1, data);
    if (response.ok) {
      setCobrosList(response.data);
      setCobrosListFilter(response.data);
    } else {
      setCobrosList([]);
      setCobrosListFilter([]);
      setOpen(true);
      setTypeMessage("error");
      if (response.mensaje.includes("Sin cargos")) {
        setMessage(`${response.mensaje} hoy`);
      } else {
        setMessage(response.mensaje);

      }
    }

    setLoader(false);
    return;
  }

  async function getCobrosFilter(type) {
    setLoader(true);
    if (type === 4) {
      if (!!!FechaInicio || !!!FechaFin) {
        if (!!!FechaInicio) {
          setMensajeFechaInicio("false");
        }
        if (!!!FechaFin) {
          setMensajeFechaFin("false");
        }
        setOpen(true);
        setTypeMessage("warning");
        setMessage("Faltan campos por completar");
      } else {
        let data = new FormData();
        data.append("Estado", EstadoFiltro);
        data.append("FechaInicio", FechaInicio);
        data.append("FechaFin", FechaFin);

        // console.log(type);

        let response = await getCobrosFilter_request(type, data);
        if (response.ok) {
          // console.log(response.data);
          // return;
          setCobrosList(response.data);
          setCobrosListFilter(response.data);
          setBotonCancelFiltro(1);
          closeFiltroModal(true);
          setFiltroActive(true);
        } else {
          setCobrosList([]);
          setCobrosListFilter([]);
          setOpen(true);
          setTypeMessage("error");
          setMessage(response.mensaje);
        }
      }
    } else {
      let data = new FormData();
      data.append("Estado", EstadoFiltro);

      // console.log(type);

      let response = await getCobrosFilter_request(type, data);
      if (response.ok) {
        // console.log(response.data);
        // return;
        setCobrosList(response.data);
        setCobrosListFilter(response.data);
        setBotonCancelFiltro(1);
        closeFiltroModal(true);
        setFiltroActive(true);
      } else {
        setCobrosList([]);
        setCobrosListFilter([]);
        setOpen(true);
        setTypeMessage("error");
        setMessage(response.mensaje);
      }
    }
    setLoader(false);
    return;
  }

  function filterList(searchText) {
    var chars = {
      á: "a",
      é: "e",
      í: "i",
      ó: "o",
      ú: "u",
      à: "a",
      è: "e",
      ì: "i",
      ò: "o",
      ù: "u",
      ñ: "n",
      Á: "A",
      É: "E",
      Í: "I",
      Ó: "O",
      Ú: "U",
      À: "A",
      È: "E",
      Ì: "I",
      Ò: "O",
      Ù: "U",
      Ñ: "N",
    };

    // Función para normalizar el texto reemplazando caracteres especiales
    function normalize(text) {
      return text.replace(/[áàéèíìóòúùñÁÉÍÓÚÀÈÌÒÙÑ]/g, (match) => chars[match] || match);
    }

    // Normalizar el texto de búsqueda
    const normalizedSearchText = normalize(searchText.target.value.toLowerCase());

    // Filtrar la lista de contactos
    const filter = CobrosListFilter.filter((cobro) => {
      const normalizedContacto = normalize(cobro.Contacto.toLowerCase());
      const normalizedFolio = normalize(cobro.Folio.toLowerCase());

      // Buscar coincidencia en cualquiera de los campos
      return (
        normalizedContacto.includes(normalizedSearchText) ||
        normalizedFolio.includes(normalizedSearchText)
      );
    });

    setCobrosList(filter);
  }

  async function detailCobro(idCobro) {
    setLoaderModal(true);
    let response = await detailCobro_request(idCobro);
    if (response.ok) {
      // console.log(response.data);
      setDetailCobro(response.data.Cobro);
      let partidas = [];
      response.data.Partidas.forEach((element, index) => {
        // console.log(element.Descuento);
        partidas.push({
          key: index + 1,
          cantidad: element.Cantidad,
          concepto: element.Concepto,
          descuento: !!element.Descuento ? element.Descuento : "0%",
          pUnitario: `$${element.Unitario}`,
          total: `$${element.Total}`,
        });
      });
      setRowsTable(partidas);
    } else {
      setOpen(true);
      setTypeMessage("error");
      setMessage(response.mensaje);
    }
    setLoaderModal(false);
    return;
  }

  async function getContactos() {
    let response = await getContacts_request();
    if (response.ok) {
      // console.log(response.data);
      let array = [];
      response.data.forEach((element) => {
        if (element.Type !== 1 && element.Type !== 4) {
          array.push({
            usuario: `${element.Nombre} ${element.ApellidoPaterno} ${element.ApellidoMaterno}`,
            imagen: `${!!element.Imagen_url ? element.Imagen_url : ""}`,
            id: `${element.IdContacto}`,
          });
        }
      });
      setContactList(array);
      setContactListFilter(array);
    } else {
    }
  }

  function filterContacts(searchText) {
    var chars = {
      á: "a",
      é: "e",
      í: "i",
      ó: "o",
      ú: "u",
      à: "a",
      è: "e",
      ì: "i",
      ò: "o",
      ù: "u",
      ñ: "n",
      Á: "A",
      É: "E",
      Í: "I",
      Ó: "O",
      Ú: "U",
      À: "A",
      È: "E",
      Ì: "I",
      Ò: "O",
      Ù: "U",
      Ñ: "N",
    };

    // Función para normalizar el texto reemplazando caracteres especiales
    function normalize(text) {
      return text.replace(/[áàéèíìóòúùñÁÉÍÓÚÀÈÌÒÙÑ]/g, (match) => chars[match] || match);
    }

    // Normalizar el texto de búsqueda
    const normalizedSearchText = normalize(searchText.target.value.toLowerCase());

    // Filtrar la lista de contactos
    const filter = ContactListFilter.filter((contacto) => {
      const normalizedUser = normalize(contacto.usuario.toLowerCase());

      // Buscar coincidencia en cualquiera de los campos
      return (
        normalizedUser.includes(normalizedSearchText)
      );
    });

    setContactList(filter);
  }

  async function addCobroEfectivo(opcion) {
    setLoaderModal(true);
    // console.log(MetodoPago);
    // return;
    if (ProductosList.length > 0 && !!MetodoPago) {
      if (opcion === 2 && !!MontoRecibido) {
        let data = new FormData();
        data.append("Monto", Total);
        data.append("MetodoPago", "Efectivo");
        ProductosList.forEach((element, index) => {
          data.append(`Concepto[${index}][Concepto]`, element.Concepto);
          data.append(`Concepto[${index}][Cantidad]`, element.Cantidad);
          data.append(`Concepto[${index}][Tipo]`, element.Tipo);
          data.append(`Concepto[${index}][Precio]`, element.Precio);
          data.append(`Concepto[${index}][Descuento]`, element.Descuento);
          data.append(`Concepto[${index}][Descripcion]`, element.Descripcion);
        });

        let response = await addCobroEfectivo_request(
          data,
          DatosContact.idContacto
        );
        if (response.ok) {
          setOpen(true);
          setTypeMessage("success");
          setMessage("Cobro realizado");
          // getCobros();
          getCobrosHoy();
          // console.log(response.data.Cambio);
          if (response.data.Cambio > 0) {
            setContinuacionCobro(2);
            setCambio(response.data.Cambio.toFixed(2));
          } else {
            closeAddModal(true);
            cleanInputsAdd();
          }
        } else {
          setOpen(true);
          setTypeMessage("error");
          setMessage(response.mensaje);
        }
      } else {
        if (opcion === 1) {
          setContinuacionCobro(1);
        } else {
          setValidacionMontoRecibido("Debe escribir una cantidad");
          setMensajeMontoRecibido("false");
        }
      }
    } else {
      if (ProductosList.length === 0) {
        setMensajeProductosList("false");
      }
      if (!!!MetodoPago) {
        setMensajeMetodoPago("false");
      }
    }
    setLoaderModal(false);
    return;
  }

  async function addCobroTarjeta(opcion) {
    setLoaderModal(true);
    if (ProductosList.length > 0 && !!MetodoPago) {
      if (opcion === 2) {
        let data = new FormData();
        data.append("Monto", Total);
        data.append(
          "TipoTarjeta",
          MetodoPago === "2" ? "Tarjeta de crédito" : "Tarjeta de débito"
        );
        ProductosList.forEach((element, index) => {
          data.append(`Concepto[${index}][Concepto]`, element.Concepto);
          data.append(`Concepto[${index}][Cantidad]`, element.Cantidad);
          data.append(`Concepto[${index}][Tipo]`, element.Tipo);
          data.append(`Concepto[${index}][Precio]`, element.Precio);
          data.append(`Concepto[${index}][Descuento]`, element.Descuento);
          data.append(`Concepto[${index}][Descripcion]`, element.Descripcion);
        });
        let response = await addCobroTarjeta_request(
          data,
          DatosContact.idContacto
        );
        if (response.ok) {
          setOpen(true);
          setTypeMessage("success");
          setMessage("Cobro realizado");
          // getCobros();
          getCobrosHoy();
          closeAddModal(true);
          cleanInputsAdd();
        } else {
          setOpen(true);
          setTypeMessage("error");
          setMessage(response.mensaje);
        }
      } else {
        setContinuacionCobro(3);
      }
    } else {
      if (ProductosList.length === 0) {
        setMensajeProductosList("false");
      }
      if (!!!MetodoPago) {
        setMensajeMetodoPago("false");
      }
    }
    setLoaderModal(false);
    return;
  }

  async function addCobroTransfer() {
    setLoaderModal(true);
    let data = new FormData();
    data.append("Monto");
    data.append("MetodoPago");
    data.append("");
    let response = await addCobroEfectivo_request(data);
    if (response.ok) {
      setOpen(true);
      setTypeMessage("success");
      setMessage("Cobro realizado");
      getCobrosHoy();
    } else {
      setOpen(true);
      setTypeMessage("error");
      setMessage(response.mensaje);
    }
    setLoaderModal(false);
    return;
  }

  async function cancelarCobro() {
    setLoader(true);
    let response = await cancelarCobro_request(IdCobro);
    if (response.ok) {
      setOpen(true);
      setTypeMessage("success");
      setMessage("Cobro cancelado");
      // getCobros();
      getCobrosHoy();
    } else {
      setOpen(true);
      setTypeMessage("error");
      setMessage(response.mensaje);
    }
    setLoader(false);
    return;
  }

  async function pagarCobro() {
    setLoaderModal(true);
    let data = new FormData();
    data.append("Monto", DatosContact.total);
    data.append("MetodoPago", MetodoPago);
    let response = await payCobro_request(data, DatosContact.idCobro);
    if (response.ok) {
      // console.log(response.data);
      setOpen(true);
      setTypeMessage("success");
      setMessage("Cobro pagado");
      // getCobros();
      getCobrosHoy();
      let cambio_temp = MontoRecibido - DatosContact.total;
      if (cambio_temp > 0) {
        setCambio(cambio_temp);
        setPagarCobro(2);
      } else {
        closePagarModal(true);
        cleanInputsAdd();
      }
    } else {
      setOpen(true);
      setTypeMessage("error");
      setMessage(response.mensaje);
    }
    setLoaderModal(false);
    return;
  }

  function addProducto() {
    // console.log(NombreProducto);
    // console.log(CantidadProducto);
    // console.log(DescripcionUso);

    if (
      !!Concepto &&
      !!NombreProducto &&
      !!DescripcionProducto &&
      !!Precio &&
      Precio > 0 &&
      !!Cantidad
    ) {
      // setOpen(true);
      // setTypeMessage("success");
      // setMessage("Agregado");
      let array = ProductosList;
      if (array.length > 0) {
        array.push({
          index: ProductosList[ProductosList.length - 1].index + 1,
          Tipo: Concepto,
          Concepto: NombreProducto,
          Cantidad: Cantidad,
          Precio: Precio,
          Descuento: !!Descuento ? Descuento : 0,
          Descripcion: DescripcionProducto,
          Total:
            !!Descuento && Descuento !== 0
              ? Precio.toFixed(2) * Cantidad -
              (Descuento / 100) * (Precio.toFixed(2) * Cantidad)
              : Precio.toFixed(2) * Cantidad,
        });
      } else {
        array.push({
          index: 1,
          Tipo: Concepto,
          Concepto: NombreProducto,
          Cantidad: Cantidad,
          Precio: Precio,
          Descuento: !!Descuento ? Descuento : 0,
          Descripcion: DescripcionProducto,
          Total:
            !!Descuento && Descuento !== 0
              ? Precio * Cantidad -
              (Descuento / 100) * (Precio.toFixed(2) * Cantidad)
              : Precio.toFixed(2) * Cantidad,
        });
      }
      if (!!Descuento && Descuento !== 0) {
        setTotal(
          Total +
          (Precio.toFixed(2) * Cantidad -
            (Descuento / 100) * (Precio.toFixed(2) * Cantidad))
        );
      } else {
        setTotal(Total + Precio.toFixed(2) * Cantidad);
      }

      // console.log(array);

      setProductosList(array);

      setConcepto(null);
      setNombreProducto("");
      setCantidad(null);
      setPrecio(null);
      setDescuento(null);
      setDescripcionProducto("");
      setMensajeProductosList("true");
    } else {
      if (!!!Concepto) {
        setMensajeConcepto("false");
      }
      if (!!!NombreProducto) {
        setMensajeNombreProducto("false");
      }
      if (!!!DescripcionProducto) {
        setMensajeDescripcionProducto("false");
      }
      if (!!!Precio || Precio === 0) {
        setMensajePrecio("false");
      }
      if (!!!Cantidad) {
        setMensajeCantidad("false");
      }
      setOpen(true);
      setTypeMessage("warning");
      setMessage("Faltan campos por completar");
    }

    return;
  }

  function eliminarProducto(posicion) {
    // console.log(posicion);
    let array = ProductosList;

    let arrayDos = [];

    let arrayTres = [];

    arrayDos = array.filter((element) => {
      if (element.index === posicion) {
        // console.log(element.Total);
        setTotal(Total - element.Total);
      }

      return element.index !== posicion;
    });
    // console.log(arrayDos);
    arrayDos.forEach((element, index) => {
      arrayTres.push({
        index: index + 1,
        Tipo: element.Tipo,
        Concepto: element.Concepto,
        Cantidad: element.Cantidad,
        Precio: element.Precio,
        Descuento: element.Descuento,
        Descripcion: element.DescripcionProducto,
        Total: element.Total,
      });
    });

    if (arrayDos.length === 0) {
      setTotal(0);
    }

    setProductosList(arrayTres);
  }

  function cleanInputsAdd() {
    setConcepto(null);
    setNombreProducto("");
    setDescripcionProducto("");
    setPrecio(null);
    setCantidad(null);
    setDescuento(null);
    setProductosList([]);
    setTotal(0);
    setMetodoPago(null);
    setDatosContact({
      nombre: "",
      imagen: "",
      idContacto: "",
      total: 0,
      idCobro: "",
    });
    setContinuacionCobro(0);
    setPagarCobro(0);
    setMontoRecibido(0);
    setCambio(0);
    setMensajeProductosList("true");
    setMensajeConcepto("true");
    setMensajeNombreProducto("true");
    setMensajeDescripcionProducto("true");
    setMensajePrecio("true");
    setMensajeCantidad("true");
    setMensajeMetodoPago("true");
    setMensajeMontoRecibido("true");
  }

  useEffect(() => {
    // getCobros();
    getCobrosHoy();
    getContactos();
    return;
  }, []);

  return (
    <Stack>
      {/* <div className="GenaralScroll"> */}

      {/* <div className="TablaPuntoDeventa"> */}

      <NavbarNuevo
        mensajesError={{
          setOpen: setOpen,
          setTypeMessage: setTypeMessage,
          setMessage: setMessage,
        }}
        modulo={"PuntoDeVenta"}
        searchBar={
          <div className="ContenedorCabezeraMetodos">
            <div className="CabezeraMetodos">
              <b>Punto de venta</b>

              <ButtonAntd
                tipo={1}
                style={{ width: "100px" }}
                onClick={OpenSelecContacto}
                children={
                  <Row style={{ gap: ".5rem", color: "white", justifyContent: "space-between", alignItems: "center" }}>
                    Nueva
                    <img style={{ width: "12px" }} src={Agregar} alt="" />
                  </Row>
                }
              />
            </div>

            <div className="FlexAliniado">
              <Input
                placeholder="Buscar por nombre o folio"
                type="text"
                className="inputblue"
                style={{ width: "45%" }}
                onChange={filterList}
              />
              <div style={{ display: "flex", width: "100%" }}>
                <img
                  onClick={() => {
                    OpenFiltroModal(true);
                    setMensajeFechaInicio("true");
                    setMensajeFechaFin("true");
                  }}
                  style={{ width: "30px", cursor: "pointer" }}
                  src={filtro}
                  alt=""
                />
                {FiltroActive && (
                  <Button
                    style={{
                      background: "white",
                      color: "#148F9F",
                      border: "0",
                      height: "30px",
                      padding: "0 1%",
                      margin: ".8% 0",
                      // marginLeft: "3%",
                      marginRight: "0",
                    }}
                    onClick={() => {
                      setFiltroActive(false);
                      setEstadoFiltro("Todos");
                      setFechaInicio("");
                      setFechaFin("");
                      // getCobros();
                      getCobrosHoy();
                    }}
                  >
                    <Cancel sx={{ color: "#148f9f" }} />
                    Filtro
                  </Button>
                )}
              </div>
              {/* {BotonCancelFiltro === 1 && (
                <div className="BotonPv1">
                <Button type="button" onClick={() => {
                  getCargos();
                }}>Cancelar filtro</Button>
              </div>
              )} */}
            </div>
          </div>
        }
      />
      <Content
        className="site-layout"
        style={{
          padding: ".5% 3%",
          width: "100%",
          overflow: "initial",
        }}
      >
        {loader ? (
          <Loader />
        ) : (

          <PuntoDeVentaCard
            setMenuDesp={setMenuDesp}
            OpenDetalleModal={OpenDetalleModal}
            CobrosList={CobrosList}
            detailCobro={detailCobro}
            setIdCobro={setIdCobro}
            setDatosContact={setDatosContact}
            OpenPagarModal={OpenPagarModal}
            OpenAddModal={OpenAddModal}
            cancelarCobro={cancelarCobro}
          />
        )}
      </Content>

      {/* </div> */}
      {/* </div> */}

      {/* Modal de filtro */}
      <ModalFiltro
        isOpen={isOpenFiltroModal}
        closeModal={closeFiltroModal}
        titulos={["Ordena tus notas de venta", "Hoy", "Ayer", "Semana"]}
        OptionsSelect={[
          { label: "Todos", value: "Todos" },
          { label: "Pagados", value: "Pagados" },
          { label: "Pendientes", value: "Pendientes" },
          { label: "Cancelados", value: "Cancelados" },
        ]}
        functions={[getCobrosHoy, getCobrosFilter]}
        statesVariables={{
          estadoFiltro: setEstadoFiltro,
          fechaInicio: setFechaInicio,
          mensajeFechaInicio: setMensajeFechaInicio,
          fechaFin: setFechaFin,
          mensajeFechaFin: setMensajeFechaFin,
        }}
        variables={{
          estadoFiltro: EstadoFiltro,
          fechaInicio: FechaInicio,
          mensajeFechaInicio: MensajeFechaInicio,
          fechaFin: FechaFin,
          mensajeFechaFin: MensajeFechaFin,
        }}
      />
      {/* Modal de detalle */}
      <Modal
        isOpen={isOpenDetalle}
        closeModal={closeDetalleModal}
        styleDet={styleDet}
        optionClose={true}
      >
        <div className="FlexAliniado CabezeraModal" style={{ color: "white" }}>
          <span>Información de cobro</span>
          <CloseIcon style={{ cursor: "pointer", fontSize: 20, }}
            onClick={() => {
              closeDetalleModal(true);
              setDetailCobro("");
              setRowsTable([]);
            }} />
        </div>
        {loaderModal ? (

          <LoaderModals className="GeneralModalPV" />

        ) : (
          <div className="GeneralModalPV">
            <div style={{ textAlign: "center" }}>
              <div>
                <img
                  className="ImgPerfilPV"
                  src={
                    !!DetailCobro.Imagen_Url
                      ? `https://${DetailCobro.Imagen_Url}`
                      : IconosDeSitio[0]
                  }
                  alt=""
                />
              </div>
              <div>
                <span style={{ fontWeight: "700" }}>{DetailCobro.Medico}</span>
              </div>
              <p style={{ margin: "1% 0" }}>
                Atendido por: {DetailCobro.Medico}
              </p>
            </div>
            <hr />
            {/* <div style={{ borderTop: "1.5px solid #424242", padding: "0 2%" }}> */}
            {/* <div
              style={{
                borderBottom: "1px solid #d7d7d7",
                display: "flex",
                justifyContent: "space-between",
                
              }}
            >
              <span style={{ fontWeight: "700" }}>Estado</span>
              <span>{DetailCobro.Estado}</span>
            </div> */}
            <Row style={{ justifyContent: "space-between", height: "5%" }}>
              <span style={{ fontWeight: "700" }}>Estado</span>
              <span>{DetailCobro.Estado}</span>
            </Row>
            <hr />
            <Row style={{ justifyContent: "space-between", height: "5%" }}>
              <span style={{ fontWeight: "700" }}>Forma de pago</span>
              <span>{DetailCobro.FormaPago}</span>
            </Row>
            {/* <div
              style={{
                borderBottom: "1px solid #d7d7d7",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <span style={{ fontWeight: "700" }}>Forma de pago</span>
              <span>{DetailCobro.FormaPago}</span>
            </div> */}
            {/* </div> */}
            <div
              style={{
                textAlign: "center",
                marginTop: "3%",
                paddingBottom: "1%",
                borderBottom: "1.5px solid #424242",
              }}
            >
              <span style={{ fontWeight: "700" }}>{DetailCobro.Paciente}</span>
            </div>
            <div style={{ padding: "0 2%", textAlign: "center" }}>
              <div className="TablaDeDetalle" >
                {/* <table style={{ width: "100%" }}>
                <tr>
                  <th>Cant</th>
                  <th>Concepto</th> <th>desc</th> <th>P.Unit.</th>
                  <th>Sub total</th>
                </tr>
                <tr>
                  <td>1</td>
                  <td>
                    <TextArea
                      className="inputblue"
                      style={{ color: "#424242", padding: "2%", overflow: "hidden", border:"0" }}
                      value={
                        "nextddddddddddddddddddddddddddddddddddddddddddddddd"
                      }
                      name=""
                      id=""
                      cols="10"
                      autoSize
                    ></TextArea>
                  </td>
                  <td>40%</td>
                  <td>$255</td>
                  <td>$255</td>
                </tr>
              </table> */}
                <Table columns={columns} dataSource={RowsTable}></Table>
              </div>
              <p className="margen1Pv">
                Total ${DetailCobro.Total !== undefined && DetailCobro.Total}{" "}
              </p>
              <p className="margen1Pv">
                Su Pago $
                {DetailCobro.MontoRecibido !== undefined &&
                  DetailCobro.MontoRecibido}{" "}
              </p>
              {DetailCobro.Cambio !== undefined && (
                <p className="margen1Pv">
                  Cambio $
                  {DetailCobro.Cambio !== undefined && DetailCobro.Cambio}{" "}
                </p>
              )}
              <div className="PieModalDetale">
                <p>Cobro</p>
                <p>
                  Folio: {DetailCobro.Folio !== undefined && DetailCobro.Folio}
                </p>
                <p>
                  Referencia:{" "}
                  {DetailCobro.Referencia !== undefined &&
                    DetailCobro.Referencia}
                </p>
                <p>
                  Fecha de elaboracion:{" "}
                  {DetailCobro.Expedido !== undefined && DetailCobro.Expedido}
                </p>
              </div>
            </div>
          </div>
        )}
      </Modal>
      {/* Modal seleccionar contacto */}
      <ModalSelectContact
        isOpen={isOpenSelecContacto}
        closeModal={closeSelecContacto}
        styleDet={styleDet}
        optionClose={true}
      >
        <div
          style={{
            background: "#148F9F",
            color: "white",
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            padding: "1% 2%",
          }}
        >
          <span>Nuevo cobro</span>
          <CloseIcon style={{ cursor: "pointer", fontSize: 20, }} onClick={closeSelecContacto} />

        </div>
        <div className="ModalSeleContacPv">
          <p>Nombre</p>
          <Input
            placeholder="Buscar..."
            type="search"
            style={{ width: "100%", marginBottom: "10px" }}
            onChange={filterContacts}
          />

          <div
            className="contCardModal"
            style={{ borderTop: "1px solid #d5d5d5" }}
          >
            <AddLista
              CloseModal={closeSelecContacto}
              OpenModal={OpenAddModal}
              funcionExtra={[3, setDatosContact]}
              Lista={ContactList}
            />
          </div>
        </div>
      </ModalSelectContact>
      {/* Modal de Agregar cobro*/}
      <Modal
        isOpen={isOpenAddModal}
        closeModal={closeAddModal}
        styleDet={styleDetAdd}
        optionClose={false}
      >
        <div className="FlexAliniado CabezeraModal" >
          <span>Nueva nota de venta</span>
          <CloseIcon
            style={{ cursor: "pointer", fontSize: 20, color: "white" }}
            onClick={() => {
              closeAddModal(true);
              cleanInputsAdd();
            }}
          />

        </div>
        {loaderModal ? (
          <LoaderModals />
        ) : (
          <>
            {ContinuacionCobro === 0 && (
              <div style={{ textAlign: "center", paddingTop: "3%" }}>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <Avatar
                    className="GeneralNoImage"
                    size={65}
                    style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                    src={
                      <Image
                        src={
                          !!DatosContact.nombre
                            ? `https://${DatosContact.imagen}`
                            : IconosDeSitio[0]
                        }
                        alt=""
                      />
                    }
                  />
                </div>
                <div>
                  <span style={{ fontWeight: "700" }}>
                    {DatosContact.nombre}
                  </span>
                </div>
              </div>
            )}
            {ContinuacionCobro === 0 && (
              <div className="GeneralModalPV2">
                <h4 style={{ marginBottom: "10px" }}>Datos del cobro</h4>
                <div style={{ marginBottom: "1%" }} >
                  <label htmlFor="">Concepto</label>
                </div>
                <Select
                  name="DatosCobro"
                  style={{ width: "100%" }}
                  id="select_concepto"
                  placeholder="Elige una opción"
                  options={[
                    { label: "Producto", value: "1" },
                    { label: "Servicio", value: "0" },
                  ]}
                  value={Concepto}
                  onSelect={(e) => {
                    setConcepto(e);
                    setMensajeConcepto("true");
                  }}
                />
                <LeyendaError
                  valido={MensajeConcepto}
                  style={{
                    display: MensajeConcepto === "false" ? "flex" : "none",
                  }}
                >
                  Debe seleccionar concepto
                </LeyendaError>

                <InputAntd
                  type="text"
                  placeholder="Nombre del servicio o producto"
                  label={"Nombre de servicio o producto"}
                  style={{ width: "100%" }}
                  value={NombreProducto}
                  setStateValue={setNombreProducto}
                  setStateError={setMensajeNombreProducto}
                  valueError={MensajeNombreProducto}
                  textError={["Debe escribir nombre de servicio o producto"]}
                  vacio={false}
                />
                <TextAreaAntd
                  type="text"
                  placeholder="Descripción de producto o servicio"
                  label={"Descripción de producto o servicio"}
                  style={{ width: "100%" }}
                  value={DescripcionProducto}
                  setStateValue={setDescripcionProducto}
                  setStateError={setMensajeDescripcionProducto}
                  valueError={MensajeDescripcionProducto}
                  textError={["Debe escribir una descripcion"]}
                  vacio={false}

                />


                <Row style={{ justifyContent: "space-between" }}>
                  <div style={{ width: "30%" }}>
                    <div>
                      <label htmlFor="">Precio</label>
                    </div>
                    <InputNumber
                      prefix="$"
                      value={Precio}
                      // type="number"
                      min={0}
                      placeholder="0.00"

                      style={{ width: "90%", marginTop: "1%" }}
                      formatter={(value) =>
                        `${value}`
                          // .replace(/\D/g, "")
                          // .replace(/([0-9])([0-9]{2})$/, '$1.$2')
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }
                      parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                      onChange={(e) => {
                        // console.log(e);
                        // console.log(formateadorMoneda.format(e.target.value));
                        // let precio = formateadorMoneda.format(e.target.value);
                        setPrecio(e);
                        // console.log(e);
                        if (!!e) {
                          setMensajePrecio("true");
                        } else {
                          setMensajePrecio("false");
                        }
                        // setPrecio(e.target.value.replace(/\D/g, "")
                        //   .replace(/([0-9])([0-9]{2})$/, '$1.$2')
                        //   .replace(/\B(?=(\d{7})+(?!\d)\.?)/g, ","));
                      }}
                    />
                    <LeyendaError
                      valido={MensajePrecio}
                      style={{
                        display: MensajePrecio === "false" ? "flex" : "none",
                      }}
                    >
                      Debe poner un precio
                    </LeyendaError>
                  </div>
                  <div style={{ width: "30%" }}>
                    <div>
                      <label htmlFor="">Cantidad</label>
                    </div>
                    <InputNumber
                      prefix="#"
                      value={Cantidad}
                      placeholder="0"

                      style={{ width: "90%", marginTop: "1%" }}
                      onChange={(e) => {
                        if (e !== 0) {
                          setCantidad(e);
                          setMensajeCantidad("true");
                        } else {
                          setMensajeCantidad("false");
                        }
                      }}
                    />
                    <LeyendaError
                      valido={MensajeCantidad}
                      style={{
                        display: MensajeCantidad === "false" ? "flex" : "none",
                      }}
                    >
                      Debe escribir una cantidad válida
                    </LeyendaError>
                  </div>
                  <div style={{ width: "30%" }}>
                    <div>
                      <label htmlFor="">Descuento</label>
                    </div>
                    <InputNumber
                      prefix="%"
                      value={Descuento}
                      placeholder="0"

                      style={{ width: "90%", marginTop: "1%" }}
                      min={0}
                      max={90}
                      formatter={(value) => `${value}`}
                      parser={(value) => value.replace("%", "")}
                      onChange={(e) => {

                        setDescuento(e);

                      }}
                    />
                  </div>
                </Row>

                <div>
                  <Button className="GeneralBoton"
                    type="button"
                    style={{ width: "100%" }}
                    onClick={() => {
                      addProducto();
                    }}
                  >
                    Agregar
                  </Button>
                </div>
                <h4 style={{ padding: "2% 0%" }}>Lista de conceptos</h4>
                {ProductosList.length === 0 ? (
                  <>
                    <Empty description={<span style={{ color: "black" }}> Aun no se agregan productos</span>} />
                    {/* <div style={{ textAlign: "center" }}>
                      Aun no se agregan productos
                    </div> */}
                    <LeyendaError
                      valido={MensajeProductosList}
                      style={{
                        display:
                          MensajeProductosList === "false" ? "flex" : "none",
                      }}
                    >
                      Debe agregar al menos un producto
                    </LeyendaError>
                  </>
                ) : (
                  ProductosList.map((producto) => (
                    <>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          padding: "1% 0",
                          borderBottom: "1px solid #d7d7d7",
                        }}
                      >
                        <div style={{ width: "70%" }}>
                          <p style={{ fontWeight: "500" }}>
                            {producto.Cantidad} {producto.Concepto}
                          </p>
                          <p>{producto.Descripcion}</p>
                          <p>Precio unitario: ${producto.Precio}</p>
                          <p>
                            subtotal: ${producto.Precio * producto.Cantidad}
                          </p>
                          <p>Descuento: {producto.Descuento}%</p>
                          <p style={{ fontWeight: "500" }}>
                            Total: ${producto.Total.toFixed(2)}
                          </p>
                        </div>
                        <div style={{ color: "#148f9f" }}>
                          <img
                            style={{
                              width: "20px",
                              height: "20px",
                              cursor: "pointer",
                            }}
                            src={EliminarIco}
                            alt=""
                            onClick={() => {
                              eliminarProducto(producto.index);
                            }}
                          />
                        </div>
                      </div>
                    </>
                  ))
                )}
                <h4 style={{ color: "gray" }}>Total = ${Total.toFixed(2)}</h4>
                <label htmlFor="">Metodos de pago</label>
                <Select

                  style={{ width: "100%", marginTop: "1%" }}
                  name=""
                  id=""
                  placeholder="Elige una ópcion"
                  options={[
                    { value: "1", label: "Efectivo" },
                    { value: "2", label: "Tarjeta de crédito" },
                    { value: "3", label: "Tarjeta de débito" },
                    { value: "4", label: "Transferencia" },
                  ]}
                  onSelect={(e) => {
                    setMetodoPago(e);
                  }}
                />
                <LeyendaError
                  valido={MensajeMetodoPago}
                  style={{
                    display: MensajeMetodoPago === "false" ? "flex" : "none",
                  }}
                >
                  Debe seleccionar un metodo de pago
                </LeyendaError>

                <Button className="GeneralBoton"
                  type="button" style={{ width: "100%" }}
                  onClick={() => {
                    // console.log(MetodoPago);
                    if (MetodoPago === "1") {
                      setMensajeMetodoPago("true");
                      addCobroEfectivo(1);
                    } else if (MetodoPago === "2" || MetodoPago === "3") {
                      setMensajeMetodoPago("true");
                      addCobroTarjeta(1);
                    } else if (MetodoPago === "4") {
                      setMensajeMetodoPago("true");
                      addCobroTransfer();
                    } else {
                      setMensajeMetodoPago("false");
                    }
                  }}
                >
                  Aplicar
                </Button>


                <Button className="GeneralBotonWhite"
                  type="button"
                  style={{ width: "100%" }}
                  onClick={() => {
                    closeAddModal(true);
                    cleanInputsAdd();
                  }}
                >
                  Cancelar
                </Button>

              </div>
            )}
            {ContinuacionCobro === 1 && (
              <div style={{ textAlign: "center", margin: "4% 2%" }}>
                <h4>Introduzca monto recibido para cobro en efectivo</h4>
                <div style={{ margin: "4% 0" }}>
                  <p>Total:</p>
                  <b>${Total.toFixed(2)}</b>
                </div>
                <div>
                  <div style={{ margin: "2% 0" }}>
                    <label htmlFor="">Monto recibido</label>
                  </div>
                  <InputNumber
                    prefix="$"
                    value={MontoRecibido}
                    // type="number"
                    placeholder="0.00"

                    style={{ width: "90%" }}
                    formatter={(value) =>
                      `${value}`
                        // .replace(/\D/g, "")
                        // .replace(/([0-9])([0-9]{2})$/, '$1.$2')
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                    onChange={(e) => {
                      // console.log(e);
                      // console.log(formateadorMoneda.format(e.target.value));
                      // let precio = formateadorMoneda.format(e.target.value);
                      setMontoRecibido(e);
                      if (e >= Total) {
                        setMensajeMontoRecibido("true");
                      } else if (e < Total) {
                        setValidacionMontoRecibido(
                          "El monto recibido debe ser mayor o igual al total"
                        );
                        setMensajeMontoRecibido("false");
                      }
                      // setPrecio(e.target.value.replace(/\D/g, "")
                      //   .replace(/([0-9])([0-9]{2})$/, '$1.$2')
                      //   .replace(/\B(?=(\d{7})+(?!\d)\.?)/g, ","));
                    }}
                  />
                  <p style={{ fontWeight: "500", color: "#424242" }}>Cambio: {MontoRecibido === "" || MontoRecibido <= Total.toFixed(2) ? (<span>$0.00</span>) : (<span>${MontoRecibido - Total.toFixed(2)}.00</span>)}</p>
                </div>
                <LeyendaError valido={MensajeMontoRecibido}>
                  {ValidacionMontoRecibido}
                </LeyendaError>
                <br />



                <Button
                  style={{ width: "90%" }}
                  className="GeneralBoton"
                  type="button"
                  onClick={() => {
                    addCobroEfectivo(2);
                  }}
                >
                  Aplicar
                </Button>


                <Button className="GeneralBotonWhite"
                  style={{ width: "90%" }}
                  type="button"
                  onClick={() => {
                    setContinuacionCobro(0);
                  }}
                >
                  Atrás
                </Button>
              </div>

            )}

            {ContinuacionCobro === 2 && (
              <div style={{ textAlign: "center", margin: "4% 2%" }}>
                <h4>¡Cobro exitoso!</h4>
                <div style={{ margin: "4% 0" }}>
                  <p>Cambio:</p>
                  <b>${Cambio.toFixed(2)}</b>
                </div>

                <Button className="GeneralBoton"
                  type="button"
                  onClick={() => {
                    setContinuacionCobro(0);
                    closeAddModal(true);
                    cleanInputsAdd();
                  }}
                >
                  Salir
                </Button>
              </div>

            )}

            {ContinuacionCobro === 3 && (
              <div style={{ textAlign: "center", margin: "4% 2%" }}>
                <h4>Cobro con tarjeta</h4>
                <div style={{ margin: "4% 0" }}>
                  <p>Total:</p>
                  <b>${Total.toFixed(2)}</b>
                </div>
                <p>
                  {" "}
                  Presiona "Finalizar cobro" una vez que se efectue el pago en
                  tu terminal PDV
                </p>
                <br />
                <div className="BotonPv1">
                  <Button
                    type="button"
                    onClick={() => {
                      addCobroTarjeta(2);
                    }}
                  >
                    Finalizar cobro
                  </Button>
                </div>
                <div className="BotonPv2">
                  <Button
                    onClick={() => {
                      setContinuacionCobro(0);
                    }}
                  >
                    Atrás
                  </Button>
                </div>
              </div>
            )}
          </>
        )}
      </Modal>

      {/* Modal de pagar cobro*/}
      <Modal
        isOpen={isOpenPagarModal}
        closeModal={closePagarModal}
        styleDet={styleDetAdd}
      >
        <div className="FlexAliniado CabezeraModal">
          <span> Pagar cobro</span>
          <CloseIcon
            style={{ cursor: "pointer", fontSize: 20, color: "white" }}
            onClick={() => {
              closePagarModal(true);
              cleanInputsAdd();
            }} />

        </div>
        {loaderModal ? (
          <LoaderModals className={"contenidomodalA"} />
        ) : (
          <>
            {PagarCobro === 0 && (
              <div className="contenidomodalA" style={{ textAlign: "left", padding: "3%" }}>
                <label htmlFor="">Metodos de pago</label>
                <br />
                <Select

                  style={{ width: "100%", height: "9%" }}
                  name=""
                  id=""
                  placeholder="Elige una ópcion"
                  options={[
                    { value: "1", label: "Efectivo" },
                    { value: "2", label: "Tarjeta de crédito" },
                    { value: "3", label: "Tarjeta de débito" },
                    { value: "4", label: "Transferencia" },
                  ]}
                  onSelect={(e) => {
                    setMetodoPago(e);
                  }}
                />
                <LeyendaError
                  valido={MensajeMetodoPago}
                  style={{
                    display: MensajeMetodoPago === "false" ? "flex" : "none",
                  }}
                >
                  Debe seleccionar un metodo de pago
                </LeyendaError>
                <br />
                <br />
                <div >
                  <Button
                    className="GeneralBoton" style={{ width: "100%" }}
                    type="button"
                    onClick={() => {
                      if (MetodoPago === "1") {
                        setMensajeMetodoPago("true");
                        setPagarCobro(1);
                      } else if (MetodoPago === "2" || MetodoPago === "3") {
                        setMensajeMetodoPago("true");
                        setPagarCobro(3);
                      } else if (MetodoPago === "4") {
                        setMensajeMetodoPago("true");
                        setPagarCobro(4);
                      } else {
                        setMensajeMetodoPago("false");
                      }
                    }}
                  >
                    Continuar
                  </Button>
                </div>
                <div>
                  <button className="GeneralBotonWhite " style={{ width: "100%", padding: "1.5%", borderRadius: ".4rem" }}
                    onClick={() => {
                      closePagarModal(true);
                      cleanInputsAdd();
                    }}
                  >
                    Cancelar
                  </button>
                </div>
              </div>
            )}
            {PagarCobro === 1 && (
              <div className="contenidomodalA" style={{ padding: "3%" }}>
                <h4>Introduzca monto recibido para cobro en efectivo</h4>
                <div style={{ margin: "4% 0" }}>
                  <p>Total:</p>
                  <b>${DatosContact.total.toFixed(2)}</b>
                </div>
                <div>
                  <div style={{ margin: "2% 0" }}>
                    <label htmlFor="">Monto recibido</label>
                  </div>
                  <InputNumber
                    prefix="$"
                    value={MontoRecibido}
                    // type="number"
                    placeholder="0.00"

                    style={{ width: "90%" }}
                    formatter={(value) =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                    onChange={(e) => {
                      // console.log(e);
                      setMontoRecibido(e);
                      if (e >= DatosContact.total) {
                        setMensajeMontoRecibido("true");
                      } else if (e < DatosContact.total) {
                        setValidacionMontoRecibido(
                          "El monto recibido debe ser mayor o igual al total"
                        );
                        setMensajeMontoRecibido("false");
                      }
                    }}
                  />

                  <p style={{ fontWeight: "500", color: "#424242" }}>Cambio: {MontoRecibido === "" || MontoRecibido <= DatosContact.total.toFixed(2) ? (<span>$0.00</span>) : (<span>${MontoRecibido - DatosContact.total.toFixed(2)}.00</span>)}</p>
                </div>
                <LeyendaError valido={MensajeMontoRecibido}>
                  {ValidacionMontoRecibido}
                </LeyendaError>
                <br />

                <Button className="GeneralBoton" style={{ width: "90%" }}
                  type="button"
                  onClick={() => {
                    pagarCobro();
                  }}
                >
                  Aplicar
                </Button>


                <Button
                  className="GeneralBotonWhite" style={{ width: "90%" }}
                  type="button"
                  onClick={() => {
                    setPagarCobro(0);
                  }}
                >
                  Atrás
                </Button>
              </div>

            )}

            {PagarCobro === 2 && (
              <div className="contenidomodalA" style={{ padding: "3%" }}>
                <h4>¡Cobro exitoso!</h4>
                <div style={{ margin: "4% 0" }}>
                  <p>Cambio:</p>
                  <b>${Cambio.toFixed(2)}</b>
                </div>

                <Button className="GeneralBoton"
                  type="button"
                  onClick={() => {
                    closePagarModal(true);
                    cleanInputsAdd();
                  }}
                >
                  Salir
                </Button>
              </div>

            )}

            {PagarCobro === 3 && (
              <div className="contenidomodalA" style={{ padding: "3%" }}>
                <h4>Cobro con tarjeta</h4>
                <div style={{ margin: "4% 0" }}>
                  <p>Total:</p>
                  <b>${DatosContact.total.toFixed(2)}</b>
                </div>
                <p>
                  {" "}
                  Presiona "Finalizar cobro" una vez que se efectue el pago en
                  tu terminal PDV
                </p>
                <br />
                <Button className="GeneralBoton" style={{ width: "90%" }}
                  type="button"
                  onClick={() => {
                    pagarCobro();
                  }}
                >
                  Finalizar cobro
                </Button>
                <Button
                  className="GeneralBotonWhite" style={{ width: "90%" }}
                  type="button"
                  onClick={() => {
                    setPagarCobro(0);
                  }}
                >
                  Atrás
                </Button>
              </div>
            )}

            {PagarCobro === 4 && (
              <div className="contenidomodalA" style={{ padding: "3%" }}>
                <h4>Transferencia</h4>
                <div style={{ margin: "4% 0" }}>
                  <p>Total:</p>
                  <b>${DatosContact.total.toFixed(2)}</b>
                </div>
                <p>
                  {" "}
                  Presiona "Finalizar cobro" una vez que se haya realizado la transferencia con éxito
                </p>
                <br />
                <Button className="GeneralBoton" style={{ width: "90%" }}
                  type="button"
                  onClick={() => {
                    pagarCobro();
                  }}
                >
                  Finalizar cobro
                </Button>
                <Button
                  className="GeneralBotonWhite" style={{ width: "90%" }}
                  type="button"
                  onClick={() => {
                    setPagarCobro(0);
                  }}
                >
                  Atrás
                </Button>
              </div>
            )}
          </>
        )}
      </Modal>

      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={() => setOpen(false)}
      >
        <Alert
          onClose={() => setOpen(false)}
          severity={typeMessage}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </Stack>
  );
}
