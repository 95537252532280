import React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// importando scss
import "../comp-styles/CitasCard.scss";

// importar iconos
import Iconos from "../IconosDeSitio";
import Imag from "../../Img/no-image.png"
import Citasjson from "../GeneralComponents/General-Json";
import { Avatar, Row } from "antd";
import PositionedMenu from "./menuvertical";
import { CancelOutlined, CancelRounded, CheckCircleOutline } from "@mui/icons-material";


function CitasCard({ listCitas, setIdCita, detailCita, cancelarCita, ModalEliminarCita, openModalDetalle, setTipoConsulta, openAddNota,
  datosContacto, atenderCita, confirmarCita, setDatosCita, contactDetail, getHistoriaClinica }) {
// console.log("hola citas");
  return (
    <>
      {listCitas.map((cita) => (
        <div key={cita.IdCita}>
          <div className="PrinCardcitas">
            <div
              className="menucitas"
              style={{ cursor: "pointer", width: "90%" }}
              onClick={() => {
                // console.log(cita.IdCita);
                detailCita(cita.IdCita, 1);
                // openModalDetalle(true);
                datosContacto({ id: cita.IdContacto, nombre: `${cita.Nombre} ${cita.ApellidoPaterno} ${cita.ApellidoMaterno}`, imagen: cita.Imagen_url });
                getHistoriaClinica(cita.IdContacto);
              }}>
              <div className="Containercitas2">
                <div className="CardC">
                  {/* <img className="statusImg" src={cita.Status === 1 ? Iconos[20] : Iconos[21]} alt="" /> */}
                  {cita.Status === 0 && (<CheckCircleOutline color="disabled" />)}
                  {cita.Status === 1 && (<CheckCircleOutline color="warning" />)}
                  {cita.Status === 2 && (<CancelOutlined color="error" />)}
                  {cita.Status === 3 && (<CheckCircleOutline color="success" />)}
                  {/* <b style={{ color: cita.TipoCita === 0 ? "red" : cita.TipoCita === 1 ? "green" : "#148f9f" }}>
                    {cita.TipoCita === 0 ? "U" : cita.TipoCita === 1 ? "P" : "S"}
                  </b> */}

                  <Avatar 
                
                       className="BgrImg"
                  src={
                    <img
                    id={`img_card_cita${cita.IdCita}`}
               
                    src={!!cita.Imagen_url ? `https://${cita.Imagen_url}` : Iconos[0]}
                    alt=''
                    onError={() => {
                      let img = document.getElementById(`img_card_cita${cita.IdCita}`);
                      img.setAttribute("src", Imag);
                    }}
                  />
                  }
                  
                  />


                  <div style={{ width: "95%" }}>
                    <b>{cita.Nombre + " "}{cita.ApellidoPaterno + " "}{!!cita.ApellidoMaterno ? cita.ApellidoMaterno : ""}</b>
                    {/* <div style={{ width: "95%", display: "flex", justifyContent: "space-between", gap: "2%" }}>

                      <p style={{ fontWeight: "normal" }}>{cita.FechaCita}</p>
                      <b style={{ color: cita.TipoCita === 0 ? "red" : cita.TipoCita === 1 ? "green" : "#148f9f" }}>
                        {cita.TipoCita === 0 ? "Urgencia" : cita.TipoCita === 1 ? "Primera vez" : "Seguimiento"}
                      </b>
                      <p style={{ fontWeight: "normal" }}>{cita.HoraCita}</p>

                    </div> */}
                    <Row style={{ width: "95%", justifyContent: "space-between", gap: "2%" }}>
                      <p style={{ fontWeight: "normal" }}>{cita.FechaCita}</p>

                      <p style={{ fontWeight: "normal" }}>{cita.HoraCita}</p>
                      <b style={{ color: cita.TipoCita === 0 ? "red" : cita.TipoCita === 1 ? "green" : "#148f9f" }}>
                        {cita.TipoCita === 0 ? "Urgencia" : cita.TipoCita === 1 ? "Primera vez" : "Seguimiento"}
                      </b>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
            <PositionedMenu
              setIdCita={setIdCita}
              extras={[
                cita, //0
                detailCita, //1
                openModalDetalle, //2
                cancelarCita,//3
                // eliminarCita,//4
                ModalEliminarCita,//4
                setTipoConsulta,//5
                openAddNota,//6
                datosContacto,//7
                atenderCita,//8
                confirmarCita,//9
                setDatosCita,//10
                contactDetail,//11
              ]} />
            {/* <div style={{ listStyle: "none" }}>
              {cita.Status === 1 ? (
                <li className="DespleListaExCitas" style={{ padding: "1%" }}>
                  <Button
                    style={{ boxShadow: "none" }}
                    id={"basic-button-1"}
                    aria-controls={openUno ? "basic-menu-1" : undefined}
                    aria-haspopup="true"
                    aria-expanded={openUno ? "true" : undefined}
                    onClick={(e) => {
                      // console.log(cita.IdCita);
                      handleClick(e, 1); setIdCita(cita.IdCita);
                    }}
                  >
                    <img style={{ width: "5px" }} src={Iconos[27]} alt="" />
                  </Button>

                  <Menu
                    id="basic-menu-1"
                    anchorEl={anchorElUno}
                    open={openUno}
                    onClose={() => { handleClose(1); }}
                    MenuListProps={{
                      "aria-labelledby": "basic-button-1",
                    }}
                  >
                    <MenuItem onClick={() => { handleClose(1); detailCita("", 2); openModalDetalle(true); }}>
                      Editar cita
                    </MenuItem>

                    <MenuItem onClick={() => { handleClose(1); cancelarCita(); }}>
                      Cancelar Cita
                    </MenuItem>

                  </Menu>
                </li>
              ) : (
                <li className="DespleListaExCitas" style={{ padding: "1%" }}>
                  <Button
                    style={{ boxShadow: "none" }}
                    id={"basic-button-2"}
                    aria-controls={openDos ? "basic-menu-2" : undefined}
                    aria-haspopup="true"
                    aria-expanded={openDos ? "true" : undefined}
                    onClick={(e) => {
                      handleClick(e, 2); setIdCita(cita.IdCita);
                    }}
                  >
                    <img style={{ width: "5px" }} src={Iconos[27]} alt="" />
                  </Button>

                  <Menu
                    id="basic-menu-2"
                    anchorEl={anchorElDos}
                    open={openDos}
                    onClose={() => { handleClose(2) }}
                    MenuListProps={{
                      "aria-labelledby": "basic-button-2",
                    }}
                  >

                    <MenuItem onClick={() => { handleClose(2); eliminarCita(); }}>
                      Eliminar cita
                    </MenuItem>

                  </Menu>
                </li>)}

            </div> */}
          </div>
        </div>
      ))}
    </>
  );
}

export default CitasCard;
