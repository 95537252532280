import React, { useRef, useEffect, useState } from "react";
// import * as React from "react";
import { Avatar, Button, Checkbox, Dropdown, Empty, Image, Input, InputNumber, List, Row, Select, Space, Modal } from "antd";
import TextArea from "antd/es/input/TextArea";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import NavbarNuevo from "../componentes/GeneralComponents/Navbar_Nuevo";
import { Content } from "antd/es/layout/layout";
import AddLista from "../componentes/GeneralComponents/ModListDeContactos";

//impotar iconos
import {
  DownloadOutlined,
  ExclamationCircleOutlined,
  RotateLeftOutlined,
  RotateRightOutlined,
  SwapOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from '@ant-design/icons';
import Agregar from "../Img/Iconos/AgregarBlanco.svg";
import iconos from "../componentes/IconosDeSitio";
import AttachmentIcon from '@mui/icons-material/Attachment';
import Eliminar from "../Img/Iconos/eliminar.svg";
import Imag from "../Img/no-image.png";
import CloseIcon from '@mui/icons-material/Close';

/*Importando Componente de modal*/
import Modals from "../componentes/GeneralComponents/Modal";
// impotar componentes
import ChatCard from "../componentes/Chats/ChatCard";
import ModalAddvincul from "../componentes/Vinculacion/ModalAddVinculacion";
import Modalchat from "../componentes/GeneralComponents/ModalDetalle"
import ModalSelectContact from "../componentes/GeneralComponents/ModalDetalle"
// importar scss
import "./styles/Chats.scss";
// importando metodo de modal
import { UseModal } from "../hooks/UseModal";
import { Loader } from "../componentes/GeneralComponents/Loader";
import { PDFViewer, Document, Page, Text, View } from '@react-pdf/renderer';
// importando metodo de servcios
import {
  chats_request,
  solicitudChat_request,
  solicitudChat_Mensajes,
  upfile_request,
  getReferenceOfChat,
  sendMessage_request,
  deleteChat_request
} from "../services/chats_services";
import { getContacts_request } from "../services/contactos_services";
import { addReceta_request } from "../services/recetas_services";
import { getCIIEDiagnosticos_request, getMedicamentos_request } from "../services/consultas_services";
import { getCedulas_request } from "../services/perfil_services";
import MuiAlert from "@mui/material/Alert";
import ButtonAntd from "../componentes/GeneralComponents/ButtonAntd";
import { AccessTime, Add, ArrowBack, DoneAll, MoreVert, PictureAsPdf, Search } from "@mui/icons-material";
// import { setPusherClient } from 'react-pusher';
import Pusher from 'pusher-js';
import { BarLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
import { ListItemDecorator } from "@mui/joy";
import { Accordion, AccordionDetails, AccordionSummary, CircularProgress } from "@mui/material";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InputAntd from "../componentes/GeneralComponents/InputAntd";
import Campos from "../componentes/GeneralComponents/campos";
import { Label, LeyendaError } from "../componentes/GeneralComponents/Formularios2Grids";
import Autosuggest from "react-autosuggest";
import TextAreaAntd from "../componentes/GeneralComponents/TextAreaAntd";
import LoaderModals from "../componentes/GeneralComponents/Loader_modals";
import { useDispatch, useSelector } from "react-redux";
import { badges } from "../redux/badges";
import { showSnackbar } from "../redux/snackbarSlice";
import { getAuthFirebase_request } from "../services/notificaciones_services";
import PreviewImage from "../componentes/GeneralComponents/previewImage";


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const pusher = new Pusher('0632eb2b2c205c095d85', {
  cluster: 'us3',
  enableLogging: false,
  encrypted: false,

},
  false);

export default function Chats() {
  const dispatch = useDispatch();

  const userInfo = useSelector((state) => state.userInfo);


  // variable del metodo de modal
  const [isOpenModal1, openModal1, CloseModal1] = UseModal(false);
  const [isOpenChatModal, OpenChatModal, CloseChatModal] = UseModal(false);
  const [IsOpenSelectContacto, OpenSelectContacto, ClosedSelectContacto] = UseModal(false);

  const [EstatusModalEliminarChat, setEstatusModalEliminarChat] =
    useState(false);

  const [contactList, setContactList] = useState([]);
  const [contactListFilter, setContactListFilter] = useState([]);

  const [DatosContacto, setDatosContacto] = useState({ idUsuario: "", id: "", nombre: "", imagen: "", tipoUsuario: "" });

  const [CargarChats, setchats] = useState([]);
  const [CargarChatsFilter, setchatsFilter] = useState([]);

  const [loader, setLoader] = useState(false);
  const [loaderSendMessage, setLoaderSendMessage] = useState(false);
  const [loaderDetalle, setLoaderDetalle] = useState(false);
  const [loaderSearch, setLoaderSearch] = useState(false);

  const [open, setOpen] = useState(false);
  const [typeMessage, setTypeMessage] = useState("");
  const [message, setMessage] = useState("");

  const [messageToSend, setMessageToSend] = useState("");
  const [messageToSendTemp, setMessageToSendTemp] = useState("");
  const [messages, setMessages] = useState([]);

  const [messagesTemp, setMessagesTemp] = useState([]);
  const [idcontact, setIdContacto] = useState("");
  const [idreferencia, setReferencia] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [TipoM, setTipoM] = useState("");
  const [FilePreview, setFilePreview] = useState("");
  // Vista de imagen en el chat
  const [UrlImagePreview, setUrlImagePreview] = useState("");

  const [ChatOption, setChatOption] = useState(1);

  const [Medicamentos, setMedicamentos] = useState([]);
  const [MedicamentosFilter, setMedicamentosFilter] = useState([]);

  const [CedulasMedico, setCedulasMedico] = useState([]);

  const [Cedula, setCedula] = useState("");
  const [CostoReceta, setCostoReceta] = useState(0);
  const [Recomendaciones, setRecomendaciones] = useState("");
  const [Indicaciones, setIndicaciones] = useState("");

  const [Medicamento, setMedicamento] = useState("");
  const [Cantidad, setCantidad] = useState(1);
  const [Dosis, setDosis] = useState("");
  const [ListaMedicamentos, setListaMedicamentos] = useState([]);

  const [PalabraClave, setPalabraClave] = useState("");
  const [ListaDiagnosticos, setListaDiagnosticos] = useState([]);
  const [DiagnosticosSeleccionados, setDiagnosticosSeleccionados] = useState([]);
  const [DiagnosticosSeleccionadosMostrados, setDiagnosticosSeleccionadosMostrados] = useState([]);
  const [OtrosDiagnosticos, setOtrosDiagnosticos] = useState("");

  const [MedcSelection, setMedcSelection] = useState({});
  const [MedcSelectionDos, setMedcSelectionDos] = useState({});

  const [CheckFirma, setCheckFirma] = useState(true);
  const [CheckEnviar, setCheckEnviar] = useState(true);
  const [CheckIndicaciones, setCheckIndicaciones] = useState(true);
  const [CheckDiagnostico, setCheckDiagnostico] = useState(true);

  const [pusherDatosTemp, setpusherDatosTemp] = useState({ referencia: "", canal: "" });

  const [FileToSend, setFileToSend] = useState({ name: "", url: "", file: "", size: 0, formato: "" });

  const [TabsChat, setTabsChat] = useState(0);

  const [MensajeCedula, setMensajeCedula] = useState("true");
  const [MensajeListaMedicamentos, setMensajeListaMedicamentos] = useState("true");
  const [MensajeMedicamento, setMensajeMedicamento] = useState("true");
  const [MensajeDosis, setMensajeDosis] = useState("true");

  // MESNAJE DIAGNOSTICO
  const [MensajeDiagnosticoCIIE, setMensajeDiagnosticoCIIE] = useState("true");
  const [MensajeOtrosDiagnosticos, setMensajeOtrosDiagnosticos] = useState("true");
  const [MensajePalabraClave, setMensajePalabraClave] = useState("true");

  let items = [
    DatosContacto.tipoUsuario !== 1 && {
      key: '1',
      label: <li onClick={() => {
        sessionStorage.setItem("idContactoExpediente", DatosContacto.id);
        sessionStorage.setItem("nombreContactoExpediente", DatosContacto.nombre);
        sessionStorage.setItem("imagenContactoExpediente", DatosContacto.imagen);
        window.open(`/#/app/ExpedientesNuevoDetalle`, "_blank");
      }}>Expediente</li>,
    },
    DatosContacto.tipoUsuario !== 1 && {
      key: '2',
      label: <li onClick={() => {
        setChatOption(2);
      }}>Nueva receta</li>,
    },
    {
      key: '3',
      label: <li onClick={() => {
        confirm();
      }}>Eliminar chat</li>,
    },
    // {
    //   key: '3',
    //   label:"",
    // },

  ]

  const handleFileSelect = (event) => {
    setMessageToSend("");
    const file = event.target.files[0];
    let formato = file.type.split("/");


    // console.log(event.target.files[0]);
    // console.log(file);
    // return;

    // Validar el tamaño del archivo (5 MB = 5 * 1024 * 1024 bytes)
    if (file.size > 5000000) {
      setOpen(true);
      setTypeMessage("warning");
      setMessage("El archivo es demasiado grande. Debe ser menor o igual a 5 MB.");
      return;
    }

    if (formato[1] === 'pdf') {
      // console.log(FileToSend);
      // setFileToSend({ name: event.target.files[0].name, url: URL.createObjectURL(event.target.files[0]), file: event.target.files[0], size: event.target.files[0].size, formato: formato });
      sendMessage("1", file);
      //// console.log('El archivo seleccionado es un PDF.');
    } else if (['jpg', 'jpeg', 'png'].includes(formato[1])) {
      setFileToSend({ name: event.target.files[0].name, url: URL.createObjectURL(event.target.files[0]), file: event.target.files[0], size: event.target.files[0].size, formato: formato });
      setFilePreview(URL.createObjectURL(event.target.files[0]));
      // setTipoM("2")
      // setFileToSend({ name: "", url: "", file: "", size: 0, formato: "" });
      //// console.log('El archivo seleccionado es una imagen.');
    } else {
      setOpen(true);
      setTypeMessage("warning");
      setMessage("Formato de archivo no permitido.");
      //// console.log('El tipo de archivo no es reconocido como PDF o imagen.');
    }
    // setSelectedFile(event.target.files[0]);
  };
  // Posicionar el scroll al ultimo mensaje
  const chatContainerRef = useRef(null);

  const scrollToBottom = () => {
    chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
  };

  const onDownload = () => {
    fetch(UrlImagePreview)
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.download = 'image.png';
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(url);
        link.remove();
      });
  };

  function replaceURLWithHTMLLinks(text) {
    var exp = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
    let arrayText = text.split(" ");
    let texto = "";
    // console.log(exp.test(text));
    arrayText.forEach(element => {
      if (exp.test(element)) {
        texto += <a href="element">{element}</a>;
      } else {
        if (!!texto) {
          texto += " "
          texto += element;
        } else {
          texto += element;
          texto += " "
        }

      }
    });

    return <div>{texto}</div>;

  }

  useEffect(() => {
    // Llamar a la función scrollToBottom cada vez que se agregue un nuevo mensaje
    scrollToBottom();
  }, [messages]);

  // useEffect(() => {
  //   // Escuchar eventos de mensajes del servidor
  //   const handleSocketMessage = (message) => {
  //     // console.log(message);
  //     // Actualizar el estado de los mensajes con el nuevo mensaje recibido
  //     setMessages((prevMessages) => [...prevMessages, message]);
  //   };
  //   // Escuchar eventos de mensajes del servidor
  //   socket.on("message", handleSocketMessage);
  //   // Limpiar la conexión cuando el componente se desmonte
  //   return () => {
  //     socket.off("message", handleSocketMessage);
  //   };
  // }, []);

  // ******** FUNCIONES ********
  async function getChats() {
    setLoader(true);
    let response = await chats_request();
    if (response.ok) {
      // console.log(response.data);
      setchats(response.data);
      setchatsFilter(response.data);
    } else {
      setOpen(true);
      setTypeMessage("error");
      setMessage(response.mensaje);
      // alert(response.mensaje);
    }
    setLoader(false);
    return;
  }

  async function getCIIEDiagnosticos() {
    // let response = await getCIIEDiagnosticos(text.target.value);
    setLoaderSearch(true);
    if (!!PalabraClave) {
      let response = await getCIIEDiagnosticos_request(PalabraClave);
      // console.log(response.data);
      // return;
      if (response.ok) {
        let list = [];
        // console.log(response.data);
        if (!!response.data[0]) {
          response.data.forEach((diagnostico, index) => {
            list.push({ key: index, value: diagnostico, label: diagnostico })
          });
          setListaDiagnosticos(list);
        } else {
          setListaDiagnosticos([]);
          setOpen(true);
          setTypeMessage("warning");
          setMessage("Sin resultados");
        }
      } else {
        setOpen(true);
        setTypeMessage("error");
        setMessage(response.mensaje);
      }
    } else {

    }
    setLoaderSearch(false);
    return;
  }

  async function fetchMensajes(re) {
    setLoader(true);
    let mensajes = await solicitudChat_request(re);
    // let mensajes = await solicitudChat_Mensajes();
    // console.log(DatosContacto.idUsuario);
    // console.log(mensajes);
    if (mensajes.ok) {
      setMessages(mensajes.data.Mensajes);
      setMessagesTemp(mensajes.data.Mensajes);
    } else {

    }
    // setMessages((prevMessages) => [...prevMessages, mensajes.data.Mensajes])
    // console.log(mensajes);
    setLoader(false);
    return;
  }

  const chatSolicitud = async (idpaciente, tipo) => {
    // console.log(idpaciente);
    pusher.connect();
    const channel = pusher.subscribe(idpaciente.Channel);
    // const channel = await pusher.subscribe(idpaciente.Referencia);
    // console.log(channel);
    try {
      if (tipo !== undefined) {
        let mensajeNuevo = {
          Channel: idpaciente.Channel,
          Emisor: idpaciente.Emisor,
          // IdPaciente: idpaciente.IdPaciente,
          IdUsuario: idpaciente.IdUsuario,
          IdUsuario2: idpaciente.IdUsuario2,
          Referencia: idpaciente.Referencia,
          Multimedia: idpaciente.Multimedia !== undefined ? idpaciente.Multimedia : null,
          MultimediaUrl: idpaciente.MultimediaUrl !== undefined ? idpaciente.MultimediaUrl : null,
          TipoMensaje: idpaciente.TipoMensaje,
          Visto: idpaciente.Visto,
          created_at: idpaciente.created_at,
          fecha: idpaciente.fecha,
          id: idpaciente.id,
          message: idpaciente.message,
          updated_at: idpaciente.updated_at,
        }
        setMessages((messagesTemp) => [...messagesTemp, mensajeNuevo]);
      }
      channel.bind('MensajeChat', (data) => {
        // console.log(data);
        let mensaje = {
          Channel: data.message.Channel,
          Emisor: data.message.Emisor,
          // IdPaciente: data.message.IdPaciente,
          IdUsuario: data.message.IdUsuario,
          IdUsuario2: data.message.IdUsuario2,
          Referencia: data.message.Referencia,
          Multimedia: data.message.Multimedia !== undefined ? data.message.Multimedia : null,
          MultimediaUrl: data.message.MultimediaUrl !== undefined ? data.message.MultimediaUrl : null,
          TipoMensaje: data.message.TipoMensaje,
          Visto: data.message.Visto,
          created_at: data.message.created_at,
          fecha: data.message.fecha,
          id: data.message.id,
          message: data.message.message,
          updated_at: data.message.updated_at,
        }
        setMessages((messagesTemp) => [...messagesTemp, mensaje]);
        setLoaderSendMessage(false);
        return;
        // messages.forEach(element => {
        //   if (element.id !== mensaje.id) {
        //     setMessages([...messagesTemp, mensaje]);
        //     // setMessages(messagesTemp.push(mensaje));
        //   }
        // });
        // setMessages([...messagesTemp, mensaje]);
        // setMessages(messagesTemp.push(mensaje));
        // setMessages(mensajes);
        // allMessages.push(data);
        // setMessages(allMessages);
      });
    } catch (error) {
      // console.log(error);
    }
    // fetchMensajes
    // setpusherChannel(idpaciente.Channel);
    if (tipo !== undefined) {
      // console.log(DatosContacto.idUsuario);
      setIdContacto(DatosContacto.id);
      setReferencia(idpaciente.Referencia);
      getChats();
    } else {
      setDatosContacto({ nombre: idpaciente.Usuario, imagen: idpaciente.Imagen_Url, id: idpaciente.IdContacto, idUsuario: idpaciente.IdPaciente, tipoUsuario: idpaciente.TipoUsuario });
      setIdContacto(idpaciente.IdContacto);
      setReferencia(idpaciente.Referencia);
      fetchMensajes(idpaciente.Referencia);
    }
    getMedicamentos();
    getCedulaMedico();
  };

  async function getContactos() {
    let response = await getContacts_request();
    if (response.ok) {
      // console.log(response.data);
      let array = [];

      response.data.forEach((element) => {
        if (element.YgiaUser === "Con Ygia") {
          array.push({
            usuario: `${element.Nombre} ${element.ApellidoPaterno} ${element.ApellidoMaterno}`,
            imagen: `${!!element.Imagen_url ? element.Imagen_url : ""}`,
            id: `${element.IdContacto}`,
            idUsuario: `${element.IdPaciente}`
          });
        }
      });
      // console.log(array);
      setContactList(array);
      setContactListFilter(array);
    } else {
    }
  };

  async function newChat(idContacto) {
    let response = await getReferenceOfChat(idContacto);
    // console.log(response);
    if (response.ok) {
      setpusherDatosTemp({ referencia: response.data.Referencia, canal: response.data.Canal })
    } else {
      setOpen(true);
      setTypeMessage("error");
      setMessage(response.mensaje);
    }
  }

  async function addReceta() {
    setLoaderDetalle(true);
    if (!!Cedula && ListaMedicamentos.length > 0) {
      let diagnosticos_temp = []
      if (DiagnosticosSeleccionadosMostrados.length > 0) {
        DiagnosticosSeleccionadosMostrados.forEach(element => {
          diagnosticos_temp.push(element.diagnostico);
        });
      }
      // console.log(diagnosticos_temp);
      let data = new FormData();
      data.append("Costo", CostoReceta);
      data.append("Cedula", Cedula);
      data.append("IdContacto", DatosContacto.id);
      data.append("Tipo", "0");
      data.append("Recomendaciones", Recomendaciones);
      data.append("Indicaciones", Indicaciones);
      data.append("Diagnostico", diagnosticos_temp.toString());
      data.append("Firma", CheckFirma ? 0 : 1);
      data.append("Enviar", CheckEnviar ? 0 : 1);
      data.append("MostrarIndicaciones", CheckIndicaciones);
      data.append("MostrarDiagnostico", CheckDiagnostico);
      ListaMedicamentos.forEach((element, index) => {
        data.append(`Concepto[${index}][Descripcion]`, element.Descripcion);
        data.append(`Concepto[${index}][Concepto]`, element.Concepto);
        data.append(`Concepto[${index}][Cantidad]`, element.Cantidad);
      });
      let response = await addReceta_request(data);
      if (response.ok) {
        // setLoaderSendMessage(true);
        setOpen(true);
        setTypeMessage("success");
        setMessage("Receta creada");
        cleanInputsAdd();
        setChatOption(1);
        // let data = new FormData();
        // data.append("TipoMensaje", "0");
        // data.append("mensaje", "Receta enviada, para acceder a la receta presione el mensaje");
        // let response = await sendMessage_request(DatosContacto.id, data);

        // if (response.ok) {
        // console.log(response);
        // console.log(response.data);
        //   document.getElementById("ChatFile").value = "";
        //   if (pusherDatosTemp.canal === false || pusherDatosTemp.canal === null) {
        //     chatSolicitud(response.data.Respuesta, 0);
        //   }
        //   setLoaderSendMessage(false);
        // } else {
        //   setOpen(true);
        //   setTypeMessage("error");
        //   setMessage(response.mensaje);
        //   setLoaderSendMessage(false);
        // }
      } else {
        setOpen(true);
        setTypeMessage("error");
        setMessage(response.mensaje);
      }
    } else {
      if (!!!Cedula) {
        setMensajeCedula("false");
      }
      if (ListaMedicamentos.length === 0) {
        setMensajeListaMedicamentos("false");
      }

      setOpen(true);
      setTypeMessage("warning");
      setMessage("Faltan campos por completar");
    }

    setLoaderDetalle(false);
    return;
  }

  async function getMedicamentos() {
    let response = await getMedicamentos_request();
    if (response.ok) {
      // console.log(response.data);
      let medi_temp = [];
      response.data.forEach(medi => {
        medi_temp.push({ Numero: medi.id, Opcion: `${medi.nombreActivo} - ${medi.id}` })
      });
      // console.log(medi_temp);
      setMedicamentos(medi_temp);
      setMedicamentosFilter(medi_temp);
    } else {

    }
  }

  async function getCedulaMedico() {
    let response = await getCedulas_request();
    if (response.ok) {
      // console.log(response.data);
      let cedulas = [];
      response.data.forEach(cedula => {
        cedulas.push({ label: `${cedula.Cedula} - ${cedula.Profesion}`, value: cedula.Cedula })
      });
      setCedulasMedico(cedulas);
    } else {
    }
  }

  async function deleteChat() {
    cerrarChatModal();
    setLoader(true);
    let response = await deleteChat_request(idreferencia);
    if (response.ok) {
      getChats();
      dispatch(showSnackbar({
        show: true,
        text: "Chat eliminado",
        type: "success",
      }));
    } else {
      dispatch(showSnackbar({
        show: true,
        text: response.mensaje,
        type: "error",
      }));
    }
    setLoader(false);
  }

  const onSuggestionsFetchRequested2 = ({ value }) => {
    // console.log("Filtro RECETA");
    setMedicamentos(FiltrarMedicamentos(value));
  };

  const FiltrarMedicamentos = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLenght = inputValue.Lenght;

    var filtrado = MedicamentosFilter.filter((medicamento) => {
      var textoCompleto = medicamento.Opcion;

      if (
        textoCompleto
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .includes(inputValue)
      ) {
        return medicamento;
      }
    });
    return inputLenght === 0 ? [] : filtrado;
  };

  const onSuggestionsClearRequested2 = () => {
    setMedicamentos([]);
  };

  const getSuggestionValueDos = (suggestion) => {
    return `${suggestion.Opcion} `;
  };

  const renderSuggestionDos = (suggestion) => (

    <div className="sugerencia" onClick={() => { setMedcSelectionDos(suggestion); }}>
      {/* {// console.log(suggestion)} */}
      {`${suggestion.Opcion}`}
    </div>
  );

  const onChangeDos = (e, { newValue }) => {
    if (newValue.length > 0) {
      setMensajeMedicamento("true");
    }
    // console.log(e.target);
    // console.log(newValue);
    setMedicamento(newValue);
  };

  const inputPropsDos = {
    placeholder: "Medicamento",
    value: Medicamento,
    onChange: (e, newValue) => {
      onChangeDos(e, newValue);
    },
  };

  const eventEnterDos = (e) => {
    if (e.key == "Enter") {
      // console.log(e.target.value);
      var split = e.target.value.split("-");
      // console.log(split);
      var medicamento = {
        Opcion: split[0].trim(),
        // Numero: split[1].trim(),
      };
      // console.log(medicamento);
      setMedcSelection(medicamento);
    }
  };

  // RECETA-MEDICAMENTOS
  function addMedicamento() {
    // console.log(NombreProducto);
    // console.log(CantidadProducto);
    // console.log(DescripcionUso);

    if (!!Medicamento && !!Dosis && !!Cantidad) {
      // setOpen(true);
      // setTypeMessage("success");
      // setMessage("Agregado");
      // console.log(Medicamento);
      let arrayMedi = Medicamento.split("-");
      let medicamento_temp = arrayMedi[0];
      let id_temp = arrayMedi[1];
      // console.log({ medicamento_temp, id_temp });
      let array = ListaMedicamentos;
      if (array.length > 0) {
        array.push({
          index: ListaMedicamentos[ListaMedicamentos.length - 1].index + 1,
          Id: id_temp !== undefined ? id_temp : "",
          Concepto: medicamento_temp,
          Cantidad: Cantidad,
          Descripcion: Dosis
        });
      } else {
        array.push({
          index: 1,
          Id: id_temp !== undefined ? id_temp : "",
          Concepto: medicamento_temp,
          Cantidad: Cantidad,
          Descripcion: Dosis
        });
      }
      // console.log(array);

      setListaMedicamentos(array);

      setMedicamento("");
      setCantidad(1);
      setDosis("");
      // setMensajeListaMedicamentos("true");
    } else {
      if (!!!Medicamento) {
        setMensajeMedicamento("false");
      }
      if (!!!Dosis) {
        setMensajeDosis("false");
      }
      setOpen(true);
      setTypeMessage("warning");
      setMessage("Faltan campos por completar");

    }

    return;
  }

  function eliminarMedicamento(posicion) {
    // console.log(posicion);
    let array = ListaMedicamentos;

    let arrayDos = [];

    let arrayTres = [];

    arrayDos = array.filter((element) => { return element.index !== posicion; });
    // console.log(arrayDos);
    arrayDos.forEach((element, index) => {
      arrayTres.push({
        index: index + 1,
        Concepto: element.Concepto,
        Cantidad: element.Cantidad,
        Descripcion: element.Descripcion
      });
    });

    setListaMedicamentos(arrayTres);
    // console.log(arrayTres);
  }

  function addDiagnosticoCIIE() {
    if (DiagnosticosSeleccionados.length > 0) {
      DiagnosticosSeleccionados.forEach((diagnostico, index) => {
        let array = DiagnosticosSeleccionadosMostrados;
        if (array.length > 0) {
          // DiagnosticosSeleccionados.forEach(diagnostico => {
          array.push({
            index: DiagnosticosSeleccionadosMostrados[DiagnosticosSeleccionadosMostrados.length - 1].index + 1,
            diagnostico: diagnostico
          });
          // });
          setDiagnosticosSeleccionadosMostrados(array);

        } else {
          array.push({
            index: 1,
            diagnostico: diagnostico
          });

          setDiagnosticosSeleccionadosMostrados(array);
        }
      });

      // console.log(DiagnosticosSeleccionadosMostrados);

      setDiagnosticosSeleccionados([]);
    } else {
      if (DiagnosticosSeleccionados.length === 0) {
        setMensajeDiagnosticoCIIE("false");
      }
      setOpen(true);
      setTypeMessage("warning");
      setMessage("Faltan campos por completar");

    }

    return;
  }

  function addDiagnosticoOtro() {
    if (!!OtrosDiagnosticos) {
      let array = DiagnosticosSeleccionadosMostrados;
      if (array.length > 0) {
        array.push({
          index: DiagnosticosSeleccionadosMostrados[DiagnosticosSeleccionadosMostrados.length - 1].index + 1,
          diagnostico: OtrosDiagnosticos
        });
      } else {
        array.push({
          index: 1,
          diagnostico: OtrosDiagnosticos
        });
      }

      // console.log(array);

      setDiagnosticosSeleccionadosMostrados(array);
      setOtrosDiagnosticos("");
    } else {
      if (!!!OtrosDiagnosticos) {
        setMensajeOtrosDiagnosticos("false");
      }
      setOpen(true);
      setTypeMessage("warning");
      setMessage("Faltan campos por completar");

    }

    return;
  }

  function eliminarDiagnostico(posicion) {
    // console.log(posicion);
    let array = DiagnosticosSeleccionadosMostrados;

    let arrayDos = [];

    let arrayTres = [];

    arrayDos = array.filter((element) => { return element.index !== posicion; });
    // console.log(arrayDos);
    arrayDos.forEach((element, index) => {
      arrayTres.push({
        index: index + 1,
        diagnostico: element.diagnostico
      });
    });

    setDiagnosticosSeleccionadosMostrados(arrayTres);
    // console.log(arrayTres);
  }

  function cleanInputsAdd() {
    setCedula("");
    setCostoReceta(0);
    setRecomendaciones("");
    setIndicaciones("");
    setMedicamento("");
    setDosis("");
    setListaMedicamentos([]);
    setCheckFirma(true);
    setCheckEnviar(true);
    setPalabraClave("");
    setOtrosDiagnosticos("");
    setDiagnosticosSeleccionados([]);
    setDiagnosticosSeleccionadosMostrados([]);
  }

  function filterContacts(searchText) {
    var chars = {
      á: "a",
      é: "e",
      í: "i",
      ó: "o",
      ú: "u",
      à: "a",
      è: "e",
      ì: "i",
      ò: "o",
      ù: "u",
      ñ: "n",
      Á: "A",
      É: "E",
      Í: "I",
      Ó: "O",
      Ú: "U",
      À: "A",
      È: "E",
      Ì: "I",
      Ò: "O",
      Ù: "U",
      Ñ: "N",
    };
  
    // Función para normalizar el texto reemplazando caracteres especiales
    function normalize(text) {
      return text.replace(/[áàéèíìóòúùñÁÉÍÓÚÀÈÌÒÙÑ]/g, (match) => chars[match] || match);
    }

    // Normalizar el texto de búsqueda
    const normalizedSearchText = normalize(searchText.target.value.toLowerCase());

    // Filtrar la lista de contactos
    const filter = contactListFilter.filter((contacto) => {
      const normalizedUser = normalize(contacto.usuario.toLowerCase());

      // Buscar coincidencia en cualquiera de los campos
      return (
        normalizedUser.includes(normalizedSearchText)
      );
    });

    setContactList(filter);
  };

  const cerrarChatModal = () => {
    const valor = null;
    setMessages([]);
    CloseChatModal();
    pusher.disconnect();
    // console.log("array vacio");
  }

  ///////////////////////////////////////////

  async function sendMessage(tipo, archivo) {
    setLoaderSendMessage(true);
    let fecha = new Date();
    let data = new FormData();
    setMessageToSend("");
    // setMessageToSendTemp(messageToSend);
    if (tipo === "1" || tipo === "2") {
      // console.log("es archivo");
      // console.log(archivo);
      // return;
      if (tipo === "1") {
        data.append("TipoMensaje", tipo);
        data.append("Multimedia", archivo, archivo.name);
        // data.append("NombreMultimedia", archivo.name);
      } else {
        // console.log(FileToSend);
        data.append("TipoMensaje", tipo);
        data.append("Multimedia", FileToSend.file, FileToSend.name);
        // data.append("NombreMultimedia", FileToSend.name);
      }
      // console.log(data.get("TipoMensaje"));
      // console.log(data.get("Multimedia"));
      // console.log(data.get("NombreMultimedia"));
      let response = await sendMessage_request(DatosContacto.id, data);

      if (response.ok) {
        // console.log(response);
        if (pusherDatosTemp.canal === false || pusherDatosTemp.canal === null) {
          chatSolicitud(response.data.Respuesta, 0);
        }
        // setFileToSend({ name: "", url: "", file: "", size: 0, formato: "" });
        setFilePreview("")
        document.getElementById("ChatFile").value = "";
      } else {
        setOpen(true);
        setTypeMessage("error");
        setMessage(response.mensaje);
      }
    } else {
      if (!!messageToSendTemp.trim()) {
        let data = new FormData();
        data.append("TipoMensaje", tipo);
        data.append("mensaje", messageToSendTemp);
        let response = await sendMessage_request(DatosContacto.id, data);

        if (response.ok) {
          // console.log(response);
          // console.log(response.data);
          document.getElementById("ChatFile").value = "";
          setMessageToSendTemp("");
          if (pusherDatosTemp.canal === false || pusherDatosTemp.canal === null) {
            chatSolicitud(response.data.Respuesta, 0);
          }
        } else {
          setMessageToSend(messageToSendTemp);
          setOpen(true);
          setTypeMessage("error");
          setMessage(response.mensaje);
          setMessageToSendTemp("");
        }
      } else {
        setOpen(true);
        setTypeMessage("warning");
        setMessage("No es posible enviar un mensaje vacio");
      }
    }

    setLoaderSendMessage(false);

  }

  function filterList(searchText) {
    var chars = {
      á: "a",
      é: "e",
      í: "i",
      ó: "o",
      ú: "u",
      à: "a",
      è: "e",
      ì: "i",
      ò: "o",
      ù: "u",
      ñ: "n",
      Á: "A",
      É: "E",
      Í: "I",
      Ó: "O",
      Ú: "U",
      À: "A",
      È: "E",
      Ì: "I",
      Ò: "O",
      Ù: "U",
      Ñ: "N",
    };

    // Función para normalizar el texto reemplazando caracteres especiales
    function normalize(text) {
      return text.replace(/[áàéèíìóòúùñÁÉÍÓÚÀÈÌÒÙÑ]/g, (match) => chars[match] || match);
    }

    // Normalizar el texto de búsqueda
    const normalizedSearchText = normalize(searchText.target.value.toLowerCase());

    // Filtrar la lista de contactos
    const filter = CargarChatsFilter.filter((chat) => {
      const normalizedUser = normalize(chat.Usuario.toLowerCase());

      // Buscar coincidencia en cualquiera de los campos
      return (
        normalizedUser.includes(normalizedSearchText)
      );
    });
    setchats(filter);
  }

  function filterOptions(input, option) {
    var chars = {
      "á": "a", "é": "e", "í": "i", "ó": "o", "ú": "u",
      "à": "a", "è": "e", "ì": "i", "ò": "o", "ù": "u", "ñ": "n",
      "Á": "A", "É": "E", "Í": "I", "Ó": "O", "Ú": "U",
      "À": "A", "È": "E", "Ì": "I", "Ò": "O", "Ù": "U", "Ñ": "N"
    }
    var expr = /[áàéèíìóòúùñ]/ig;
    return option?.label.replace(expr, function (e) { return chars[e] }).toLowerCase().indexOf(input.replace(expr, function (e) { return chars[e] }).toLowerCase()) > -1
  }

  function filterSort(optionA, optionB) {
    var chars = {
      "á": "a", "é": "e", "í": "i", "ó": "o", "ú": "u",
      "à": "a", "è": "e", "ì": "i", "ò": "o", "ù": "u", "ñ": "n",
      "Á": "A", "É": "E", "Í": "I", "Ó": "O", "Ú": "U",
      "À": "A", "È": "E", "Ì": "I", "Ò": "O", "Ù": "U", "Ñ": "N"
    }
    var expr = /[áàéèíìóòúùñ]/ig;
    return (optionA?.label.replace(expr, function (e) { return chars[e] }).toLowerCase() ?? "").replace(expr, function (e) { return chars[e] }).toLowerCase().localeCompare((optionB?.label.replace(expr, function (e) { return chars[e] }).toLowerCase() ?? "").replace(expr, function (e) { return chars[e] }).toLowerCase())
  }

  // MODAL ELIMINAR CITA
  const [modal, ModalEliminarChat] = Modal.useModal();
  const confirm = () => {
    modal.confirm({
      title: "¿Desea eliminar este chat?",
      okText: "Confirmar",
      cancelText: "Cancelar",
      onOk: () => deleteChat(),
      onCancel: () => setEstatusModalEliminarChat(false),
      open: EstatusModalEliminarChat,
      icon: <ExclamationCircleOutlined />,
      content: (
        <div>
          <p>El chat con {DatosContacto.nombre.toUpperCase()} sera eliminado de forma permanente</p>
        </div>
      ),
    });
  };

  async function readModule() {
    await getAuthFirebase_request({ accion: 1, modulo: "chat", userId: userInfo.id });
  }

  ///////////////////////////////////////////

  useEffect(() => {
    readModule();
    dispatch(badges({ accion: 0, modulo: "M2", }));
    getChats();
    getContactos();
  }, []);

  // componentDidUpdate() {
  // console.log();
  // }

  // Modal style
  const styleDet = {
    height: "clac(100vh - 50px)",
    width: "clac(100vw - 50px)",
  };

  return (
    <Stack>
      <NavbarNuevo
        modulo={"Chat"}
        mensajesError={{
          setOpen: setOpen,
          setTypeMessage: setTypeMessage,
          setMessage: setMessage,
        }}
        searchBar={
          <div className="CabezeraChat">
            <div className="Encabesado-Chat" style={{ alignItems: "center" }}>
              <b>Chat</b>
              <ButtonAntd
                tipo={1}
                style={{ width: "100px" }}
                onClick={OpenSelectContacto}
                children={
                  <Row style={{ gap: ".5rem", color: "white", justifyContent: "space-between", alignItems: "center" }}>
                    Nuevo
                    <img style={{ width: "12px" }} src={Agregar} alt="" />
                  </Row>
                }
              />
            </div>
            <Input
              prefix={<Search style={{ color: "#d7d7d7", width: "18px" }} />}
              style={{ width: "45%" }}
              placeholder="Paciente"
              type="text"
              onChange={filterList}
            />

          </div>
        }
      />
      <Content
        className="site-layout"
        style={{
          // marginTop: "100px",
          padding: '0 3%',
          // height: "calc(100vh - 52px)",
          position: "relative",
          width: "100%",
          overflow: 'initial',
        }}
      >
        {loader ? (
          <Loader Loading={loader} />
        ) : (
          <>
            <ChatCard
              OpenChatModal={OpenChatModal}
              ChatsCarga={CargarChats}
              chatSolicitud={chatSolicitud}
            />
            {CargarChats.length === 0 && (
              <Empty description={<span style={{ color: "black" }}>Sin chats</span>} />
            )}
          </>
        )}
      </Content>

      <Modalchat isOpen={isOpenChatModal} closeModal={CloseChatModal} optionClose={false} styleDet={styleDet}>
        <div className="ModalChat">
          {/*  // ******** inicio Front de Mensajes ******** */}
          <div
            className="usuario-seleccionado"
          >
            <div style={{ display: "flex", alignItems: "center", gap: "2%", width: "95%" }}>
              {ChatOption === 2 && (
                <ArrowBack onClick={() => {
                  setChatOption(1);
                }} />
              )}
              {/* <div className="imgDetalleChat"> */}

              <Avatar size={55} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                className="GeneralNoImage"
                src={
                  <Image
                    style={{ background: "#d7d7d7" }}
                    src={
                      !!DatosContacto.imagen
                        ? "https://" + DatosContacto.imagen
                        : Imag
                    }
                  />
                } />
              {/* </div> */}
              <div className="">
                <span style={{ color: "white", fontWeight: "500", wordBreak: "break-all" }}>{DatosContacto.nombre}</span>
              </div>

              {ChatOption === 1 && (
                <Dropdown
                  menu={{ items, }}
                  trigger={["click"]}
                >
                  <ListItemDecorator>
                    <MoreVert style={{ cursor: "pointer" }} />
                  </ListItemDecorator>

                </Dropdown>
              )}
            </div>
            {ChatOption === 1 && (
              <CloseIcon
                onClick={cerrarChatModal} className="opciones" style={{ cursor: "pointer", fontSize: "20px" }}
              />


            )}
          </div>
          {ChatOption === 1 && (
            <div>
              <div id="Chatmessage" className="Chatmessage" ref={chatContainerRef}>
                {messages.map((chats) => (
                  <div
                    key={chats.id}
                    className={(chats.IdUsuario !== DatosContacto.idUsuario) ? "mensaje2 left" : "mensaje"}>
                    <div className="cuerpo">
                      {!!chats.Multimedia ? (
                        chats.MultimediaUrl.substring(chats.MultimediaUrl.toString().length, chats.MultimediaUrl.toString().length - 3) === "png" || chats.MultimediaUrl.substring(chats.MultimediaUrl.toString().length, chats.MultimediaUrl.toString().length - 3) === "jpg" ? (
                          <div className="archivo">
                            <div>
                              <PreviewImage
                                urlImage={`https://${chats.MultimediaUrl}`}
                                urlImageSecond={chats.MultimediaUrl}
                                style={{ width: "200px", height: "150px" }}
                                imageName={!!chats.nombreMultimedia ? chats.nombreMultimedia : `YGIAChat_${new Date().toUTCString()}.${chats.MultimediaUrl.substring(chats.MultimediaUrl.toString().length, chats.MultimediaUrl.toString().length - 3)}`}
                              />
                            </div>
                            <div className="tiempo">
                              {/* <i className=""></i> */}
                              <p style={{ fontSize: "12px" }}>{new Date(chats.created_at).toLocaleDateString(undefined, { day: "2-digit", month: "numeric", year: "numeric" })} {new Date(chats.created_at).toLocaleTimeString(undefined, { hour12: true, hour: "2-digit", minute: "2-digit" })}</p>
                              <DoneAll fontSize="12px" />
                            </div>
                          </div>
                        ) : chats.MultimediaUrl.substring(chats.MultimediaUrl.toString().length, chats.MultimediaUrl.toString().length - 3) === "mp4" ? (
                          <div className="archivo">
                            <video controls>
                              <source src={chats.MultimediaUrl} type="video/mp4" />
                              Tu navegador no admite la reproducción de videos.
                            </video>
                            <div className="tiempo">
                              {/* <i className=""></i> */}
                              <p style={{ fontSize: "12px" }}>{new Date(chats.created_at).toLocaleDateString(undefined, { day: "2-digit", month: "numeric", year: "numeric" })} {new Date(chats.created_at).toLocaleTimeString(undefined, { hour12: true, hour: "2-digit", minute: "2-digit" })}</p>
                              <DoneAll fontSize="12px" />
                            </div>
                          </div>
                        ) : chats.MultimediaUrl.substring(chats.MultimediaUrl.toString().length, chats.MultimediaUrl.toString().length - 3) === "pdf" ? (
                          <div>
                            <a href={`https://${chats.MultimediaUrl}`} target="_blank" rel="noopener noreferrer" style={{ listStyle: "none", color: "white" }}>
                              <div style={{ display: "flex", textAlign: "center", gap: "5px" }}><PictureAsPdf size={35} /> {!!chats.NombreMultimedia ? chats.NombreMultimedia : "Documento PDF"}</div>
                            </a>
                            <div className="tiempo">
                              {/* <i className=""></i> */}
                              <p style={{ fontSize: "12px" }}>{new Date(chats.created_at).toLocaleDateString(undefined, { day: "2-digit", month: "numeric", year: "numeric" })} {new Date(chats.created_at).toLocaleTimeString(undefined, { hour12: true, hour: "2-digit", minute: "2-digit" })}</p>
                              <DoneAll fontSize="12px" />
                            </div>
                          </div>
                        ) : (
                          <div className="archivo">
                            <div>
                              Contenido multimedia no compatible.{" "}
                              <a href={chats.MultimediaUrl} target="_blank" rel="noopener noreferrer">
                                Descargar contenido
                              </a>
                            </div>
                            <div className="tiempo">
                              {/* <i className=""></i> */}
                              <p style={{ fontSize: "12px" }}>{new Date(chats.created_at).toLocaleDateString(undefined, { day: "2-digit", month: "numeric", year: "numeric" })} {new Date(chats.created_at).toLocaleTimeString(undefined, { hour12: true, hour: "2-digit", minute: "2-digit" })}</p>
                              <DoneAll fontSize="12px" />
                            </div>
                          </div>
                        )
                      ) : (
                        <div>
                          <Space style={{ wordBreak: "break-word" }}>
                            {chats.message}
                          </Space>
                          <div className="tiempo">
                            {/* <i className=""></i> */}
                            <p style={{ fontSize: "12px" }}>{new Date(chats.created_at).toLocaleDateString(undefined, { day: "2-digit", month: "numeric", year: "numeric" })} {new Date(chats.created_at).toLocaleTimeString(undefined, { hour12: true, hour: "2-digit", minute: "2-digit" })}</p>
                            <DoneAll fontSize="12px" />
                          </div>
                        </div>
                        // <div className="texto">
                        //   {chats.message}
                        //   {/* {replaceURLWithHTMLLinks(chats.message)} */}
                        //   <div className="tiempo">
                        //     {/* <i className=""></i> */}
                        //     <p style={{fontSize:"12px"}}>{new Date(chats.created_at).toLocaleDateString(undefined, {day:"2-digit", month:"numeric", year:"numeric"})} {new Date(chats.created_at).toLocaleTimeString(undefined, {hour12:true, hour:"2-digit",minute:"2-digit"})}</p>
                        //     <AccessTime fontSize="12px" />
                        //   </div>
                        // </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
              <div className="panel-escritura">
                {loaderSendMessage && (<BarLoader color="#148f9f" width={"100%"} />)}
                {FilePreview !== "" && <div style={{ display: "flex" }}>
                  <Image
                    width={100}
                    src={FilePreview}
                  />
                  <button onClick={() => { setFilePreview(""); document.getElementById("ChatFile").value = ""; }}>X</button>
                </div>}
                <form className="textarea">

                  <TextArea
                    name="message"
                    type="text"
                    placeholder="Escribir Mensaje"
                    value={messageToSend}
                    autoSize
                    onChange={(e) => { setMessageToSend(e.target.value); setMessageToSendTemp(e.target.value); }}
                  />
                  <div style={{ display: "flex", justifyContent: "right", alignItems: "center", margin: ".5%", gap: "1%" }}>
                    <label htmlFor="ChatFile">
                      <AttachmentIcon htmlColor="#148f9f" style={{ cursor: "pointer" }} />
                      <input accept="image/png,image/jpeg,application/pdf" style={{ display: "none" }} id="ChatFile" type="file" onChange={handleFileSelect} />
                    </label>
                    <button
                      onClick={(e) => {
                        if (!!FilePreview) {
                          sendMessage("2");
                        } else {
                          sendMessage("0");
                        }
                      }}
                      type="button"
                      className="enviar"
                    >
                      {" "}
                      <b>{">"}</b>{/*   <i className="fas fa-paper-plane"></i>  */}
                    </button>
                  </div>

                </form>
              </div>
            </div>
          )}

          {ChatOption === 2 && (
            <>
              {loaderDetalle ? (
                <LoaderModals className="addRecetaChat" />
              ) : (
                <div className="addRecetaChat" style={{ textAlign: "start" }}>
                  <Content
                    className="site-layout"
                    style={{
                      padding: '1% 10px',
                      // height: "calc(70vh - 52px)",
                      width: "100%",
                      overflow: 'initial',
                      // background: "#eaeef0"
                    }}
                  >

                    <div style={{ display: "flex", justifyContent: "end", alignItems: "center", }}>
                      <Checkbox
                        defaultChecked={true}
                        checked={CheckEnviar}
                        onChange={(e) => {
                          // console.log(e.target.checked);
                          setCheckEnviar(e.target.checked)
                        }}
                      >Enviar a paciente</Checkbox>
                      <Checkbox
                        defaultChecked={true}
                        checked={CheckFirma}
                        onChange={(e) => {
                          // console.log(e.target.checked);
                          setCheckFirma(e.target.checked);
                        }}
                      >Incluir firma</Checkbox>
                      <Button
                        style={{
                          display: "flex", justifyContent: "space-between", alignItems: "center",
                          width: "100px",
                          backgroundColor: "#148f9f",
                          color: "white",

                          gap: "1%"
                        }}
                        onClick={() => {
                          addReceta();
                        }}
                      >Guardar <CheckCircleIcon style={{ fontSize: "15px" }} /></Button>
                    </div>
                    <form action="" style={{ padding: "0" }}>
                      <Row
                        style={{
                          marginBottom: "2%",
                          // justifyContent: "end",
                        }}
                      >

                        <Campos
                          label={"Costo:"}
                          styleDiv={{ width: "30%" }}
                        >
                          <InputNumber
                            // style={{ marginBottom: "2%", width:"40%" }}
                            style={{ width: "100%", height: "60%", display: "flex" }}
                            defaultValue={0}
                            formatter={(value) =>
                              `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            }
                            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                            onChange={(e) => setCostoReceta(e)}
                          />
                        </Campos>
                      </Row>

                      <Campos
                        valueError={MensajeCedula}
                        textError={"Debe seleccionar una cédula"}
                        label={"Cédula profesional"}
                      >
                        <Select
                          placeholder="Cédula profesional"
                          defaultValue={null}
                          style={{
                            width: "100%",
                          }}
                          onSelect={(e) => {
                            setCedula(e);
                            setMensajeCedula("true");
                          }}
                          options={CedulasMedico}
                        />
                      </Campos>

                      {/* <div> */}
                      {/* <Label>Medicamento</Label>
                      <div style={{ width: "100%", position: "relative" }}>
                        <Autosuggest
                          suggestions={Medicamentos}
                          onSuggestionsFetchRequested={
                            onSuggestionsFetchRequested2
                          }
                          onSuggestionsClearRequested={
                            onSuggestionsClearRequested2
                          }
                          getSuggestionValue={getSuggestionValueDos}
                          renderSuggestion={renderSuggestionDos}
                          inputProps={inputPropsDos}
                          onSuggestionSelected={eventEnterDos}
                        />
                        <LeyendaError valido={MensajeMedicamento}>Debe escribir un medicamento</LeyendaError>
                      </div> */}

                      <Campos label="Medicamento">
                        <div style={{ width: "100%", position: "relative" }}>
                          <Autosuggest
                            suggestions={Medicamentos}
                            onSuggestionsFetchRequested={
                              onSuggestionsFetchRequested2
                            }
                            onSuggestionsClearRequested={
                              onSuggestionsClearRequested2
                            }
                            getSuggestionValue={getSuggestionValueDos}
                            renderSuggestion={renderSuggestionDos}
                            inputProps={inputPropsDos}
                            onSuggestionSelected={eventEnterDos}
                          />
                          <LeyendaError valido={MensajeMedicamento}>Debe escribir un medicamento</LeyendaError>
                        </div>
                      </Campos>

                      <InputAntd
                        value={Dosis}
                        placeholder={"Dósis"}
                        label={"Dósis"}
                        styleDiv={{ width: "100%" }}
                        valueError={MensajeDosis}
                        setStateError={setMensajeDosis}
                        vacio={false}
                        textError={["Debe escribir una dósis"]}
                        setStateValue={setDosis}
                      />

                      <div style={{ display: "flex", justifyContent: "center" }}>
                        <Button
                          style={{
                            display: "flex", justifyContent: "space-between", alignItems: "center",
                            width: "100px",
                            backgroundColor: "#148f9f",
                            color: "white",
                            marginTop: "15px"
                          }}
                          onClick={() => {
                            if (!!Cedula) {
                              addMedicamento();
                            } else {
                              setOpen(true);
                              setTypeMessage("warning");
                              setMessage("Debe seleccionar una cédula");
                            }
                          }}
                        >Agregar <AddCircleIcon style={{ fontSize: "15px" }} /> </Button>

                      </div>

                      <hr style={{ margin: "1% 0", color: "#d7d7d7" }} />

                      <h3 style={{ marginBottom: "5%", textAlign: "center" }}>
                        Medicamentos
                      </h3>
                      <LeyendaError
                        valido={MensajeListaMedicamentos}
                        style={{
                          display:
                            MensajeListaMedicamentos === "false" ? "flex" : "none",
                        }}
                      >
                        Debe agregar al menos un medicamento
                      </LeyendaError>
                      <div>
                        {ListaMedicamentos.map((medicamento, index) => (

                          <div style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center", padding: "1%", borderBottom: "1px solid #d7d7d7" }}>
                            <span style={{ width: "80%" }}>{`${medicamento.Concepto} - ${medicamento.Descripcion}`}</span>
                            <img style={{ width: "20px" }} onClick={() => { eliminarMedicamento(medicamento.index) }} src={Eliminar} alt="" />
                          </div>

                        ))}
                        {ListaMedicamentos.length === 0 && (
                          <p style={{ marginBottom: "5%", textAlign: "center" }}>
                            Aún no se agregan medicamentos
                          </p>
                        )}
                      </div>

                      <TextAreaAntd
                        autoSize={true}
                        label={"Indicaciones"}
                        placeholder={"Escribe algunas indicaciones"}
                        value={Indicaciones}
                        setStateValue={setIndicaciones}
                        cols="30"
                      />

                      <TextAreaAntd
                        autoSize={true}
                        label={"Recomendaciones"}
                        placeholder={"Escribe algunas recomendaciones"}
                        value={Recomendaciones}
                        setStateValue={setRecomendaciones}
                        cols="30"
                      />

                      <Label valido={MensajeDiagnosticoCIIE}>Diagnósticos CIE</Label>
                      <div style={{ display: "flex", gap: "1", margin: "1% 0" }}>
                        <InputAntd
                          className="InputNormal"
                          type=""
                          placeholder="Palabra clave"
                          value={PalabraClave}
                          setStateValue={setPalabraClave}
                          vacio={true}
                          disabled={false}
                          style={{ width: "100%" }}
                          setStateError={setMensajePalabraClave}
                          textError={["Debe escribir una palabra clave", ""]}
                          valueError={MensajePalabraClave}
                        />
                        {loaderSearch ? (<CircularProgress size={35} sx={{ color: "#148f9f" }} />) : (
                          <Button
                            type="button"
                            style={{ margin: "0 1%", marginLeft: "1%", border: "0", background: "#148f9f", color: "white" }}
                            onClick={() => {
                              if (!!PalabraClave) {
                                getCIIEDiagnosticos();
                              } else {
                                setMensajePalabraClave("false");
                                setOpen(true);
                                setTypeMessage("warning");
                                setMessage("Debe escribir una palabra");
                              }
                            }}>Buscar</Button>
                        )}
                      </div>

                      <div>
                        {ListaDiagnosticos.length > 0 && (
                          <>
                            <div style={{ display: "flex", gap: "1rem" }}>
                              <Select
                                value={DiagnosticosSeleccionados}
                                mode="multiple"
                                placeholder="Seleciona tus diagnósticos"
                                style={{ width: "100%" }}
                                options={ListaDiagnosticos}
                                filterOption={filterOptions}
                                filterSort={filterSort}
                                onChange={(e) => {
                                  // console.log(e);
                                  setDiagnosticosSeleccionados(e);
                                }}
                              />
                              <Button
                                icon={<Add style={{ color: "white" }} />}
                                style={{ background: "#148f9f" }}
                                onClick={() => {
                                  addDiagnosticoCIIE();
                                }}
                              />
                            </div>
                            <LeyendaError valido={MensajeDiagnosticoCIIE}>Debe seleccionar un diagnóstico</LeyendaError>
                          </>
                        )}
                        <Label valido={MensajeOtrosDiagnosticos}>Otros diagnósticos</Label>
                        <div style={{ display: "flex", gap: "1rem" }}>
                          <TextAreaAntd
                            value={OtrosDiagnosticos}
                            placeholder="Otros diagnósticos"
                            style={{
                              marginBottom: ".5%",
                              fontFamily: "sans-serif",
                            }}
                            columns="100"
                            autoSize={true}
                            setStateValue={setOtrosDiagnosticos}
                            setStateError={setMensajeOtrosDiagnosticos}
                            valueError={MensajeOtrosDiagnosticos}
                            textError={["Debe escribir un diagnósticos"]}
                            vacio={true}
                          />
                          <Button
                            icon={<Add style={{ color: "white" }} />}
                            style={{ background: "#148f9f" }}
                            onClick={() => {
                              addDiagnosticoOtro();
                            }}
                          />
                        </div>
                      </div>
                      <h3 style={{ marginBottom: "5%", textAlign: "center" }}>
                        Diagnósticos
                      </h3>
                      {DiagnosticosSeleccionadosMostrados.length > 0 ? (
                        <>
                          {DiagnosticosSeleccionadosMostrados.map((diagnostico, index) => (
                            // <div className="ItemCard">
                            //     <div className="CardNotasMed">
                            //         <span>{diagnostico.diagnostico}</span>
                            //         <img onClick={() => { eliminarDiagnostico(diagnostico.index) }} src={Eliminar} alt="" />
                            //     </div>
                            // </div>
                            <div className="ItemCard">
                              <Row style={{ justifyContent: "space-between" }}>
                                <span>{diagnostico.diagnostico}</span>
                                <img style={{ width: "14px", cursor: "pointer" }} onClick={() => { eliminarDiagnostico(diagnostico.index) }} src={Eliminar} alt="" />
                              </Row>
                            </div>

                          ))}
                        </>
                      ) : (
                        <p style={{ textAlign: "center" }}>Aún no se agregan diagnósticos</p>
                      )}
                    </form>
                  </Content>
                </div>
              )}
            </>
          )}

        </div>
      </Modalchat >

      {ModalEliminarChat}

      <ModalSelectContact
        isOpen={IsOpenSelectContacto}
        closeModal={ClosedSelectContacto}
        optionClose={true}
        styleDet={styleDet}
      >
        <div
          style={{
            width: "100%",
            background: "#148f9f",
            color: "#fff",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "1% 2%",
          }}
        >
          Selecciona un Contacto
          <CloseIcon
            style={{ cursor: "pointer", fontSize: "20px" }}
            onClick={() => {
              ClosedSelectContacto(true);
              // OpenTipoUsuario(true);
            }}
          />

        </div>
        <div className="ModalListContactosConsult">
          {/* <div style={{ display: "flex", justifyContent: "end" }}>
            <Button type="button" onClick={() => {
              ClosedSelectContacto(true);
              OpenAddContacto(true);
            }}
              style={{
                width: "150px",
                backgroundColor: "#148f9f",
                color: "white"
              }}>Nuevo contacto</Button>
          </div> */}
          <p>Nombre</p>
          <Input
            placeholder="Buscar..."
            type="search"
            style={{ width: "100%", marginBottom: "10px" }}
            onChange={filterContacts}
          />

          <div
            className="contCardModal"
            style={{ borderTop: "1px solid #d5d5d5" }}
          >
            <AddLista
              CloseModal={ClosedSelectContacto}
              OpenModal={OpenChatModal}
              Lista={contactList}
              funcionExtra={[5, setDatosContacto, newChat]}
            />
          </div>
        </div>
      </ModalSelectContact>

      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={() => setOpen(false)}
      >
        <Alert
          onClose={() => setOpen(false)}
          severity={typeMessage}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </Stack >
  );
}
