import React from 'react'
import "./styles/Ayuda.scss"
import { useState } from 'react';
import { Input, Row, Button, Layout, Modal, Empty, } from "antd";
import Typography from "@mui/material/Typography";
import NavbarNuevo from '../componentes/GeneralComponents/Navbar_Nuevo';
import { Accordion, AccordionDetails, AccordionSummary, IconButton, Stack } from '@mui/material';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Tutoriales from './Tutoriales';
import { WhatsApp } from '@mui/icons-material';
import { WhatsAppChatURL } from '../services/apiUrl';

function Ayuda() {

  const listQuestions = [
    {
      "id": '0',
      "question": '¿Qué es Ygia?',
      "answer":
        "Ygia App para médicos, es una aplicación que simplifica y facilita todos los procesos del día a día a doctores, dentistas, nutriólogos o cualquier otro tipo de especialista en el ramo de la salud. Pero también es una App para pacientes que se sincroniza con todos sus médicos que cuenten con YGIA.",
    },
    {
      "id": '1',
      "question": '¿Cuáles son las principales funciones?',
      "answer":
        "Agenda y directorio digital, consultas, gestión de recetas y estudios, cobro y facturación a pacientes, punto de venta, expediente médico del paciente, administración de asistentes.",
    },
    {
      "id": '2',
      "question": '¿Ygia es seguro?',
      "answer":
        "En Ygia nos preocupamos por la seguridad de tu información y la de tus pacientes, por ello nos apegamos a la certificación HIPPA, al Nivel 1 como Proveedor de Servicios PCI y a la Norma Oficial Mexicana NOM-004-SSA3-2012, del expediente clínico.",
    },
    {
      "id": '3',
      "question": '¿Cómo está respaldada la información?',
      "answer":
        "Está respaldada en servidores considerados como los Top 10 del mundo bajo 3 niveles de protección.",
    },
    {
      "id": '4',
      "question": '¿Cuál es la capacidad de almacenamiento?',
      "answer":
        "Tiene capacidad para almacenar hasta 3 gb con la membresía Freemium y hasta 50gb con la membresía Plan Ygia Superior, en caso de requerir más capacidad puede visualizar las opciones de aumento de almacenamiento en el módulo de suscripción.",
    },
    {
      "id": '5',
      "question": '¿Se puede usar en cualquier dispositivo?',
      "answer":
        "Se puede utilizar en cualquier ordenador o dispositivo inteligente que cuente con la versión más reciente de Google Chrome, Safari y Firefox.",
    },
    {
      "id": '6',
      "question": '¿Cómo me registro en Ygia?',
      "answer": <div>Para registrarte es necesario dirigirse al apartado <a href="https://ygia.app/#/registrarme">Registrarme</a>.</div>,
    },
    // {
    //   "id": '7',
    //   "question": '¿Qué necesito para poder usar Ygia?',
    //   "answer":
    //     "Solo necesitas contar con internet y un dispositivo con la versión más reciente de Google Chrome, Safari, Firefox, iOS o Android.",
    // },
    {
      "id": '8',
      "question":
        '¿Qué hago si por algún incidente, robo, extravío u otro mi información está en riesgo?',
      "answer": <div>Puedes comunicarte con soporte técnico a nuestro WhatsApp <IconButton target='_blank' href='https://wa.me/message/P2CQCNCUDX5RG1' children={<WhatsApp sx={{ fontSize: 20, color: "green" }} />} /> o al correo soporte@ygia.app.</div>,
    },
    {
      "id": '9',
      "question":
        'Olvidé mis datos de inicio de sesión, ¿Puedo recuperar mi cuenta?',
      "answer": <div>Puedes recuperar tu contraseña desde el apartado <a href="https://ygia.app/#/recuperacion">Recuperar</a>.</div>,
    },
    {
      "id": '10',
      "question": '¿Puedo cancelar mi cuenta?',
      "answer":
        "Puedes cancelar tu cuenta desde tu perfil.",
    },
    {
      "id": '11',
      "question": 'Si cancelo mi cuenta, ¿Cómo recupero mi información?',
      "answer": <div>Puedes comunicarte con soporte técnico a nuestro WhatsApp <IconButton target='_blank' href='https://wa.me/message/P2CQCNCUDX5RG1' children={<WhatsApp sx={{ fontSize: 20, color: "green" }} />} /> o al correo soporte@ygia.app.</div>,
    },
    {
      "id": '12',
      "question": '¿A dónde me puedo comunicar si tengo algún problema?',
      "answer": <div>Puedes comunicarte con soporte técnico a nuestro WhatsApp <IconButton target='_blank' href='https://wa.me/message/P2CQCNCUDX5RG1' children={<WhatsApp sx={{ fontSize: 20, color: "green" }} />} /> o al correo soporte@ygia.app.</div>,
    },
    {
      "id": '13',
      "question":
        '¿Para qué dispositivos está disponible?',
      "answer": "Para dispositivos que cuenten con la versión mas reciente de Chrome, Safari o Firefox.",
    },
    // {
    //   "id": '14',
    //   "question":
    //     'Si subo un paciente desde mi computadora, ¿Lo puedo ver en mi celular o tablet?',
    //   "answer": "Todas la acciones realizadas desde la plataforma web, podran visualizar desde la app móvil.",
    // },
    {
      "id": '15',
      "question": '¿Cuál es el precio del servicio Ygia?',
      "answer":
        "Ygia para médicos tiene un costo base de \$399 MXN mensuales. Ygia App para pacientes es totalmente gratuita",
    },
    {
      "id": '16',
      "question": '¿Cuáles son las formas de pago?',
      "answer":
        "Puedes realizar pagos con cualquier tarjeta Visa o Mastercard.",
    },
    {
      "id": '17',
      "question":
        '¿Ygia cuenta con un periodo de prueba antes de adquirir la suscripción?',
      "answer":
        "Cuentas con un periodo de 90 días para probar todas las funcionalidades de Ygia totalmente gratis.",
    },
    {
      "id": '18',
      "question": '¿Aumenta el precio si uso Ygia en más de un consultorio?',
      "answer":
        "Puedes utilizar Ygia médicos en 2 dispositivos a la vez.",
    },
    {
      "id": '19',
      "question": '¿La aplicación móvil tiene un costo adicional?',
      "answer":
        "La aplicación móvil no tiene costo extra.",
      // "No. El costo de la aplicación es por usuario y puedes utilizar la misma desde diversos dispositivos.",
    },
    // {
    //   "id": '20',
    //   "question": '¿Puedo cambiar mi plan?',
    //   "answer":
    //     "El plan puede ser cambiado en cualquier momento desde la App de Ygia para cada próxima renovación.",
    // },
    {
      "id": '21',
      "question": '¿Cómo se realiza el cobro de mi suscripción?',
      "answer":
        "El cobro se realiza automáticamente cada mes a tu tarjeta Visa o Mastercard registrada.",
    },
    {
      "id": '22',
      "question": '¿Es seguro realizar pagos con Ygia?',
      "answer":
        "Todos los pagos realizados a través de Ygia son controlados por Stripe. Proveedor certificado de Servicios PCI DSS, con el nivel más riguroso existente dentro de la industria de pagos.",
    },
    {
      "id": '23',
      "question": '¿Tengo algún tipo de garantía al adquirir mi plan?',
      "answer":
        "Ygia cuenta con un periodo de prueba de 90 días, dentro del cual podrás disfrutar de todas sus características totalmente gratis para evitar este tipo de situaciones. Si el software no te convence después de haber hecho tu compra, te devolvemos tu dinero, hasta 7 días después de tu compra.",
    },
    {
      "id": '24',
      "question": '¿Es necesario registrar una tarjeta?',
      "answer":
        "No es necesario registrar alguna tarjeta para utilizar el periodo de prueba de 90 días, pero será necesaria para adquiri Plan Ygia Superior, una vez terminado el periodo de prueba.",
    },
    // {
    //   "id": '25',
    //   "question": '¿Existen descuentos y promociones para hospitales?',
    //   "answer":
    //     "Todos los hospitales cuentan con un descuento adicional. Para más información, comunícate con nuestro equipo de soporte.",
    // },
    // {
    //   "id": '26',
    //   "question": '¿Puedo cancelar mi plan?',
    //   "answer":
    //     "La cancelación de todos los planes se hace automáticamente en caso de no utilizar la aplicación por más de 1 año al final de cada periodo contratado.",
    // },
    // {
    //   "id": '27',
    //   "question": 'Pregunta',
    //   "answer": "Respuesta",
    // },
  ];
  const { Header, Content, Footer } = Layout;

  const [TabsAyuda, setTabsAyuda] = useState("1");

  const [videos, setvideos] = useState([
    {

      id: "1",
      videoUrl: "https://www.youtube.com/embed/Z1_pcApgPX0?si=SyUaBAlsfVt2CjQF",
      titleVideo: "Configuraciones generales de Ygia",
      descripcion: "En este módulo aprenderás lo necesario para configurar tus horarios de citas, formatos para recetas y agregar una nueva cedula profesional.",
    },
    {

      id: "2",
      videoUrl: "https://www.youtube.com/embed/2EtstAQwG7I?si=p5FLFktuVG61s2jJ",
      titleVideo: "¿Cómo agendar una cita medica?",
      descripcion: "En este tutorial aprenderas cómo agendar una cita, agregar mi primer paciente y Tipos de pacientes que existen.",
    },
    {
      id: "3",
      videoUrl: "https://www.youtube.com/embed/KTX6Yu5TpXQ?si=o3_VO-djr_l_72lA",
      titleVideo: "¿Cómo hacer una consulta medica?",
      descripcion: "En este modulo aprenderas a cómo  crear tu primara consulta y reseta en Ygia.",
    },
    {
      id: "4",
      videoUrl: "https://www.youtube.com/embed/eZLIfSrBYJs?si=LS_t3bGXqoeVx1Gm",
      titleVideo: "¿Cómo visualizo y modifico mi Expediente?",
      descripcion: "En este modulo aprenderas a buscar el expediente de un paciente y modificar su contenido.",
    },

  ]);

  const [videosFilter, setvideosFilter] = useState([
    {

      id: "1",
      videoUrl: "https://www.youtube.com/embed/Z1_pcApgPX0?si=SyUaBAlsfVt2CjQF",
      titleVideo: "Configuraciones generales de Ygia",
      descripcion: "En este módulo aprenderás lo necesario para configurar tus horarios de citas, formatos para recetas y agregar una nueva cedula profesional.",
    },
    {

      id: "2",
      videoUrl: "https://www.youtube.com/embed/2EtstAQwG7I?si=p5FLFktuVG61s2jJ",
      titleVideo: "¿Cómo agendar una cita medica?",
      descripcion: "En este tutorial aprenderas cómo agendar una cita, agregar mi primer paciente y Tipos de pacientes que existen.",
    },
    {
      id: "3",
      videoUrl: "https://www.youtube.com/embed/KTX6Yu5TpXQ?si=o3_VO-djr_l_72lA",
      titleVideo: "¿Cómo hacer una consulta medica?",
      descripcion: "En este modulo aprenderas a cómo  crear tu primara consulta y reseta en Ygia.",
    },
    {
      id: "4",
      videoUrl: "https://www.youtube.com/embed/eZLIfSrBYJs?si=LS_t3bGXqoeVx1Gm",
      titleVideo: "¿Cómo visualizo y modifico mi Expediente?",
      descripcion: "En este modulo aprenderas a buscar el expediente de un paciente y modificar su contenido.",
    },

  ]);

  function filterList(searchText) {
    var chars = {
      á: "a",
      é: "e",
      í: "i",
      ó: "o",
      ú: "u",
      à: "a",
      è: "e",
      ì: "i",
      ò: "o",
      ù: "u",
      ñ: "n",
      Á: "A",
      É: "E",
      Í: "I",
      Ó: "O",
      Ú: "U",
      À: "A",
      È: "E",
      Ì: "I",
      Ò: "O",
      Ù: "U",
      Ñ: "N",
    };

    // Función para normalizar el texto reemplazando caracteres especiales
    function normalize(text) {
      return text.replace(/[áàéèíìóòúùñÁÉÍÓÚÀÈÌÒÙÑ]/g, (match) => chars[match] || match);
    }

    // Normalizar el texto de búsqueda
    const normalizedSearchText = normalize(searchText.target.value.toLowerCase());

    // Filtrar la lista de contactos
    const filter = videosFilter.filter((video) => {
      const normalizedTitle = normalize(video.titleVideo.toLowerCase());
      const normalizedDescription = normalize(video.descripcion.toLowerCase());

      // Buscar coincidencia en cualquiera de los campos
      return (
        normalizedTitle.includes(normalizedSearchText) ||
        normalizedDescription.includes(normalizedSearchText)
      );
    });

    setvideos(filter);
  }

  // MANEJA DE MENSAJES
  const [open, setOpen] = useState(false);
  const [typeMessage, setTypeMessage] = useState("");
  const [message, setMessage] = useState("");

  return (
    <Stack>
      <NavbarNuevo
        mensajesError={{
          setOpen: setOpen,
          setTypeMessage: setTypeMessage,
          setMessage: setMessage,
        }}

        searchBar={
          <div style={{ width: "100%" }}>
            <div className="tabsContainer">
              <div
                className={
                  TabsAyuda === "1" ? "tabsAyuda active_tabs" : "tabsAyuda"
                }
                onClick={() => setTabsAyuda("1")}
              >
                <p>Preguntas frecuentes</p>
              </div>

              <div
                className={
                  TabsAyuda === "2" ? "tabsAyuda active_tabs" : "tabsAyuda"
                }
                onClick={() => setTabsAyuda("2")}
              >
                Tutoriales
              </div>

              <div
                className={
                  TabsAyuda === "3" ? "tabsAyuda active_tabs" : "tabsAyuda"
                }
                onClick={() => setTabsAyuda("3")}
              >
                Soporte
              </div>

            </div>
            {TabsAyuda === "2" && (
              <div style={{ width: "100%", background: "white", padding: "15px" }}>
                <Input
                  className="inputsearchtutoriales"
                  placeholder="Buscar..."
                  type="text"
                  // style={{ width: "31%", marginBottom: "10px", marginLeft: "10px" }}
                  onChange={filterList}
                />
              </div>
            )}
          </div>
        }
      />
      {TabsAyuda === "1" && (
        <div style={{ padding: "1px 3%" }}>
          {listQuestions.map((question) => (
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography><b style={{ color: "#464646" }}>{question.question}</b></Typography>

              </AccordionSummary>
              <AccordionDetails>
                <div>
                  {question.answer}
                </div>
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
      )}

      {TabsAyuda === "2" && (
        <Tutoriales videos={videos} />
      )}

      {TabsAyuda === "3" && (
        <div className='soporteContent'>
          <div style={{ width: "50%", background: "white", padding: "10px", textAlign: "center" }}>
            <div style={{ marginTop: "10px" }}>
              <b>WhatsApp de soporte:</b>
              {/* <p>https://wa.me/message/P2CQCNCUDX5RG1</p> */}
              <div>
                <IconButton target='_blank' href={WhatsAppChatURL} children={<WhatsApp sx={{ fontSize: 40, color: "green" }} />} />
              </div>
              {/* <div style={{width:"50%"}}>
                <a href="https://wa.me/message/P2CQCNCUDX5RG1" target='_blank' ><WhatsApp/></a>
              </div> */}
            </div>
            <div style={{ marginTop: "20px", marginBottom: "10px" }}>
              <b>Correo de soporte:</b>
              <p>soporte@ygia.app</p>
            </div>
          </div>
        </div>
      )}
    </Stack>
  )
}

export default Ayuda