import { notification } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { onMessageListener } from '../../firebase';
import addNotification from 'react-push-notification';
import { AccountCircle } from '@mui/icons-material';
import iconoYgia from "../../Img/ygiaicono.jpg"
import { useDispatch } from 'react-redux';
import { badges } from '../../redux/badges';
const Context = React.createContext({
    name: 'Default',
});
export default function NotificationSlide({ navigate, detalle }) {

    const dispatch = useDispatch();
    let firebasetoken = localStorage.getItem("firebaseToken");
    const [api, contextHolder] = notification.useNotification();

    const [NotificationContent, setNotificationContent] = useState({ title: "", body: "" });
    const [DataNotification, setDataNotification] = useState("");

    const openNotification = () => {
        let url = window.location.hash.split("/");
        // addNotification({
        //     title: NotificationContent?.title,
        //     message: NotificationContent.body,
        //     duration: 6000,
        //     icon: iconoYgia,
        //     native: true,
        //     onClick: (e) => {
        //         // console.log(e);
        //         // console.log("abrir notificacion");
        //         // console.log(url);
        //         // console.log(DataNotification);
        //         // window.open(receta.url, "_blank");
        //         if (DataNotification.actionType === "M1") {
        //             // console.log(url[2]);
        //             if (url[2] === "citas") {
        //                 detalle(DataNotification.id, 1);
        //             } else {
        //                 navigate("/app/citas", {
        //                     replace: true,
        //                     state: {
        //                         id: DataNotification.idContacto,
        //                         imagen: DataNotification.url,
        //                         nombre: DataNotification.nombre,
        //                         idCita: DataNotification.id,
        //                     },
        //                 });
        //             }

        //         } else if (DataNotification.actionType === "M3") {
        //             // console.log(url[2]);
        //             if (url[2] === "estudios") {
        //                 detalle(DataNotification.id, 1);
        //             } else {
        //                 navigate("/app/estudios", {
        //                     replace: true,
        //                     state: {
        //                         id: DataNotification.idContacto,
        //                         imagen: DataNotification.url,
        //                         nombre: DataNotification.nombre,
        //                         idEstudio: DataNotification.id,
        //                     },
        //                 });
        //             }

        //         } else if (DataNotification.actionType === "M2") {
        //             // console.log(url[2]);
        //             if (url[2] === "chats") {
        //                 detalle(DataNotification.id, 1);
        //             } else {
        //                 navigate("/app/chats", {
        //                     replace: true,
        //                     state: {
        //                         id: DataNotification.idContacto,
        //                         imagen: DataNotification.url,
        //                         nombre: DataNotification.nombre,
        //                         idEstudio: DataNotification.id,
        //                     },
        //                 });
        //             }

        //         }
        //     }

        // })

        api.open({
            message: `${NotificationContent?.title}`,
            description: <Context.Consumer>{({ name }) => `${NotificationContent.body}`}</Context.Consumer>,
            placement: "bottomRight",

            onClick: () => {
                // console.log("abrir notificacion");
                // console.log(DataNotification);
                if (DataNotification.actionType === "M1") {
                    // console.log(url[2]);
                    if (url[2] === "citas") {
                        detalle(DataNotification.id, 1);
                    } else {
                        navigate("/app/citas", {
                            replace: true,
                            state: {
                                id: DataNotification.idContacto,
                                imagen: DataNotification.url,
                                nombre: DataNotification.nombre,
                                idCita: DataNotification.id,
                            },
                        });
                    }

                } else if (DataNotification.actionType === "M3") {
                    // console.log(url[2]);
                    if (url[2] === "estudios") {
                        detalle(DataNotification.id, 1);
                    } else {
                        navigate("/app/estudios", {
                            replace: true,
                            state: {
                                id: DataNotification.idContacto,
                                imagen: DataNotification.url,
                                nombre: DataNotification.nombre,
                                idEstudio: DataNotification.id,
                            },
                        });
                    }

                } else if (DataNotification.actionType === "M2") {
                    // console.log(url[2]);
                    if (url[2] === "chats") {
                        detalle(DataNotification.id, 1);
                    } else {
                        navigate("/app/chats", {
                            replace: true,
                            state: {
                                id: DataNotification.idContacto,
                                imagen: DataNotification.url,
                                nombre: DataNotification.nombre,
                                idEstudio: DataNotification.id,
                            },
                        });
                    }

                }
            }

        });
    };
    const contextValue = useMemo(
        () => ({
            name: 'Ant Design',
        }),
        [],
    );
    if (Notification.permission === "granted") {
        // console.log("Mensajes");
        onMessageListener()
            .then((payload) => {
                console.log(payload);
                if (document.domain === "ygia.app") {
                    dispatch(badges({ accion: 1, modulo: payload?.data.actionType, }));
                }
                setNotificationContent({ title: payload?.notification?.title, body: payload?.notification?.body });
                setDataNotification(payload.data);
            })
            .catch((err) => console.log('failed: ', err));
    }

    useEffect(() => {
        if (NotificationContent?.title) {
            openNotification();
        }
    }, [NotificationContent])
    return (
        <Context.Provider value={contextValue}>

            {contextHolder}

        </Context.Provider>
    );
};