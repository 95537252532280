// store.js


import { configureStore } from '@reduxjs/toolkit';

import snackbarReducer from './snackbarSlice';
import userReducer from './userSlice';
import doctorsToAssistantSlice from './doctorsToAssistant';
import infoDoctorLog from './infoDoctorLog';
import badges from './badges';
import navBarProps from './navBarProps';
import notifications from './notifications';

const store = configureStore(
  {
    reducer: {
      snackbar: snackbarReducer,
      userInfo: userReducer,
      doctorsLink: doctorsToAssistantSlice,
      infoDoctorLog: infoDoctorLog,
      badges: badges,
      navBarProps: navBarProps,
      notifications: notifications,
    }
  }
)

export default store;

// import { configureStore } from 'redux';

// const initialState = { variable: false };

// // Definir un reducer simple
// const reducer = (state = initialState, action) => {
// console.log(action.type);
// console.log(action.payload);
//   switch (action.type) {
//     case 'mensaje_app':
//       return { ...state, variable: action.payload };
//     // return state.concat([action.payload])
//     default:
//       return state;
//   }
// };



// export default reducer;