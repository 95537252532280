import * as React from "react";
import IconButton from "@mui/joy/IconButton";
import Menu from "@mui/joy/Menu";
// import Menu from "@mui/material/Menu";
import MenuItem from "@mui/joy/MenuItem";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import MoreVert from "@mui/icons-material/MoreVert";
import DeleteForever from "@mui/icons-material/DeleteForever";
import { StyledEngineProvider, CssVarsProvider } from "@mui/joy/styles";
import { Add, AddOutlined, CancelOutlined, CancelRounded, CheckCircleOutline, DeleteForeverOutlined, Edit, EditOutlined } from "@mui/icons-material";

export default function PositionedMenu({ setIdCita, extras }) {
  let data = JSON.parse(sessionStorage.getItem("infoUser"));
  // console.log(data.tipo);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    // console.log("ABRE");
    setAnchorEl(event.currentTarget);

  };

  const handleClose = (event) => {
    // console.log("CIERRA");
    setAnchorEl(null);
  };

  return (

    <StyledEngineProvider>
      <CssVarsProvider>
        <IconButton
          id="positioned-demo-button"
          aria-controls={open ? "positioned-demo-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          variant="outlined"
          color="neutral"
          onClick={(e) => {
            handleClick(e);
            setIdCita(extras[0].IdCita);
            extras[5](!!extras[0].TipoCita ? extras[0].TipoCita !== 0 ? extras[0].TipoCita.toString() : "2" : "");
            extras[7]({ id: extras[0].IdContacto, nombre: `${extras[0].Nombre} ${extras[0].ApellidoPaterno} ${extras[0].ApellidoMaterno}`, imagen: extras[0].Imagen_url });
            extras[10]({ nombre: extras[0].Full_Name, tipo: extras[0].TipoCita, estado: extras[0].Estado, hora: extras[0].HoraCita, fecha: extras[0].FechaCita });
          }}
        >
          <MoreVert />
        </IconButton>
        {extras[0].Status === 0 && (
          <>
            {anchorEl !== null && (
              <div style={{ top: "0", left: "0", width: "100%", height: "100vh", position: "absolute", zIndex: "999" }}>
                <div>
                  <Menu
                    id="positioned-demo-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="positioned-demo-button"
                    placement="bottom-end"

                  >
                    {data.Tipo === 1 && (
                      <MenuItem onClick={() => { handleClose(); extras[6](true); }}>
                        <ListItemDecorator sx={{ color: "inherit" }}>
                          <AddOutlined color='secondary' />
                        </ListItemDecorator>{" "}
                        Consultar
                      </MenuItem>
                    )}
                    <MenuItem onClick={() => { handleClose(); extras[9](); }}>
                      <ListItemDecorator sx={{ color: "inherit" }}>
                        <CheckCircleOutline color="warning" />
                      </ListItemDecorator>{" "}
                      Confirmar
                    </MenuItem>
                    <MenuItem onClick={() => { handleClose(); extras[8](); }}>
                      <ListItemDecorator sx={{ color: "inherit" }}>
                        <CheckCircleOutline color="success" />
                      </ListItemDecorator>{" "}
                      Atender
                    </MenuItem>
                    <MenuItem onClick={() => { handleClose(); extras[1]("", 2); extras[2](true); }}>
                      <ListItemDecorator sx={{ color: "inherit" }}>
                        <EditOutlined color='secondary' />
                      </ListItemDecorator>{" "}
                      Editar cita
                    </MenuItem>
                    <MenuItem onClick={() => { handleClose(); extras[11](); }}>
                      <ListItemDecorator sx={{ color: "inherit" }}>
                        <EditOutlined color='secondary' />
                      </ListItemDecorator>{" "}
                      Editar contacto
                    </MenuItem>
                    <MenuItem onClick={() => { handleClose(); extras[3](true); }}>
                      <ListItemDecorator sx={{ color: "inherit" }}>
                        <CancelOutlined color="danger" />
                      </ListItemDecorator>{" "}
                      Cancelar
                    </MenuItem>
                    <MenuItem onClick={() => { handleClose(); extras[4](); }}>
                      <ListItemDecorator sx={{ color: "inherit" }}>
                        <DeleteForeverOutlined color="danger" />
                      </ListItemDecorator>{" "}
                      Eliminar
                    </MenuItem>
                  </Menu>
                </div>
              </div>)}
          </>
        )}
        {extras[0].Status === 1 && (
          <>
            {anchorEl !== null && (
              <div style={{ top: "0", left: "0", width: "100%", height: "100vh", position: "absolute", zIndex: "999" }}>
                <div>
                  <Menu
                    id="positioned-demo-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="positioned-demo-button"
                    placement="bottom-end"

                  >
                    {data.Tipo === 1 && (
                      <MenuItem onClick={() => { handleClose(); extras[6](true); }}>
                        <ListItemDecorator sx={{ color: "inherit" }}>
                          <AddOutlined color='secondary' />
                        </ListItemDecorator>{" "}
                        Consultar
                      </MenuItem>
                    )}
                    <MenuItem onClick={() => { handleClose(); extras[8](); }}>
                      <ListItemDecorator sx={{ color: "inherit" }}>
                        <CheckCircleOutline color="success" />
                      </ListItemDecorator>{" "}
                      Atender
                    </MenuItem>
                    <MenuItem onClick={() => { handleClose(); extras[1]("", 2); extras[2](true); }}>
                      <ListItemDecorator sx={{ color: "inherit" }}>
                        <EditOutlined color='secondary' />
                      </ListItemDecorator>{" "}
                      Editar cita
                    </MenuItem>
                    <MenuItem onClick={() => { handleClose(); extras[11](); }}>
                      <ListItemDecorator sx={{ color: "inherit" }}>
                        <EditOutlined color='secondary' />
                      </ListItemDecorator>{" "}
                      Editar contacto
                    </MenuItem>
                    <MenuItem onClick={() => { handleClose(); extras[3](true); }}>
                      <ListItemDecorator sx={{ color: "inherit" }}>
                        <CancelOutlined color="danger" />
                      </ListItemDecorator>{" "}
                      Cancelar
                    </MenuItem>
                    <MenuItem onClick={() => { handleClose(); extras[4](); }}>
                      <ListItemDecorator sx={{ color: "inherit" }}>
                        <DeleteForeverOutlined color="danger" />
                      </ListItemDecorator>{" "}
                      Eliminar
                    </MenuItem>
                  </Menu>
                </div>
              </div>)}
          </>
        )}

        {extras[0].Status === 2 && (
          <>
            {anchorEl !== null && (
              <div style={{ top: "0", left: "0", width: "100%", height: "100vh", position: "absolute", zIndex: "999" }}>
                <div>
                  <Menu
                    id="positioned-demo-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="positioned-demo-button"
                    placement="bottom-end"

                  >
                    {data.Tipo === 1 && (
                      <MenuItem onClick={() => { handleClose(); extras[6](true); }}>
                        <ListItemDecorator sx={{ color: "inherit" }}>
                          <AddOutlined color='secondary' />
                        </ListItemDecorator>{" "}
                        Consultar
                      </MenuItem>
                    )}
                    <MenuItem onClick={() => { handleClose(); extras[9](); }}>
                      <ListItemDecorator sx={{ color: "inherit" }}>
                        <CheckCircleOutline color="warning" />
                      </ListItemDecorator>{" "}
                      Confirmar
                    </MenuItem>
                    <MenuItem onClick={() => { handleClose(); extras[8](); }}>
                      <ListItemDecorator sx={{ color: "inherit" }}>
                        <CheckCircleOutline color="success" />
                      </ListItemDecorator>{" "}
                      Atender
                    </MenuItem>
                    <MenuItem onClick={() => { handleClose(); extras[1]("", 2); extras[2](true); }}>
                      <ListItemDecorator sx={{ color: "inherit" }}>
                        <EditOutlined color='secondary' />
                      </ListItemDecorator>{" "}
                      Editar cita
                    </MenuItem>
                    <MenuItem onClick={() => { handleClose(); extras[11](); }}>
                      <ListItemDecorator sx={{ color: "inherit" }}>
                        <EditOutlined color='secondary' />
                      </ListItemDecorator>{" "}
                      Editar contacto
                    </MenuItem>
                    <MenuItem onClick={() => { handleClose(); extras[4](); }}>
                      <ListItemDecorator sx={{ color: "inherit" }}>
                        <DeleteForeverOutlined color="danger" />
                      </ListItemDecorator>{" "}
                      Eliminar
                    </MenuItem>
                  </Menu>
                </div>
              </div>)}
          </>
        )}

        {extras[0].Status === 3 && (
          <>
            {anchorEl !== null && (
              <div style={{ top: "0", left: "0", width: "100%", height: "100vh", position: "absolute", zIndex: "999" }}>
                <div>
                  <Menu
                    id="positioned-demo-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="positioned-demo-button"
                    placement="bottom-end"

                  >
                    {data.Tipo === 1 && (
                      <MenuItem onClick={() => { handleClose(); extras[6](true); }}>
                        <ListItemDecorator sx={{ color: "inherit" }}>
                          <AddOutlined color='secondary' />
                        </ListItemDecorator>{" "}
                        Consultar
                      </MenuItem>
                    )}
                    <MenuItem onClick={() => { handleClose(); extras[9](); }}>
                      <ListItemDecorator sx={{ color: "inherit" }}>
                        <CheckCircleOutline color="warning" />
                      </ListItemDecorator>{" "}
                      Confirmar
                    </MenuItem>
                    <MenuItem onClick={() => { handleClose(); extras[3](true); }}>
                      <ListItemDecorator sx={{ color: "inherit" }}>
                      <CancelOutlined color="danger" />
                      </ListItemDecorator>{" "}
                      Cancelar
                    </MenuItem>
                    <MenuItem onClick={() => { handleClose(); extras[1]("", 2); extras[2](true); }}>
                      <ListItemDecorator sx={{ color: "inherit" }}>
                        <EditOutlined color='secondary' />
                      </ListItemDecorator>{" "}
                      Editar cita
                    </MenuItem>
                    <MenuItem onClick={() => { handleClose(); extras[11](); }}>
                      <ListItemDecorator sx={{ color: "inherit" }}>
                        <EditOutlined color='secondary' />
                      </ListItemDecorator>{" "}
                      Editar contacto
                    </MenuItem>
                    <MenuItem onClick={() => { handleClose(); extras[4](); }}>
                      <ListItemDecorator sx={{ color: "inherit" }}>
                        <DeleteForeverOutlined color="danger" />
                      </ListItemDecorator>{" "}
                      Eliminar
                    </MenuItem>
                  </Menu>
                </div>
              </div>)}
          </>
        )}

      </CssVarsProvider>
    </StyledEngineProvider>
  );
}
